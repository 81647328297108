import { initXHRListener } from './xhr';
import { initFetchListener } from './fetch';
import { initEventSourceListener } from './eventsource';
import { initImgListener } from './img';
import { MonitoringBuffer } from './types';
import { compress, processBuffer, submit } from './export';

export function randomString(len: number) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}
function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, '0');
}

export function initMonitoring(options: {
  interval?: number;
  include?: {
    xhr?: boolean;
    fetch?: boolean;
    eventSource?: boolean;
    img?: boolean;
  };
}) {
  const { interval = 5000, include } = options;
  const buffer: MonitoringBuffer = {
    lastFlush: new Date(),
    http: [],
    img: [],
    esMessages: [],
  };
  const { listen: listenXHR } = initXHRListener();
  const stopListeningXHR = listenXHR((req) => {
    if (req.url.includes(import.meta.env.V_FRONTEND_MONITOR_BASE_URL)) return;
    if (include && !include.xhr) return;
    buffer.http.push(req);
  });
  const { listen: listenFetch } = initFetchListener();
  const stopListeningFetch = listenFetch((req) => {
    if (req.url.includes(import.meta.env.V_FRONTEND_MONITOR_BASE_URL)) return;
    if (include && !include.fetch) return;
    buffer.http.push(req);
  });
  const { listen: listenEventSource, listenToMessages } = initEventSourceListener();
  const stopListeningEventSource = listenEventSource((req) => {
    if (include && !include.eventSource) return;
    buffer.http.push(req);
  });
  const stopListeningEventSourceMessages = listenToMessages((msg) => {
    if (include && !include.eventSource) return;
    buffer.esMessages.push(msg);
  });
  const { subscribe: subscribeImg } = initImgListener();
  const unsubscribeImg = subscribeImg((reqs) => {
    if (include && !include.img) return;
    buffer.img = reqs;
  });
  const iv = setInterval(() => {
    if (buffer.http.length === 0 && buffer.img.length === 0 && buffer.esMessages.length === 0) return;
    const payload = processBuffer(buffer);
    compress(payload).then((compressed) => {
      try {
        submit(compressed);
      } catch {}
    });
    buffer.http = [];
    buffer.img = [];
    buffer.esMessages = [];
    buffer.lastFlush = new Date();
  }, interval);
  return () => {
    clearInterval(iv);
    stopListeningXHR();
    stopListeningFetch();
    stopListeningEventSource();
    stopListeningEventSourceMessages();
    unsubscribeImg();
  };
}

initMonitoring({
  interval: 5000,
  include: {
    xhr: true,
    fetch: true,
    eventSource: false,
    img: false,
  },
});
