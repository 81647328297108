<script setup lang="ts">
import { NButton, NIcon, NCollapseTransition } from 'naive-ui';
import { CloseOutline } from '@vicons/ionicons5';
//@ts-ignore
import Markdown from 'vue3-markdown-it';
export type BannerData = {
  markdown: string;
  closable: boolean;
  rememberClose: boolean;
  backgroundColor: string;
  textColor?: string;
};
const bannerData = ref<BannerData | null>(null);
provide('banner-data', bannerData);
const memory = useLocalStorage('banner-memory', new Map<string, string>());
const rememberClosed = computed(() => {
  if (!bannerData.value || !bannerData.value.rememberClose) return false;
  return memory.value.get(JSON.stringify(bannerData.value)) === 'closed';
});
const closed = ref(false);
const close = () => {
  if (bannerData.value?.rememberClose) {
    memory.value.set(JSON.stringify(bannerData.value), 'closed');
  }
  closed.value = true;
};
const reset = () => {
  console.log('reset');
  closed.value = false;
  memory.value.delete(JSON.stringify(bannerData.value));
};
provide('banner-reset', reset);
const mdRef = ref<HTMLElement | null>(null);
const textCSSVar = useCssVar('--text', mdRef);
watch(
  () => bannerData.value?.textColor,
  (nv) => {
    if (!nv) return;
    textCSSVar.value = nv;
  },
  { immediate: true }
);
</script>

<template>
  <div class="banner-provider">
    <n-collapse-transition :show="bannerData !== null && !closed && !rememberClosed">
      <div
        v-if="bannerData && !closed && !rememberClosed"
        class="banner"
        :style="{
          backgroundColor: bannerData?.backgroundColor,
        }"
      >
        <div class="flex-grow flex items-center justify-center">
          <Markdown ref="mdRef" class="md" breaks :source="bannerData.markdown" />
        </div>
        <n-button v-if="bannerData.closable" circle quaternary @click="close">
          <template #icon>
            <n-icon>
              <CloseOutline />
            </n-icon>
          </template>
        </n-button>
      </div>
    </n-collapse-transition>
    <div class="content-wrapper">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner-provider {
  @apply w-full h-full flex flex-col;
  .content-wrapper {
    @apply relative flex-grow;
  }
  .banner {
    @apply flex items-center justify-between z-[1000] p-4;
    border-bottom: 1px solid var(--borders);
    .md {
      :deep(*) {
        a,
        a:visited,
        a:-webkit-any-link {
          color: var(--text) !important;
        }
        color: var(--text) !important;
      }
    }
  }
}
</style>
