<script setup lang="ts">
import { oneWaySync } from '../../util/reactivity';
interface Props {
  src?: string;
  fallback?: string;
  sharp?: boolean;
  circle?: boolean;
}
const props = defineProps<Props>();
const { src, fallback, sharp } = toRefs(props);
const emit = defineEmits(['loaded']);
const imgSrc = ref(src?.value);
if (src) oneWaySync(imgSrc, src);
const loaded = ref(false);
const imgRef = ref<HTMLImageElement>();
const naturalDimensions = reactive({
  width: 0,
  height: 0,
});
const aspectRatio = computed(() => {
  //if browser is safari, return undefined
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    return undefined;
  }
  if (!imgRef.value) return 1;
  return naturalDimensions.width / naturalDimensions.height;
});
watch(
  imgRef,
  (el) => {
    if (el) {
      el.onload = () => {
        loaded.value = true;
        emit('loaded');
        naturalDimensions.width = el.naturalWidth;
        naturalDimensions.height = el.naturalHeight;
      };
      // @ts-ignore
      el.onerror = (e: Event) => {
        imgSrc.value = fallback?.value;
      };
    }
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="b-img"
    :class="{ round: !sharp && !circle, circle }"
    :style="{
      aspectRatio,
    }"
  >
    <div v-if="!src || !loaded" class="skeleton-wrapper">
      <n-skeleton class="skeleton" />
    </div>
    <img v-show="src && loaded" ref="imgRef" class="image" :src="imgSrc" :key="imgSrc" />
  </div>
</template>

<style lang="scss" scoped>
.b-img {
  @apply relative flex-grow;
  &.round > .image {
    border-radius: var(--roundness);
  }
  &.circle > .image {
    border-radius: 9999px;
  }
  .image {
    @apply m-auto w-full pointer-events-none object-contain;
    background-color: var(--foreground1);
  }
  .skeleton-wrapper {
    @apply aspect-square;
    .skeleton {
      @apply w-full h-full;
    }
  }
}
</style>
