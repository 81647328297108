import { randomString } from "../crypto";
import { HTTPRequest } from "./types";

export function initFetchListener(excludeUrl?: RegExp) {
  const listeners = new Map<string, (req: HTTPRequest) => void>();
  const origFetch = window.fetch;
  window.fetch = function (...args) {
    const { method, url, headers } = parseFetchArgs(args);
    if (excludeUrl?.test(url)) {
      //@ts-ignore
      return origFetch.apply(this, args);
    }
    const reqHeaders = {} as Record<string, string>;
    headers?.forEach((value, key) => {
      reqHeaders[key] = value;
    });
    const data: HTTPRequest = {
      location: window.location.href,
      initiator: "fetch",
      method,
      url,
      startTime: Date.now(),
      reqHeaders,
    };
    return (
      origFetch
        // @ts-ignore
        .apply(this, args)
        .then(
          (res) => {
            data.status = res.status;
            data.resHeaders = {};
            res.headers.forEach((value, key) => {
              data.resHeaders![key] = value;
            });
            switch (res.headers.get("content-type")) {
              case "application/json":
                data.resBody = res.clone().json();
              case "text/plain":
                data.resBody = res.clone().text();
            }
            return res;
          },
          (err) => {
            data.error = JSON.stringify(err);
            throw err;
          }
        )
        .finally(() => {
          data.endTime = Date.now();
          listeners.forEach((listener) => {
            listener(data);
          });
        })
    );
  };
  return {
    listen: (listener: (req: HTTPRequest) => void) => {
      const id = randomString(8);
      listeners.set(id, listener);
      return () => {
        listeners.delete(id);
      };
    },
  };
}

// stolen from https://github.com/getsentry/sentry-javascript/blob/500fc66775ee1b9d57d4ce4508593611ca3a84df/packages/utils/src/instrument.ts#L192
type FetchResource = string | { toString(): string } | { url: string };

// stolen from https://github.com/getsentry/sentry-javascript/blob/500fc66775ee1b9d57d4ce4508593611ca3a84df/packages/utils/src/instrument.ts#L217C1-L236C2
function parseFetchArgs(fetchArgs: unknown[]) {
  if (fetchArgs.length === 0) {
    return { method: "GET", url: "" };
  }

  if (fetchArgs.length === 2) {
    const [url, options] = fetchArgs as [FetchResource, object];

    return {
      url: getUrlFromResource(url),
      method: hasProp(options, "method") ? String(options.method).toUpperCase() : "GET",
      // my addition
      headers: hasProp(options, "headers") ? new Headers(options.headers as any) : null,
    };
  }

  const arg = fetchArgs[0];
  return {
    url: getUrlFromResource(arg as FetchResource),
    method: hasProp(arg, "method") ? String(arg.method).toUpperCase() : "GET",
  };
}

// stolen from https://github.com/getsentry/sentry-javascript/blob/500fc66775ee1b9d57d4ce4508593611ca3a84df/packages/utils/src/instrument.ts#L194
function getUrlFromResource(resource: FetchResource): string {
  if (typeof resource === "string") {
    return resource;
  }

  if (!resource) {
    return "";
  }

  if (hasProp(resource, "url")) {
    return resource.url;
  }

  if (resource.toString) {
    return resource.toString();
  }

  return "";
}

// stolen from https://github.com/getsentry/sentry-javascript/blob/500fc66775ee1b9d57d4ce4508593611ca3a84df/packages/utils/src/instrument.ts#L188
function hasProp<T extends string>(obj: unknown, prop: T): obj is Record<string, string> {
  return !!obj && typeof obj === "object" && !!(obj as Record<string, string>)[prop];
}
