import { onLogout } from '../util/logout';
import { pinia } from './pinia';
import { NotificationOutput } from '@bluepic/types/src/Auth/notification.output';
import { upsert } from '../util/arrays';
import { NotificationSettingsOutput } from '@bluepic/types/src/Auth/notificationSettings.output';
import { message } from '../util/naiveUI';
import Notification from '../components/notification/index.vue';

const pageSize = 10;

type NotificationStoreState = {
  settings: NotificationSettingsOutput | null;
  notifications: NotificationOutput[];
  count: number | null;
  lastSkip: number | null;
  deleted: string[];
};

type NotificationStoreActions = {
  tryFetch: () => Promise<void>;
  fetchMoreNotifications: () => Promise<void>;
  initFeed: () => void;
};

let fetchingNotifications = false;
export const useNotificationStore = defineStore<string, NotificationStoreState, {}, NotificationStoreActions>('notification', {
  state: () => ({
    settings: null,
    notifications: [] as NotificationOutput[],
    count: null,
    lastSkip: null,
    deleted: [],
  }),
  actions: {
    async tryFetch() {
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      try {
        this.settings = (await getSettings(undefined, authStore.jwt)) ?? null;
        const res = await getNotifications(
          {
            skip: 0,
            take: pageSize,
          },
          authStore.jwt
        );
        if (!res) return;
        this.notifications = res.results;
        this.count = res.count;
        this.lastSkip = res.offset;
        this.initFeed();
      } catch (e) {
        console.error(e);
      }
    },
    async fetchMoreNotifications() {
      if (fetchingNotifications) return;
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      if ((this.lastSkip ?? 0) + pageSize >= (this.count ?? 0)) return;
      fetchingNotifications = true;
      try {
        const res = await getNotifications(
          {
            skip: (this.lastSkip ?? 0) + pageSize,
            take: pageSize,
          },
          authStore.jwt
        );
        if (!res) return;
        for (const notification of res.results) {
          upsert(this.notifications, (a) => a.id === notification.id, notification);
        }
        this.count = res.count;
        this.lastSkip = res.offset;
      } catch (e) {
        console.error(e);
      } finally {
        fetchingNotifications = false;
      }
    },
    initFeed() {
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      useFeed(authStore.jwt, ['notifications'], ['SETTINGS', 'NOTIFICATION'], (m) => {
        if (m.service !== 'notifications') return;
        switch (m.operation) {
          case 'UPDATE_SETTINGS':
            if (!m.payload) return;
            this.settings = JSON.parse(m.payload);
            break;
          case 'CREATE_NOTIFICATION_' + import.meta.env.V_ENV:
            if (!m.payload) return;
            const notification = JSON.parse(m.payload) as NotificationOutput;
            if (this.notifications.find((n) => n.id === notification.id)) return;
            notification.timestamp = new Date(notification.timestamp);
            notification.read = notification.read ? new Date(notification.read) : null;
            const msg = message.create('', {
              render: () =>
                h(Notification, {
                  notification,
                  hideNew: true,
                  noDelete: true,
                  close: () => msg.destroy(),
                }),
              keepAliveOnHover: true,
              duration: 5000,
              closable: true,
            });
            upsert(this.notifications, (a) => a.id === notification.id, notification);
            break;
          case 'MARK_AS_READ':
            if (!m.payload) return;
            const { ids, timestamp } = JSON.parse(m.payload) as { ids: string[]; timestamp: string };
            for (const notification of this.notifications) {
              if (ids.includes(notification.id)) {
                notification.read = new Date(timestamp);
              }
            }
            break;
          case 'DELETE_NOTIFICATIONS':
            if (!m.payload) return;
            const toBeDeleted = JSON.parse(m.payload) as string[];
            this.notifications = this.notifications.filter((n) => !toBeDeleted.includes(n.id));
            this.deleted = this.deleted.filter((n) => !toBeDeleted.includes(n));
            break;
        }
      });
    },
  },
});

onLogout(() => {
  useNotificationStore(pinia).$reset();
});
