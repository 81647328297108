import { App } from 'vue';

const registeredHandlers = new Map<string, (e: MouseEvent) => void>();

export function initClickout(app: App) {
  app.directive('clickout', (el, binding) => {
    if (el.getAttribute('data-clickout-uid')) {
      return;
    }
    const uid = Math.random().toString(36).slice(2);
    el.setAttribute('data-clickout-uid', uid);
    registeredHandlers.set(uid, (e) => {
      if (!el.contains(e.target)) {
        binding.value(e);
      }
    });
  });
  document.addEventListener(
    'click',
    (e) => {
      for (const uid of registeredHandlers.keys()) {
        if (!document.querySelector(`[data-clickout-uid="${uid}"]`)) {
          // console.log("removing handler", uid);
          registeredHandlers.delete(uid);
        }
        registeredHandlers.get(uid)?.(e);
      }
    },
    { passive: true }
  );
}
