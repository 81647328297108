{
  "email": "Email Address",
  "password": "Password",
  "username": "Username",
  "consent": {
    "agree": "By registering I agree to the",
    "terms": "Terms and Conditions",
    "and": "and",
    "privacy": "Privacy Policy",
    "suffix": ""
  },
  "password-rating": {
    "weak": "Weak",
    "medium": "medium",
    "strong": "strong",
    "tooWeak": "too weak"
  },
  "error": "Something went wrong...",
  "errors": {
    "ERR_AUTH_1": "You don't have permission to create an apikey for this user",
    "ERR_AUTH_2": "Apikey expiry cannot be more than 1 year in the future",
    "ERR_AUTH_3": "You do not have permission to create multi-use apikeys",
    "ERR_AUTH_4": "You don't have permission to create a magic link for this user",
    "ERR_AUTH_5": "Apikey not found",
    "ERR_AUTH_6": "You don't have permission to view this user's apikeys",
    "ERR_AUTH_7": "You don't have permission to delete this apikey",
    "ERR_AUTH_9": "User is locked. Check your emails.",
    "ERR_AUTH_10": "User not found",
    "ERR_AUTH_11": "Invalid key",
    "ERR_AUTH_12": "You took too long to enter the code.",
    "ERR_AUTH_13": "Email confirmation not found",
    "ERR_AUTH_14": "Email confirmation expired",
    "ERR_AUTH_15": "Error confirming email",
    "ERR_AUTH_16": "User is not allowed to invite to this space",
    "ERR_AUTH_17": "User is not allowed to redeem this invite",
    "ERR_AUTH_18": "Invite not found",
    "ERR_AUTH_19": "User is not allowed to read invites",
    "ERR_AUTH_20": "User is not allowed to revoke this invite",
    "ERR_AUTH_21": "User is not allowed to decline this invite",
    "ERR_AUTH_22": "You are not allowed to get metadata for this user",
    "ERR_AUTH_23": "You are not allowed to set metadata for this user",
    "ERR_AUTH_24": "Metadata not found",
    "ERR_AUTH_25": "You do not have permission to get this users permissions",
    "ERR_AUTH_26": "Reset not found",
    "ERR_AUTH_27": "Reset expired",
    "ERR_AUTH_28": "You cannot delete another user session",
    "ERR_AUTH_29": "You cannot view this user's sessions",
    "ERR_AUTH_30": "You are not allowed to create a space",
    "ERR_AUTH_31": "You are not allowed to create a subteam for this space",
    "ERR_AUTH_33": "Space name already exists in your organization",
    "ERR_AUTH_35": "You are not allowed to get all spaces",
    "ERR_AUTH_36": "Space not found",
    "ERR_AUTH_37": "User is already admin",
    "ERR_AUTH_38": "You can't add admins to this space",
    "ERR_AUTH_39": "User is not admin",
    "ERR_AUTH_40": "You cannot remove the last admin",
    "ERR_AUTH_41": "You can't remove admins from this space",
    "ERR_AUTH_42": "User is already member",
    "ERR_AUTH_43": "User is not a member",
    "ERR_AUTH_44": "You can't remove members from this space",
    "ERR_AUTH_45": "User is an admin",
    "ERR_AUTH_46": "You are not allowed to lock this user",
    "ERR_AUTH_47": "Space is not locked",
    "ERR_AUTH_48": "You are not allowed to edit this space",
    "ERR_AUTH_49": "Space with this name already exists",
    "ERR_AUTH_50": "You are not allowed to edit space members",
    "ERR_AUTH_51": "You are not allowed to edit space themes",
    "ERR_AUTH_52": "You are not allowed to change this spaces passthroughPermissions",
    "ERR_AUTH_53": "You can only pass through permissions that are already present on the space",
    "ERR_AUTH_54": "You are not allowed to change this spaces permissions",
    "ERR_AUTH_55": "You are not allowed to delete this space",
    "ERR_AUTH_56": "You can not delete a space that has children",
    "ERR_AUTH_57": "Dummy users cannot have TOTP enabled",
    "ERR_AUTH_58": "A user with this email already exists",
    "ERR_AUTH_59": "A dummy user must be assigned to a space",
    "ERR_AUTH_60": "Passwords must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter and one number",
    "ERR_AUTH_61": "You can only create dummy users for your own space",
    "ERR_AUTH_62": "You are not allowed to unlock this user",
    "ERR_AUTH_63": "You are not allowed to remove this user",
    "ERR_AUTH_64": "no users found",
    "ERR_AUTH_67": "dummy users cannot use 2fa",
    "ERR_AUTH_68": "no new totp secret to confirm",
    "ERR_AUTH_69": "invalid token",
    "ERR_AUTH_70": "You are not allowed to edit this user",
    "ERR_AUTH_72": "You are not allowed to edit max sessions",
    "ERR_AUTH_73": "You are not allowed to change this users permissions",
    "ERR_AUTH_74": "You are not allowed to change this users password",
    "ERR_AUTH_76": "No new email to confirm",
    "ERR_AUTH_79": "value is required",
    "ERR_AUTH_80": "Apikey expired",
    "ERR_AUTH_81": "Admins and members must not intersect",
    "ERR_AUTH_82": "Profile is disabled",
    "ERR_AUTH_83": "Invalid key",
    "ERR_AUTH_84": "Invitation is required during closed beta",
    "ERR_AUTH_85": "User is not an affiliate",
    "ERR_AUTH_86": "Profile is disabled",
    "ERR_AUTH_87": "No auth header provided",
    "ERR_AUTH_88": "Invalid auth header",
    "ERR_AUTH_89": "Invalid API key",
    "ERR_AUTH_90": "Invalid Session",
    "ERR_AUTH_92": "Invalid OIDC Token",
    "ERR_AUTH_93": "Custom price not found",
    "ERR_AUTH_94": "Custom price is used by spaces",
    "ERR_AUTH_95": "Subscription not found",
    "ERR_AUTH_96": "Customer not found",
    "ERR_AUTH_97": "Choose your own price not allowed",
    "ERR_AUTH_98": "Space not found",
    "ERR_AUTH_99": "Subteams cannot have their own subscriptions",
    "ERR_AUTH_100": "User is not space owner",
    "ERR_AUTH_101": "Price could not be generated",
    "ERR_AUTH_102": "Could not create subscription",
    "ERR_AUTH_103": "Invalid custom price expression",
    "ERR_AUTH_104": "User is already a member of this space",
    "ERR_AUTH_105": "You cannot set metadata for both user and space",
    "ERR_AUTH_106": "Unrecognised issuer",
    "ERR_AUTH_107": "Not implemented",
    "ERR_AUTH_108": "You are not allowed to create a space without subscription",
    "ERR_AUTH_109": "You are not allowed to hard-delete this space",
    "ERR_AUTH_110": "You are not allowed to edit this spaces subscription",
    "ERR_AUTH_111": "You are not allowed to check this spaces subscription",
    "ERR_AUTH_112": "Only root spaces can have subscriptions",
    "ERR_AUTH_113": "No token",
    "ERR_AUTH_114": "No email in token",
    "ERR_AUTH_115": "Invalid OIDC ID Token",
    "ERR_AUTH_116": "Email is required for non-dummy users",
    "ERR_AUTH_117": "This user is not part of this space",
    "ERR_AUTH_118": "no email",
    "ERR_AUTH_119": "ID is already associated with an account",
    "ERR_AUTH_120": "ID not found",
    "ERR_AUTH_122": "This space can't have any more members. Contact the team owner."
  },
  "night": "Night",
  "morning": "Morning",
  "afternoon": "Afternoon",
  "evening": "Evening",
  "feed-type": {
    "you": {
      "CREATED": "you created a draft",
      "COPIED": "you copied a draft",
      "MOVED": "you moved a draft",
      "UPDATED": "you updated a draft",
      "DELETED": "you deleted a draft",
      "RESTORED": "you restored a draft",
      "COMMENT": "you commented"
    },
    "they": {
      "CREATED": "created a draft",
      "COPIED": "copied a draft",
      "MOVED": "moved a draft",
      "UPDATED": "updated a draft",
      "DELETED": "deleted a draft",
      "RESTORED": "restored a draft",
      "COMMENT": "commented"
    }
  },
  "already-have-account": "You already have an account?",
  "back-to-sign-in": "back to sign in",
  "confirm-success": "Thanks for confirming your email-address!",
  "confirming-email": "Confirming your email-address...",
  "consent-required": "Consent is required",
  "do-sign-in": "Sign in",
  "do-sign-up": "Sign up",
  "dont-have-an-account": "Don't have an account?",
  "email-invalid": "Email Address is invalid",
  "email-required": "Email Address is required",
  "email-resent": "email re-sent",
  "forgot-password": "Forgot your password?",
  "forgot-text": "Enter your email address and we'll send you a link to reset your password.",
  "invalid-confirm-token": "invalid confirm token",
  "invalid-magic-link": "invalid Sign-In Link",
  "new-password": "new password",
  "password-invalid": "password doesn't match requirements",
  "password-required": "Password required",
  "password-strength": "Password strength",
  "passwords-dont-match": "Passwords don't match",
  "redirecting-to-sign-in": "Redirecting you to the Sign in Page...",
  "register-success": "Now you just need to confirm your email address. Check your mailbox.",
  "remember-me": "Remember me",
  "repeat-password": "Repeat password",
  "resend-email": "resend",
  "reset-password": "Reset password",
  "return-to-sign-in": "Return to sign in",
  "send-email": "Send email",
  "send-sign-in-link": "Send me a Sign-In Link",
  "sign-in": "Sign in",
  "sign-in-link": "Sign-In Link",
  "sign-up": "Sign up",
  "this-shouldnt-happen": "this shouldn't happen. Please report this to the developers.",
  "you-can-close-this-tab": "You can close this tab now",
  "workspace-is-required": "Workspace is required",
  "title-is-required": "Title is required",
  "editor": "Editor",
  "save-to-collection": "Save to Post",
  "space": "Space",
  "select-a-workspace": "Select a workspace",
  "title": "Title",
  "text": "Text",
  "save": "Save",
  "gallery": "Gallery",
  "signing-in": "Signing in",
  "email-sent": "Email sent",
  "magic-link-sent": "We sent you a link, that will automagically sign you in. Check your mailbox",
  "invite-redeemed": "Invite redeemed",
  "invite-sent": "Invite sent",
  "invites": "Invites",
  "only-redeemed": "Only redeemed",
  "success": "Success",
  "invites-sent-successfully": "Invites sent successfully",
  "invites-could-not-be-sent": "Invites could not be sent",
  "failed-invites": "Failed Invites:",
  "retry": "Retry",
  "invite-sent-successfully": "Invite sent successfully",
  "invite-will-be-sent": "Invite will be sent",
  "disabled": "Disabled",
  "get-invite": "Get Invite",
  "edit": "Edit",
  "delete-api-key": "Delete API key",
  "are-you-sure-this-action-cannot-be-undone": "Are you sure? This action cannot be undone",
  "yes": "Yes",
  "cancel": "Cancel",
  "expires": "expires:  {0}",
  "uses-apikey": "uses: {0}{1}",
  "api-keys": "API Keys",
  "copied-to-clipboard": "Copied to clipboard",
  "delete-collection": "Delete Post",
  "confirm-permanently-delete-collection": "Are you sure you want to permanently delete this Post? This is irreversible!",
  "im-sure": "I'm sure",
  "collection-deleted": "Post deleted",
  "created-created": "created: {0}",
  "last-edit-lastedit": "last edit: {0}",
  "created-by-creator-name": "created by {0}",
  "no-field-for-datatype-type-properties-modelvalue": "No field for datatype \"{0}\" {1} {2}",
  "field-not-found": "Field not found",
  "editor-warning.title": "Beta Version!",
  "editor-warning.content": "This is an early-stage beta version. We highly recommend to use Google Chrome instead of Safari, Firefox or Edge at this time to ensure everyrthing looking and working as you expect. Full-browser support is on our list and will be there on release.",
  "editor-warning.confirm": "Ok, I understand",
  "download": "Download",
  "share": "Share",
  "reset-view": "Reset View",
  "feed": "History",
  "incoming-invites": "Incoming Invites:",
  "invite-accepted": "Invite accepted!",
  "invite-declined": "Invite declined!",
  "invited-by-inviter-name": "invited by {0}",
  "pending-invites": "Pending Invites",
  "invite-resent": "Invite resent",
  "invite-revoked": "Invite revoked",
  "resend": "Resend",
  "revoke": "Revoke",
  "spaces": "Spaces",
  "settings": "Settings",
  "key-is-required": "Key is required",
  "name-cannot-contain-spaces": "Name cannot contain spaces",
  "value-is-required": "Value is required",
  "metadata-created": "Metadata created",
  "metadata": "Metadata",
  "set-metadata": "Set Metadata",
  "key": "Key",
  "value": "Value",
  "create": "Create",
  "delete-metadata": "Delete Metadata",
  "are-you-sure-you-want-to-delete-this-metadata-entry": "Are you sure you want to delete this metadata entry?",
  "metadata-deleted": "Metadata deleted",
  "delete": "Delete",
  "only-png-and-jpeg-files-are-allowed": "Only PNG and JPEG files are allowed",
  "upload": "Upload",
  "zoom-and-crop": "Zoom & Crop",
  "finish": "Finish",
  "profile-picture-updated": "Profile picture updated",
  "change-picture": "Change Picture",
  "zoom": "Zoom",
  "tap-here-to-upload": "Tap here to upload",
  "click-or-drag-a-file-to-this-area-to-upload": "Click or drag a file to this area to upload",
  "max-16mb-allowed-png-jpg-jpeg": "Max. 20MB, Allowed: .png .jpg .jpeg",
  "kill-session": "Kill session",
  "are-you-sure-you-will-be-logged-out-on-this-device": "Are you sure? You will be logged out on this device",
  "ip-session-ip": "IP: {0}",
  "last-seen-lastseen": "Last seen: {0}",
  "kill-all-sessions": "Kill all sessions",
  "are-you-sure-you-will-be-logged-out-on-all-other-devices": "Are you sure? You will be logged out on all other devices",
  "sessions": "Sessions",
  "close-all-other-sessions": "close all other sessions",
  "name-is-required": "Name is required",
  "space-created": "Space Created",
  "name": "Name",
  "email-is-required": "Email is required",
  "invite-user": "Invite User",
  "none": "None",
  "no-category": "No Category",
  "search": "Search",
  "failed-to-update-user": "Failed to update user",
  "share-with-sub-spaces": "share with sub-spaces",
  "admin-only": "Admin only",
  "select-variant": "Select variant:",
  "edit-theme": "Edit Theme",
  "reset": "Reset",
  "primary": "Primary",
  "background": "Background",
  "greyscale-background": "Greyscale Background",
  "advanced": "Advanced",
  "secondary": "Secondary",
  "background-1": "Background 1",
  "background-2": "Background 2",
  "foreground-1": "Foreground 1",
  "foreground-2": "Foreground 2",
  "borders": "Borders",
  "warning": "Warning",
  "error-0": "Error",
  "roundness": "Roundness",
  "font": "Font",
  "background-gradient": "Background Gradient",
  "leave": "Leave",
  "demote": "Demote",
  "remove": "Remove",
  "promote": "Promote",
  "resend-confirmation-email": "resend confirmation email",
  "bluepic-admin": "Bluepic Admin",
  "min-8-characters": "min. 8 characters*",
  "lower-case": "lower case",
  "upper-case": "upper case",
  "digits": "digits",
  "special-characters": "special characters",
  "profile-updated-successfully": "Profile updated successfully",
  "comment": "Comment",
  "standalone-link": "Standalone Link:",
  "create-profile": "Create Profile",
  "delete-account": "Delete account",
  "confirm-delete-account": "Are you sure you want to delete your account? For your own privacy, this is permanent and cannot be undone.",
  "are-you-absolutely-sure": "Are you absolutely sure?",
  "last-chance-to-keep-account": "This is your last chance to cancel. Once you delete your account, it is gone forever.",
  "offboarding": "Thank you for using our service. All your data has been deleted permanently. You will be logged out now...",
  "profile-picture": "Profile Picture",
  "delete-user": "Delete User",
  "left-space": "Left space",
  "parent": "Parent",
  "leave-space": "Leave Space",
  "you-cant-leave-if-youre-the-only-admin": "You can't leave if you're the only admin",
  "saved": "Saved",
  "delete-space": "Delete Space",
  "confirm-delete-space": "Are you sure you want to delete this space? If there is a subscription for this space, it will be cancelled at the end of your billing period.",
  "space-deleted": "Space deleted",
  "edit-templates": "Edit Templates",
  "change": "change",
  "theme": "Theme",
  "preview": "Preview",
  "your-spaces": "Your Spaces:",
  "create-space": "Create Space",
  "appearance": "General",
  "language": "Language",
  "font-size": "Font Size",
  "notifications": "Notifications",
  "me": "Me",
  "admin": "Admin",
  "affiliate": "Affiliate",
  "templates": "Templates",
  "billing": "Billing",
  "my-templates": "My Templates",
  "my-spaces": "My Spaces",
  "log-out": "Log Out",
  "confirm-logout": "Are you sure you want to log out?",
  "content": "Content",
  "fontsize": {
    "small": "small",
    "medium": "medium",
    "large": "large"
  },
  "livemode": {
    "auto": "Auto",
    "always": "Always",
    "never": "Never"
  },
  "editor-live-preview": "Editor live preview",
  "live-preview-help": "Changes made in the editor will be immediately visible. May lead to performace issues on some devices.",
  "rendering": "rendering",
  "drafts": "Drafts",
  "s-space": "'s Space",
  "draft": "Draft",
  "found-drafts": "Found drafts:",
  "start-fresh": "Start fresh",
  "only-unredeemed": "Only unredeemed",
  "redeemed-invites": "Redeemed invites:",
  "you": "you",
  "email-confirmed": "email confirmed",
  "organization": "Organization",
  "organization-shorthand": "Org",
  "email-template": "Email Template",
  "monthly": "Monthly",
  "month": "Month",
  "yearly": "Yearly",
  "year": "Year",
  "current-plan": "Current plan",
  "free": "Free",
  "pro": "Pro",
  "enterprise": "Enterprise",
  "switch-to-yearly-billing": "Switch to yearly billing",
  "switch-to-monthly-billing": "Switch to monthly billing",
  "billing-contact": "Billing Contact",
  "phone": "Phone",
  "street": "Address line 1",
  "address-line-2": "Address Line 2",
  "zip": "Postal Code",
  "city": "City",
  "state": "State",
  "invoices": "Invoices",
  "no-invoices-found": "No invoices found",
  "open": "Open",
  "paid": "Paid",
  "uncollectible": "Uncollectible",
  "void": "Void",
  "pay": "Pay",
  "load-more": "Load More",
  "payment-methods": "Payment Methods",
  "no-payment-methods-found": "No payment methods found",
  "default": "Default",
  "add-payment-method": "Add Payment Method",
  "delete-payment-method": "Delete Payment Method",
  "are-you-sure-you-want-to-delete-this-payment-method": "Are you sure you want to delete this payment method?",
  "confirm": "Confirm",
  "subscriptions": "Subscriptions",
  "per": "per",
  "cancel-subscription": "Cancel Subscription",
  "are-you-sure-you-want-to-cancel-this-subscription": "Are you sure you want to cancel this subscription?",
  "expiration-date": "Expiration date",
  "phone-is-required": "Phone is required",
  "address-line-1-is-required": "Address line 1 is required",
  "postal-code-is-required": "Postal code is required",
  "city-is-required": "City is required",
  "state-is-required": "State is required",
  "fast": "fast",
  "slow": "slow",
  "transition-speed": "Transition Speed",
  "you-dont-have-permission-for-this-template": "You don't have permission for this template.",
  "are-you-in-the-right-space": "Are you in the right space?",
  "copy-link": "Copy link",
  "invalid-invite": "Invalid invite",
  "you-are-not-the-intended-recipient-of-this-invite": "You are not the intended recipient of this invite.",
  "you-have-been-invited-to-join": "You have been invited to join:",
  "by": "by",
  "accept": "Accept",
  "decline": "Decline",
  "pro-badge": "PRO",
  "next-payment": "Next payment",
  "cancel-subscription-short": "cancel",
  "keep-it": "keep it",
  "subscription-canceled": "Subscription canceled",
  "switch-to-pro": "Switch to pro",
  "switch-to-free": "Switch to free",
  "free-forever": "Free forever",
  "professional": "Professional",
  "pro-header": "Professional - 5€ / month",
  "pro-subtitle": "For independent content creators",
  "unlimited-projects": "unlimited projects",
  "5-personal-templates": "5 personal templates",
  "unlimited-personal-templates": "unlimited personal templates",
  "full-access-to-learning-material": "full access to learning material",
  "early-access-to-new-template-collections": "early access to new template collections",
  "cancel-pro-subscription": "Are you sure you want to cancel your pro subscription? You will lose access to all pro features. Your excess personal templates will be deleted.",
  "running-out": "Running out",
  "reactivate": "Reactivate",
  "delete-all": "delete all",
  "are-you-sure-you-want-to-delete-all-drafts-this-cannot-be-undone": "Are you sure you want to delete all drafts? This cannot be undone.",
  "confirm-purchase": "Confirm Purchase",
  "renews-automatically": "renews automatically",
  "coupon": "Coupon",
  "apply": "Apply",
  "subtotal": "Subtotal",
  "vat": "VAT",
  "total": "Total",
  "shared": "shared",
  "upcoming-payments": "Upcoming payments:",
  "all-subscriptions": "All subscriptions",
  "deleted-user": "deleted user",
  "visible": "visible",
  "hidden": "hidden",
  "failed-to-update-space": "Failed to update space",
  "mixed": "mixed",
  "enabled": "enabled",
  "minimal": "minimal",
  "medium": "medium",
  "extra": "extra",
  "coupon-applied": "Coupon applied",
  "active": "active",
  "make-default": "Make default",
  "no-subscription": "no subscription",
  "owner": "owner",
  "sub_inactive": "Subscription is inactive",
  "sub_noSubscription": "We could not find a subscription for this space",
  "sub_noPayment": "This subscription was not paid for",
  "sub_notEnoughSlots": "You have more members than your subscription allows",
  "sub_notEnoughDepth": "You have more subteam layers than your subscription allows",
  "sub_canceled": "Subscription canceled",
  "subscription": "Subscription",
  "action-required": "Action required",
  "pay-now": "Pay now",
  "subscribe-now": "Subscribe now",
  "upgrade-subscription-now": "Upgrade subscription now",
  "slots": "Slots",
  "hierarchy-layers": "Subteam Layers",
  "incl-tax": "incl. Tax",
  "tax": "Tax",
  "incl": "incl",
  "excl": "excl",
  "payment-interval": "Payment interval",
  "hard-delete-space": "HARD-Delete Space",
  "edit-subscription": "Edit subscription",
  "slots-are-required": "Slots are required",
  "minimum-status-value-totalmembers-5-slots": "Minimum {0} slots",
  "maximum-300-slots": "Maximum 300 slots",
  "hierarchy-layers-are-required": "Subteam layers are required",
  "minimum-status-value-actualdepth-1-layer": "Minimum {0} layer",
  "maximum-1-layer": "Maximum 1 layer",
  "you-need-to-add-a-payment-method-before-you-can-purchase-a-subscription": "You need to add a payment method before you can purchase a subscription.",
  "all-users": "All Users",
  "all-spaces": "All Spaces",
  "custom-prices": "Custom Prices",
  "admin_prices": "Custom Prices",
  "admin_users": "All Users",
  "admin_teams": "All Spaces",
  "admin_feature_flags": "Feature flags",
  "no-custom-prices-found": "No custom prices found",
  "create-custom-price": "Create custom price",
  "price": "Price",
  "update": "Update",
  "expression": "Expression",
  "expression-is-required": "Expression is required",
  "javascript-expression": "Javascript Expression",
  "try-it-out": "Try it out",
  "choose-your-own-price": "Pay what you want",
  "user-ids": "User ids",
  "search-users-by-email": "Search users by email",
  "invalid-expression": "Invalid expression",
  "users": "Users",
  "no-subscription-needed": "No subscription needed",
  "custom-price": "Custom Price",
  "min-newprice": "(min. {0})",
  "select": "Select",
  "admin-settings": "Admin Settings",
  "use-webgl-when-available": "use WebGL when available",
  "experimental": "experimental",
  "uploading": "Uploading",
  "normalizing": "Normalizing",
  "checking-permission": "Checking permission",
  "upload-failed": "Upload Failed",
  "please-let-us-know-if-this-keeps-happening": "Please let us know if this keeps happening.",
  "image-file-size-is-too-big": "Image file size is too big.",
  "image-file-is-corrupted": "Image file is corrupted.",
  "onboarding": "Onboarding",
  "start": "Start",
  "resume": "Resume",
  "learn-to-use-bluepic-social": "Learn how to use Bluepic Social",
  "reset-progress": "Reset progress",
  "reset-all": "Reset all",
  "reset-all-progress": "Reset all progress",
  "are-you-sure-you-want-to-reset-all-progress": "Are you sure you want to reset all progress?",
  "feature-flags": "Feature flags",
  "firebase-messaging": "Firebase Messaging",
  "nothing-to-see-here": "Nothing to see here",
  "continue-with": "Continue with:",
  "rate-limit-exceeded-please-try-again-in-a-few-minutes": "Rate limit exceeded. Please try again in a few minutes.",
  "unauthorized": "Unauthorized",
  "connected-accounts": "Connected Accounts",
  "connected-accounts-num-0": "No connections",
  "connected-accounts-num-1": "1 connection",
  "connected-accounts-num-x": "{0} connections",
  "add-connection": "Add connection",
  "remove-connection": "Remove Connection",
  "are-you-sure-you-want-to-remove-this-connection": "Are you sure you want to remove this connection?",
  "or": "or",
  "login-successful": "Login successful",
  "you-can-close-this-window-now": "You can close this window now",
  "contact-us": "Contact us",
  "login-failed": "Login failed",
  "invalid-email-or-password": "Invalid email or password",
  "you-cant-leave-if-youre-the-owner": "You can't leave if you're the owner",
  "edit-billing-contact": "Edit billing contact",
  "country": "Country",
  "country-is-required": "Country is required",
  "incomplete": "Incomplete",
  "please-complete-your-billing-information": "Please complete your billing information.",
  "reactivate-subscription": "reactivate subscription",
  "select-profile": "Select space",
  "please-wait": "please wait",
  "credit": "Credit",
  "send-invite": "Send invite",
  "reset-to-default": "Reset to default",
  "contrast-between-primary-and-background-is-low": "Contrast between primary and background is low!",
  "failed-to-load-image": "Failed to load image",
  "unsaved-changes": "Unsaved Changes",
  "you-have-unsaved-changes-are-you-sure-you-want-to-leave": "You have unsaved changes. Are you sure you want to leave?",
  "stay": "Stay",
  "development-build": "Development Build",
  "dev-warning-part-1": "This is a development build. Expect frequent updates and fatal bugs. Go to ",
  "dev-warning-part-2": " for the stable version.",
  "go-to-stable": "Go to Stable",
  "dont-show-again": "don't show again",
  "reset-to-default-values": "Reset to default values?",
  "are-you-sure-you-want-to-reset-all-values-to-their-defaults": "Are you sure you want to reset all values to their defaults?",
  "save-as-draft": "Save draft",
  "caption": "Caption",
  "tags": "Tags",
  "enter-a-title": "Enter a title",
  "enter-a-caption": "Enter a caption",
  "choose-tags": "Choose tags",
  "owner-only": "Owner only",
  "hub": "Hub",
  "untitled": "Untitled",
  "copy": "copy",
  "delete-draft": "Delete draft?",
  "are-you-sure-you-want-to-delete-this-draft": "Are you sure you want to delete this draft?",
  "draft-deleted": "Draft deleted",
  "you-dont-have-access-to-this-template": "You don't have access to this template",
  "deleted-at": "deleted {0} {1}",
  "no-render-available": "no render available",
  "trash": "Trash",
  "restore": "Restore",
  "show-all": "Show all",
  "members-sub-spaces": "Members / Sub-Spaces",
  "members": "Members",
  "incl-sub-spaces": "incl. Sub-Spaces",
  "only billed for X unique": "You are only billed for <b style=\"color: var(--primary)\">{0}</b> unique members",
  "invite": "Invite",
  "sub-spaces": "Sub-Spaces",
  "create-sub-space": "Create Sub-Space",
  "this-draft-has-been-modified-since-you-started-editing": "This draft has been modified since you started editing",
  "copy-suffix": "(copy)",
  "save-as-new": "Save as copy",
  "overwrite": "Overwrite",
  "you-have-unsaved-changes": "You have unsaved changes",
  "are-you-sure-you-want-to-leave-this-page-any-unsaved-changes-will-be-lost": "Are you sure you want to leave this page? Any unsaved changes will be lost.",
  "discard-changes": "Discard changes",
  "sharing-is-not-supported-on-this-platform": "Sharing is not supported on this platform",
  "move-copy-draft": "Move / Copy Draft",
  "keep-original": "keep original",
  "move": "move",
  "target-spaces": "Target Spaces:",
  "need-templates-check-out": "Need templates? Check out:",
  "teams": "Spaces",
  "all-length-0-hidden-length-0-visible-templates": "{0} visible templates",
  "hidden-length-0-hidden-templates": "{0} hidden templates",
  "team-memberids-length-0-team-adminids-length-0-members": "{0} Members",
  "templatestore-templates-size-templates": "{0} Templates",
  "draft-keeporiginal-value-copied-moved-successfully-to-targetspaces-size-spaces": "Draft {0} successfully to {1} spaces",
  "select-target-spaces": "select target spaces",
  "account": "Account",
  "logout": "Logout",
  "draft-saved": "Draft saved",
  "draft-restored": "Draft restored",
  "move-copy": "Move/Copy",
  "copied": "copied",
  "moved": "moved",
  "dont-have-access-to-this-template": "don't have access to this template",
  "are-you-sure-you-want-to-leave": "Are you sure you want to leave?",
  "you-will-lose-access-to-this-space": "You will lose access to this space.",
  "theres-nothing-here": "There's nothing here",
  "remove-member": "Remove member",
  "are-you-sure-you-want-to-remove-this-user-from-the-space": "Are you sure you want to remove this user from the space?",
  "are-you-sure-you-want-to-leave-this-space": "Are you sure you want to leave this space?",
  "you-can-only-upload-files-up-to-16mb": "you can only upload files up to 20mb",
  "cropping": "Cropping",
  "compressing": "Compressing",
  "saving": "Saving",
  "something-went-wrong": "something went wrong...",
  "too-many-redirects-checking-services": "Too many redirects, checking services...",
  "up": "up",
  "down": "down",
  "retry-2": "retry",
  "update-banner-msg-p1": "We've reworked our template system and replaced workspaces with a drafts feature.",
  "update-banner-bottom-1": "If you're missing anything, please let us know at",
  "update-banner-bottom-2": "or give us a call at",
  "today": "today",
  "manage-plan": "Manage subscription",
  "shared-seats": "Shared seats",
  "you-dont-have-enough-slots-for-all-members-please-upgrade-your-plan": "You don't have enough slots for all members. Please upgrade your plan!",
  "previous": "Previous",
  "next": "Next",
  "plan": "Plan",
  "add-ons": "Add-ons",
  "summary": "Summary",
  "space-theme": "Space theme",
  "unlimited-templates": "Unlimited templates",
  "available-plans": "Available plans:",
  "add-plan": "Add plan",
  "no-sub-needed": "No sub needed",
  "starting-at": "starting at",
  "options": "Options",
  "need-more-slots-contact-us-at": "Need more slots? contact us at",
  "need-more-shared-seats-contact-us-at": "Need more shared seats? contact us at",
  "proceed-to-checkout": "Proceed to checkout",
  "change-subscription": "Change Subscription",
  "sub-spaces-need-their-own-subscriptions": "Sub-Spaces need their own subscriptions",
  "this-space-is-included-in-subscriptionteam-name-s-subscription": "This space is included in {0}'s subscription",
  "want-to-save-and-edit-drafts-upgrade-your-plan-and-check-drafts-at-add-ons": "Want to save and edit drafts? Upgrade your plan and check \"Drafts\" at Add-Ons",
  "premium-feature": "Premium feature",
  "want-to-create-sub-spaces-upgrade-your-subscription-to-business": "Want to create sub-spaces? Upgrade your subscription to \"Business+\"",
  "want-to-let-people-use-your-templates-without-an-account-subscribe-to-social-shared": "Want to let people use your templates without an account? Subscribe to Social Shared",
  "social-shared": "Social Shared",
  "login-link": "Login link",
  "generate-new-link": "Generate new link",
  "logging-in": "Logging in",
  "all-available-shared-seats-are-filled-try-again-later": "All available shared seats are filled. Try again later",
  "create-dummy-session": "Create dummy session",
  "unknown": "Unknown",
  "youve-been-signed-out-due-to-inactivity": "You've been signed out due to inactivity",
  "session-timeout": "Session Timeout",
  "you-will-be-signed-out-in-timeout-value-getminutes-timeout-value-getseconds": "You will be signed out in {0}:{1}",
  "are-you-sure-you-want-to-generate-a-new-link-the-old-link-will-no-longer-work-and-all-sessions-will-be-signed-out": "Are you sure you want to generate a new link? The old link will no longer work and all sessions will be signed out.",
  "you-have-successfully-signed-out": "You have successfully signed out",
  "this-link-is-invalid-please-contact-your-space-admin": "This link is invalid. Please contact your space admin",
  "SPACE_EVENTS": "something happens in my spaces (members added/removed, owner changed, etc.)",
  "TEMPLATES": "my space templates are changed",
  "INVITES": "I get invited to join a space",
  "ACCOUNT_EVENTS": "my account is changed (password, email, etc.)",
  "settings-saved": "Settings saved!",
  "notify-me-when": "Notify me when",
  "via": "via",
  "app": "App",
  "email-0": "Email",
  "push": "Push",
  "SPACE_INVITE": "<b>{0}</b> has invited you to join their space <b>{1}</b>",
  "SPACE_MEMBER_JOINED": "<b>{0}</b> has joined your space <b>{1}</b>",
  "SPACE_MEMBER_REMOVED": "<b>{0}</b> has been removed from your space <b>{1}</b>",
  "SPACE_LOCKED": "Your space <b>{0}</b> has been locked.",
  "SPACE_UNLOCKED": "Your space <b>{0}</b> has been unlocked",
  "SPACE_PROMOTED": "<b>{0}</b> has promoted you to space admin of <b>{1}</b>",
  "SPACE_DEMOTED": "You are no longer a space admin of <b>{0}</b>. You have been demoted by <b>{1}</b>",
  "SPACE_OWNER_CHANGED": "The owner of your space <b>{0}</b> has changed to <b>{1}</b>",
  "SPACE_OWNER_TRANSFER_REQUESTED": "<b>{0}</b> wants to transfer the space <b>{1}</b> to you",
  "SPACE_DELETED": "Your space <b>{0}</b> has been deleted by <b>{1}</b>",
  "SPACE_TEMPLATE_ADDED": "A new template <b>{0}</b> has been added to your space <b>{1}</b>",
  "USER_EMAIL_CONFIRMED": "Your email address <b>{0}</b> has been confirmed",
  "USER_LOCKED": "Your account has been locked by <b>{0}</b>. Reason: <b>{1}</b>",
  "USER_UNLOCKED": "Your account has been unlocked by <b>{0}</b>",
  "UPDATE_EMAIL": "Your email address has been changed to <b>{0}</b>",
  "CHANGE_PASSWORD": "Your password has been changed",
  "last-7-days": "Last 7 days",
  "last-30-days": "Last 30 Days",
  "new": "new",
  "broadcast-to-space": "Broadcast to space",
  "enter-your-message-here": "Enter your message here",
  "send": "Send",
  "broadcast-message": "Broadcast message",
  "override": "Override",
  "studio": "Bluepic Studio",
  "id": "Bluepic ID Portal",
  "you-are-not-allowed-to-access-the-staging-environment": "You are not allowed to access the staging environment.",
  "this-space-cant-have-its-own-subscription": "This space can't have its own subscription",
  "please-enter-a-space-name": "Please enter a space name",
  "add-tag": "Add Tag",
  "filter-by-tags": "filter by tags",
  "edit-tags": "Edit tags",
  "tags-must-have-unique-names": "Tags must have unique names",
  "new-tag": "new tag",
  "and": "AND",
  "or-0": "OR",
  "cancels-automatically-on": "cancels automatically on",
  "duplicate": "Duplicate",
  "locked": "locked",
  "your-current-space-is-locked-please-contact-support": "Your current space is locked. Please contact support.",
  "stats": "Stats",
  "request-ownership-transfer": "Request ownership transfer",
  "ownership-transfer-pending": "Ownership transfer pending",
  "user-promoted-to-admin": "User promoted to admin",
  "user-demoted-to-member": "User demoted to member",
  "ownership-transfer-requested": "Ownership transfer requested",
  "cancel-ownership-transfer": "Cancel ownership transfer",
  "ownership-transfer-cancelled": "Ownership transfer cancelled",
  "has-requested-to-transfer-ownership-of-this-space-to-you": "has requested to transfer ownership of this space to you.",
  "comment-sent": "Comment sent",
  "comment-deleted": "Comment deleted",
  "batch": "Batch",
  "default-prices-are-available-to-all-users": "Default prices are available to all users",
  "please-use-a-bigger-screen-for-the-batch-editor": "Please use a bigger screen for the batch editor.",
  "sharing-is-not-supported-in-batch-mode": "sharing is not supported in batch mode",
  "this-feature-is-not-available-yet-were-working-on-it": "This feature is not available yet. We're working on it!",
  "batch-upload": "upload multiple",
  "drop-csv-file-to-import-data": "Drop .csv file to import data",
  "import-data": "Import data",
  "create-x-rows": "Create X Rows",
  "how-many-rows-should-be-created": "How many rows should be created?",
  "select-a-file-or-import-from-clipboard": "Select a file or import from clipboard",
  "select-file": "Select File",
  "import-from-clipboard": "import from clipboard",
  "data-includes-headers": "Data includes headers",
  "select-field": "Select field",
  "match-data-to-fields": "Match data to fields",
  "create-rows": "Create rows",
  "single": "Single",
  "ownership-transfer-accepted": "Ownership transfer accepted",
  "dont-see-anything-you-like": "Don't see anthing you like?",
  "were-happy-to-help": "We're happy to help",
  "need-templates-ask-your-parent-space-to-share-some-or-check-out": "Need templates? Ask your parent-space to share some, or check out:",
  "want-to-split-your-organisation-into-sub-spaces-upgrade-your-subscription-to-business": "Want to split your organisation into sub-spaces? Upgrade your subscription to \"Business+",
  "manage-templates": "Manage templates",
  "want-to-theme-bluepic-social-to-fit-in-with-your-corporate-identity-uprade-your-subscription-to-business": "Want to theme Bluepic Social to fit in with your corporate identity? Uprade your Subscription to \"Business\"",
  "are-you-sure": "Are you sure?",
  "delete-row": "Delete row",
  "pattern": "Pattern",
  "pattern-generator": "Pattern Generator",
  "from": "from",
  "to": "to",
  "stops": "stops",
  "repeat": "repeat",
  "generate": "generate",
  "export": "export",
  "edit-multiple-versions-of-this-template-at-once": "Edit multiple versions at once",
  "batchUploadTooltip": "Upload multiple images. A new row will be created for each",
  "patternTooltip": "Generate a color pattern. A new row will be created for each",
  "select-all": "Select all",
  "selected": "selected",
  "download-selected": "Download",
  "delete-selected": "Delete",
  "delete-rows": "Delete rows",
  "steps": "Steps",
  "new-lines": "new lines",
  "delete-disabled": "You have to leave at least one row",
  "included": "included",
  "member-plural": "members",
  "member-singular": "member",
  "discount-x-off": "(you save {0}%)",
  "auto-repeat": "auto",
  "preis": "Price:",
  "unverified": "unverified",
  "want-to-share-templates-with-your-sub-space": "Want to share templates with your sub-space?",
  "used-slots": "used Slots",
  "agencies": "Agencies",
  "admin_agency": "Agencies",
  "agency": "Agency",
  "target": "Target",
  "customer": "Customer",
  "commission-total": "Commission total",
  "transactions": "Transactions",
  "commission": "Gross Commission",
  "invoice-amount": "Invoice amount",
  "refunded": "Refunded",
  "select-a-space": "Select a space",
  "to-get-started": "to get started",
  "your-agency": "Your Agency",
  "request-payout": "Request payout",
  "the-minimum-payout-amount-is": "The minimum payout amount is",
  "rejected": "Rejected",
  "pending": "pending",
  "requested": "Requested",
  "payout-instructions": "Payout instructions",
  "reject": "Reject",
  "connections": "Connections",
  "balance": "Balance",
  "paid-out": "Paid out",
  "payouts": "Payouts",
  "you-already-have-a-pending-payout": "You already have a pending payout",
  "expired": "expired",
  "click-on-one-or-more-templates-to-change-their-visibility-and-to-share-them-with-sub-space": "Click on one or more templates to change their visibility and to share them with sub-space",
  "controls-visibility-in-this-space-sub-spaces-can-manage-visibility-independently": "Controls visibility in the gallery of this space. Sub-spaces can manage visibility independently.",
  "visibility": "Visibility",
  "share-templates-with-your-sub-spaces-hover-a-template-to-see-all-subspaces-that-have-access-to-it": "Share templates with your sub-spaces. Hover a template to see all subspaces that have access to it.",
  "add": "Add",
  "supports": "supports",
  "markdown": "markdown",
  "closable": "closable",
  "remember-closed": "remember closed",
  "banner": "Banner",
  "preview-banner": "Preview banner",
  "collect-emails": "Collect emails",
  "download-csv": "download .csv",
  "num-emails-collected": "{0} emails collected",
  "unlimited": "Unlimited",
  "APPSUMO_SOCIAL": "AppSumo SOCIAL Credits",
  "APPSUMO_EMBED": "AppSumo EMBED Credits",
  "APPSUMO_API": "AppSumo API Credits",
  "DAILY": "Daily",
  "WEEKLY": "Weekly",
  "MONTHLY": "Monthly",
  "YEARLY": "Yearly",
  "no-credits": "You've used up all your credits"
}
