<script setup lang="ts">
import { NButton, NBadge, NIcon } from 'naive-ui';
import { NotificationsOutline } from '@vicons/ionicons5';
interface Props {
  icon?: boolean;
  show?: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(['update:show']);

const notificationStore = useNotificationStore();
const newNotifications = computed(() => notificationStore.notifications.filter((n) => !n.read));

const route = useRoute();
const router = useRouter();
const showOverlay = ref(false);
let correctionCycle = false;
watch(
  () => props.show,
  (nv) => {
    correctionCycle = true;
    showOverlay.value = nv;
  }
);
watch(showOverlay, (value) => {
  // if (value) {
  //   router.push({ query: { notifications: null } });
  // } else {
  //   router.push({ query: { notifications: undefined } });
  // }
  if (correctionCycle) {
    correctionCycle = false;
    return;
  }
  emit('update:show', value);
});
// const stopWatchingRoute = watch(
//   route,
//   (nv) => {
//     if (nv.query.notifications !== undefined) {
//       showOverlay.value = true;
//     } else {
//       showOverlay.value = false;
//     }
//     nextTick(() => {
//       stopWatchingRoute();
//     });
//   },
//   { immediate: true }
// );
</script>

<template>
  <div>
    <n-badge :value="newNotifications.length" :max="10">
      <n-button v-if="!icon" circle quaternary @click="showOverlay = true" size="large">
        <template #icon>
          <n-icon size="24">
            <NotificationsOutline />
          </n-icon>
        </template>
      </n-button>
      <n-icon color="var(--text-hover)" v-else size="1.5rem">
        <NotificationsOutline />
      </n-icon>
    </n-badge>
    <notification-adaptive-overlay v-model:show="showOverlay" />
  </div>
</template>

<style scoped lang="scss"></style>
