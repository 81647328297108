import { onLogout } from '../../util/logout';

export type BaseActions = {
  _hydrate?: () => void;
};

export const pinia = createPinia();
const initializedStores = new Set<string>();
watch(
  pinia.state,
  (nv, ov) => {
    hydrate();
  },
  { deep: true, immediate: true }
);

export function hydrate() {
  const state = pinia.state.value;
  const _p = getActivePinia();
  if (!_p) return;
  for (const id in state) {
    if (initializedStores.has(id)) continue;
    //@ts-ignore - this is fine 🐕🔥
    const store = _p._s?.get(id);
    if (!store) continue;
    // console.log(`hydrating ${id} store`);
    store._hydrate?.();
    initializedStores.add(id);
  }
}

onLogout(() => {
  initializedStores.clear();
});
