const featureFlagKeys = ['billing', 'onboarding', 'oidc'] as const;

export type FeatureFlags = {
  [key in (typeof featureFlagKeys)[number]]: boolean;
};

export function useFeatureFlags() {
  //const { config } = useConfig();
  const overrides = useLocalStorage('featureFlagOverrides', {} as Partial<FeatureFlags>);
  return {
    featureFlags: computed(() => {
      const flags = {} as FeatureFlags;
      const parsed = Object.fromEntries(import.meta.env.V_SOCIAL_FEATURE_FLAGS.split(';').map((flag) => flag.split(':')));
      for (const key of featureFlagKeys) {
        flags[key] = overrides.value[key] ?? parsed[key] === 'true';
      }
      return flags;
    }),
    setOverride: (key: keyof FeatureFlags, value: boolean) => {
      overrides.value[key] = value;
    },
    resetOverrides: () => {
      for (const key of featureFlagKeys) {
        delete overrides.value[key];
      }
    },
  };
}
