import { OnboardingFlow } from "../types";

export const notificationsFlow: OnboardingFlow = {
  key: "notifications",
  name: "Stay up to date!",
  startRoute: "/settings",
  startMessage: `
# Stay up to date!
<br/>
Let's quickly show you where you can find notifications!
  `,
  endMessage: `
  # Good Job!
  <br/>
  Make sure to check out the **Notification** settings to make sure we don't spam your inbox!
  `,
  steps: [
    {
      elements: ["notifications-button"],
      message: `
# Click on the bell icon
      `,
      preventClicks: false,
      nextCondition: {
        type: "click",
        target: "notifications-button",
      },
    },
  ],
};
