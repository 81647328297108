<script setup lang="ts">
import { CloseOutline } from '@vicons/ionicons5';
import { oneWaySync } from '../../../util/reactivity';
interface Props {
  size?: string;
}
const props = defineProps<Props>();
const { size } = toRefs(props);
const elRef = ref(null);
watch(elRef, (el) => {
  if (el) {
    oneWaySync(
      useCssVar('--size', elRef),
      computed(() => size?.value || '1.5rem')
    );
  }
});
</script>

<template>
  <b-btn-blind class="b-btn-nav" :size="size" ref="elRef">
    <CloseOutline class="icon" />
  </b-btn-blind>
</template>

<style lang="scss" scoped>
.b-btn-nav {
  .icon {
    width: var(--size);
    height: var(--size);
    fill: var(--text);
  }
}
</style>
