import axios from 'axios';
import { LoginInput } from '@bluepic/types/src/Auth/login.input';
import { OperationOutput } from '@bluepic/types/src/Auth/operation.output';
import { UserUpdate } from '@bluepic/types/src/Auth/user.update';
import { InitTOTPOutput } from '@bluepic/types/src/Auth/initTOTP.output';
import { MagicLinkInput } from '@bluepic/types/src/Auth/magicLink.input';
import { UserOutput } from '@bluepic/types/src/Auth/user.output';
import { BaseController } from './baseController';
import { SessionOutput } from '@bluepic/types/src/Auth/session.output';
import { ApiKeyInput } from '@bluepic/types/src/Auth/apiKey.input';
import { ApiKeyOutput } from '@bluepic/types/src/Auth/apiKey.output';
import { AffiliateProfileInput } from '@bluepic/types/src/Auth/affiliateProfile.input';
import { AffiliateProfileOutput } from '@bluepic/types/src/Auth/affiliateProfile.output';
import { AffiliateKeyOutput } from '@bluepic/types/src/Auth/affiliateKey.output';
import { TeamInput } from '@bluepic/types/src/Auth/team.input';
import { TeamOutput } from '@bluepic/types/src/Auth/team.output';
import { TeamUpdate } from '@bluepic/types/src/Auth/team.update';
import { InviteInput } from '@bluepic/types/src/Auth/invite.input';
import { InviteOutput } from '@bluepic/types/src/Auth/invite.output';
import { MetaDataOutput } from '@bluepic/types/src/Auth/metaData.output';
import { MetaDataInput } from '@bluepic/types/src/Auth/metaData.input';
import { CustomPriceOutput } from '@bluepic/types/src/Auth/customPrice.output';
import { CustomPriceInput } from '@bluepic/types/src/Auth/customPrice.input';
import { GetAllUsersInputDto } from '@bluepic/types/src/Auth/getAllUsersInput.dto';
import { GetAllTeamsInputDto } from '@bluepic/types/src/Auth/getAllTeamsInput.dto';
import { PaginatedResponseDto } from '@bluepic/types/src/Auth/paginatedResponse.dto';
import { TeamSubscriptionOutput } from '@bluepic/types/src/Auth/teamSubscription.output';
import { TeamSubscriptionInput } from '@bluepic/types/src/Auth/teamSubscription.input';
import Stripe from 'stripe';
import { UserInput } from '@bluepic/types/src/Auth/user.input';
import { AgencyConnectionInput } from '@bluepic/types/src/Auth/agencyConnection.input';
import { AgencyConnectionOutput } from '@bluepic/types/src/Auth/agencyConnection.output';
import { AgencyTransactionOutput } from '@bluepic/types/src/Auth/agencyTransaction.output';
import { AgencyPayoutOutput } from '@bluepic/types/src/Auth/agencyPayout.output';
import { AgencyTransactionInput } from '@bluepic/types/src/Auth/agencyTransaction.input';

const baseUrl = import.meta.env.V_AUTH_BASE_URL;

const c = new BaseController(baseUrl);

export async function login(loginInput: LoginInput) {
  const url = c.getUrl('auth', 'login');
  return (await c.handleAxiosError(() => axios.post(url, loginInput))) as string;
}

export async function refreshCredentials(jwt?: string, apikey?: string) {
  const url = c.getUrl('auth', 'refresh');
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string;
}

export async function getMagicLink(data: MagicLinkInput) {
  const url = c.getUrl('auth', 'magiclink');
  return (await c.handleAxiosError(() => axios.post(url, data))) as string;
}

export async function redeemMagicLink(token: string) {
  const url = c.getUrl('auth', 'magiclink', 'redeem', token);
  return (await c.handleAxiosError(() => axios.get(url))) as string;
}

export async function createUser(registerInput: UserInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'create');
  return (await c.handleAxiosError(() =>
    axios.post(url, registerInput, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string | UserOutput;
}

export async function createDummyUser(registerInput: UserInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'create', 'dummy');
  return (await c.handleAxiosError(() =>
    axios.post(url, registerInput, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string | UserOutput;
}

export async function confirmEmail(token: string) {
  const url = c.getUrl('emailconfirmation', token);
  await c.handleAxiosError(() => axios.get(url));
}

export async function resendEmails(userId: string) {
  const url = c.getUrl('user', 'resendemails', userId);
  await c.handleAxiosError(() => axios.get(url));
}

export async function getUser(jwt?: string, apikey?: string) {
  const url = c.getUrl('user');
  return (
    await c.handleAxiosError(() =>
      axios.get(url, {
        headers: c.authHeader(jwt, apikey),
      })
    )
  )[0] as UserOutput;
}

export async function getUsers(userIds: string[], jwt?: string, apikey?: string) {
  const url = c.getUrl('user?' + userIds.map((id) => `userid=${id}`).join('&'));
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as UserOutput[];
}

// POST /user/all
export async function getAllUsers(options: GetAllUsersInputDto, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'all');
  return (await c.handleAxiosError(() =>
    axios.post(url, options, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as PaginatedResponseDto<UserOutput>;
}

// POST /team/all
export async function getAllTeams(options: GetAllTeamsInputDto & { agency?: boolean }, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'all');
  return (await c.handleAxiosError(() =>
    axios.post(url, options, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as PaginatedResponseDto<TeamOutput>;
}

export async function updateUser(
  userId: string,
  data: Pick<UserUpdate, 'name' | 'email' | 'password' | 'teamId' | 'hiddenTemplates' | 'onboardingProgress' | 'locale'>,
  jwt?: string,
  apikey?: string
) {
  const url = c.getUrl('user', 'update', userId);
  return (await c.handleAxiosError(() =>
    axios.patch(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

export async function deleteUser(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', userId);
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

export async function initmfa(jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'initmfa', 'totp');
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as InitTOTPOutput;
}

export async function confirmtotp(code: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'verifytotp', code);
  return (await c.handleAxiosError(() =>
    axios.post(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

export async function verifytotp(userid: string, code: string, token: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('auth', 'totp', userid, token, code);
  return (await c.handleAxiosError(() =>
    axios.post(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string;
}

export async function getSessions(userid?: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('session', userid ?? '');
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as SessionOutput[];
}

export async function killSession(sessionId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('session', sessionId);
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

export async function getApiKeys(userId?: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('apikey', userId ?? '');
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as ApiKeyOutput[];
}

export async function createApiKey(data: ApiKeyInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('apikey', 'create');
  return (await c.handleAxiosError(() =>
    axios.post(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

export async function removeApiKey(apikeyId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('apikey', apikeyId);
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// PATCH /user/lock/:userId
export async function lockUser(userId: string, reason: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'lock', userId);
  return (await c.handleAxiosError(() =>
    axios.patch(
      url,
      {
        reason,
      },
      {
        headers: c.authHeader(jwt, apikey),
      }
    )
  )) as OperationOutput;
}

// PATCH /user/unlock/:userId
export async function unlockUser(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'unlock', userId);
  return (await c.handleAxiosError(() =>
    axios.patch(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// PUT /affiliate/profile
export async function createOrUpdateAffiliateProfile(data: AffiliateProfileInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('affiliate', 'profile');
  return (await c.handleAxiosError(() =>
    axios.put(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET /affiliate/profile/{id}
export async function getAffiliateProfile(id?: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('affiliate', 'profile', id);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as AffiliateProfileOutput;
}

// GET /affiliate/keys/{profileid}
export async function getAffiliateKeys(profileId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('affiliate', 'keys', profileId);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as AffiliateKeyOutput[];
}

export interface AffiliateInvite {
  name?: string;
  organization?: string;
  email: string;
}

// POST /affiliate/invite/{profileid}
export async function inviteAffiliate(profileId: string, invites: AffiliateInvite[], jwt?: string, apikey?: string) {
  const url = c.getUrl('affiliate', 'invite', profileId);
  return (await c.handleAxiosError(() =>
    axios.post(url, invites, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// DELETE /affiliate/invite/{keyId}
export async function revokeAffiliateInvite(keyId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('affiliate', 'invite', keyId);
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET /user/byaffiliate
export async function getUsersByAffiliate(jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'byaffiliate');
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as UserOutput[];
}

// GET /affiliate/key/{key}
export async function getAffiliateKey(key: string) {
  const url = c.getUrl('affiliate', 'key', key);
  return (await c.handleAxiosError(() => axios.get(url))) as AffiliateKeyOutput;
}

// GET /affiliate/templates
export async function getAffiliateTemplates() {
  const url = c.getUrl('affiliate', 'templates');
  return (await c.handleAxiosError(() => axios.get(url))) as string[];
}

// GET /permission/current/{userid}
export async function getCurrentPermissions(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('permission', 'current', userId);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string[] | null;
}

// GET /permission/all/{userid}
export async function getAllPermissions(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('permission', 'all', userId);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string[] | null;
}

// GET /permission/team/{teamid}
export async function getTeamPermissions(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('permission', 'team', teamId);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string[] | null;
}

// POST /team/create
export async function createTeam(data: TeamInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'create');
  return (await c.handleAxiosError(() =>
    axios.post(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET /team/byuser/{id}
export async function getTeamsOfUser(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'byuser', userId);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as TeamOutput[];
}

// GET /team
interface GetTeamOptions {
  teamIds?: string[];
  recursive?: boolean;
  jwt?: string;
  apikey?: string;
  success?: (teams: TeamOutput[]) => void;
  error?: (err: any) => void;
  finally?: () => void;
}
export function getTeams(options: GetTeamOptions) {
  const query = {
    teamid: options.teamIds,
    recursive: options.recursive,
  };
  const url = c.getUrl('team' + (query.teamid || query.recursive ? '?' + c.query(query) : ''));
  const controller = new AbortController();
  c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(options.jwt, options.apikey),
      signal: controller.signal,
    })
  )
    .then((data: TeamOutput[]) => {
      options.success?.(data);
    })
    .catch((error: any) => {
      options.error?.(error);
    })
    .finally(() => {
      if (!controller.signal.aborted) {
        options.finally?.();
      }
    });
  return controller.abort.bind(controller);
}

// PATCH /team/update/{id}
export async function updateTeam(id: string, data: TeamUpdate, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'update', id);
  return (await c.handleAxiosError(() =>
    axios.patch(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// PATCH /team/promote/{id}/{memberid}
export async function promoteTeamMember(id: string, memberId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'promote', id, memberId);
  return (await c.handleAxiosError(() =>
    axios.patch(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// PATCH /team/demote/{id}/{memberid}
export async function demoteTeamMember(id: string, memberId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'demote', id, memberId);
  return (await c.handleAxiosError(() =>
    axios.patch(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// PATCH /team/removemember/{id}/{memberid}
export async function removeTeamMember(id: string, memberId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'removemember', id, memberId);
  return (await c.handleAxiosError(() =>
    axios.patch(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// DELETE /team/{id}
export async function deleteTeam(id: string, hard?: boolean, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', id) + (hard ? '?hard=true' : '');
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// POST /invite
export async function inviteUser(data: InviteInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite');
  return (await c.handleAxiosError(() =>
    axios.post(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET /invite/redeem/{id}
export async function redeemInvite(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite', 'redeem', id);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET /invite/byUser/{userId}
export async function getInvitesByUser(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite', 'byUser', userId);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as InviteOutput[];
}

// GET /invite/byTeam/{teamId}
export async function getInvitesByTeam(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite', 'byTeam', teamId);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as InviteOutput[];
}

// DELETE /invite/revoke/{id}
export async function revokeInvite(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite', 'revoke', id);
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// DELETE /invite/decline/{id}
export async function declineInvite(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite', 'decline', id);
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET /invite/:id
export async function getInvite(id: string) {
  const url = c.getUrl('invite', id);
  return (await c.handleAxiosError(() => axios.get(url))) as InviteOutput;
}

// GET /metadata/:key
export async function getMetadata(
  options: {
    key?: string;
    userId?: string;
    teamId?: string;
  },
  jwt?: string,
  apikey?: string
) {
  const { key, userId, teamId } = options;
  let url = c.getUrl('metadata');
  if (key) {
    url += `/${key}`;
  }
  if (userId || teamId) {
    url += '?' + c.query({ userId, teamId });
  }
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as MetaDataOutput | MetaDataOutput[];
}

// PUT /metadata/:key
export async function setMetadata(
  options: {
    key: string;
    userId?: string;
    teamId?: string;
    value?: string;
  },
  jwt?: string,
  apikey?: string
) {
  const { key, userId, teamId, value } = options;
  let url = c.getUrl('metadata', key);
  if (userId || teamId) {
    url += '?' + c.query({ userId, teamId });
  }
  return (await c.handleAxiosError(() =>
    axios.put(
      url,
      { value },
      {
        headers: c.authHeader(jwt, apikey),
      }
    )
  )) as OperationOutput;
}

export type OIDCProvider = 'google' | 'apple' | 'facebook' | 'twitter';

export interface OIDCIdToken {
  provider: OIDCProvider;
  userID: string;
  email: string;
  name?: string;
  picture?: string;
}

// GET /oidc/verify?id_token={id_token}
export async function verifyIDToken(id_token: string) {
  const url = c.getUrl('oidc', 'verify') + '?' + c.query({ id_token });
  return (await c.handleAxiosError(() => axios.get(url))) as OIDCIdToken;
}

// POST /user/addoidc
export async function addOIDC(id_token: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'addoidc');
  return (await c.handleAxiosError(() =>
    axios.post(
      url,
      { id_token },
      {
        headers: c.authHeader(jwt, apikey),
      }
    )
  )) as OperationOutput;
}

// DELETE /user/removeoidc?id={id}
export async function removeOIDC(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'removeoidc') + '?' + c.query({ id });
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// POST /auth/login/oidc?affiliateKey={affiliateKey}&inviteId={inviteId}
export async function loginOIDC(id_token: string, affiliateKey?: string, inviteId?: string) {
  let url = c.getUrl('auth', 'login', 'oidc');
  const query = { affiliateKey, inviteId };
  if (query) {
    url += '?' + c.query(query);
  }
  return (await c.handleAxiosError(() => axios.post(url, { id_token }))) as string;
}

// GET /user/pictureuploadurl
export async function getPictureUploadUrl(jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'pictureuploadurl');
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string;
}

// GET /user/checkpictureupload
export async function checkPictureUpload(jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'checkpictureupload');
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as boolean;
}

// GET /team/pictureuploadurl/:id
export async function getTeamPictureUploadUrl(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'pictureuploadurl', teamId);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string;
}

// GET /team/checkpictureupload/:id
export async function checkTeamPictureUpload(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'checkpictureupload', teamId);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as boolean;
}

// GET /billing/custom-price/all
export async function getAllCustomPrices(jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'custom-price', 'all');
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as CustomPriceOutput[];
}

// GET /billing/custom-price/:id
export async function getCustomPrice(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'custom-price', id);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as CustomPriceOutput;
}

// GET billing/custom-price/by-team/:teamId
export async function getCustomPricesByTeam(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'custom-price', 'by-team', teamId);
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as CustomPriceOutput[];
}

// PUT billing/custom-price/:id
export async function createOrUpdateCustomPrice(data: CustomPriceInput, id?: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'custom-price', id);
  return (await c.handleAxiosError(() =>
    axios.put(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// DELETE billing/custom-price/:id
export async function deleteCustomPrice(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'custom-price', id);
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET billing/team-subscription/by-team/:teamId
export async function getTeamSubscriptionByTeam(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'team-subscription', 'by-team', teamId);
  const res = (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as TeamSubscriptionOutput;
  res.slots = Number(res.slots);
  return res;
}

// GET billing/team-subscription/by-custom-price/{id}
export async function getTeamSubscriptionsByCustomPriceId(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'team-subscription', 'by-custom-price', id);
  return (
    (await c.handleAxiosError(() =>
      axios.get(url, {
        headers: c.authHeader(jwt, apikey),
      })
    )) as TeamSubscriptionOutput[]
  ).map((subscription) => ({
    ...subscription,
    slots: Number(subscription.slots),
  }));
}

// PUT billing/team-subscription?successUrl={successUrl}&cancelUrl={cancelUrl}
export async function createOrUpdateTeamSubscription(
  data: TeamSubscriptionInput,
  successUrl?: string,
  cancelUrl?: string,
  jwt?: string,
  apikey?: string
) {
  let url = c.getUrl('billing', 'team-subscription');
  const query = { successUrl, cancelUrl };
  if (query) {
    url += '?' + c.query(query);
  }
  return (await c.handleAxiosError(() =>
    axios.put(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string;
}

// GET billing/team-subscription/checkout-success/:checkoutId
export async function teamSubscriptionCheckoutSuccess(checkoutId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'team-subscription', 'checkout-success', checkoutId);
  const res = (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as TeamSubscriptionOutput;
  res.slots = Number(res.slots);
  return res;
}

// GET billing/team-subscription/cancellation-session/:id?returnUrl={returnUrl}
export async function getTeamSubscriptionCancellationSession(id: string, returnUrl?: string, jwt?: string, apikey?: string) {
  let url = c.getUrl('billing', 'team-subscription', 'cancellation-session', id);
  const query = { returnUrl };
  if (query) {
    url += '?' + c.query(query);
  }
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string;
}

// GET auth/shared/:id
export async function sharedLogin(id: string) {
  const url = c.getUrl('auth', 'shared', id);
  return (await c.handleAxiosError(() => axios.get(url))) as string;
}

// POST session/dummy/{userid}
export async function createDummySession(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('session', 'dummy', userId);
  return (await c.handleAxiosError(() =>
    axios.post(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET session/ping
export async function pingSession(jwt?: string, apikey?: string) {
  const url = c.getUrl('session', 'ping');
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as string;
}

// DELETE session/all/{userId}
export async function deleteAllSessions(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('session', 'all', userId);
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET session/stats/ua
export async function getUAStats(jwt?: string, apikey?: string) {
  const url = c.getUrl('session', 'stats', 'ua');
  return await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  );
}

// GET session/stats/ua
export async function getUsageStats(jwt?: string, apikey?: string) {
  const url = c.getUrl('session', 'stats', 'usage');
  return await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  );
}

// PATCH team/ownershiptransfer/:teamId/:targetId
export async function initTeamOwnershipTransfer(teamId: string, targetId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'ownershiptransfer', teamId, targetId);
  return (await c.handleAxiosError(() =>
    axios.patch(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// DELETE team/ownershiptransfer/:teamId
export async function cancelTeamOwnershipTransfer(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'ownershiptransfer', teamId);
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// PATCH team/acceptownership/:teamId
export async function acceptTeamOwnershipTransfer(teamId: string, jwt?: string, apikey?: string) {
  console.log('acceptTeamOwnershipTransfer');
  const url = c.getUrl('team', 'acceptownership', teamId);
  return (await c.handleAxiosError(() =>
    axios.patch(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET agency/connection?agencySpaceId={agencySpaceId}&targetSpaceId={targetSpaceId}&id={id1}&id={id2}
export async function getAgencyConnections(
  options: {
    agencySpaceId?: string;
    targetSpaceId?: string;
    ids?: string[];
  },
  jwt?: string,
  apikey?: string
) {
  const { agencySpaceId, targetSpaceId, ids } = options;
  const url = c.getUrl('agency', 'connection') + '?' + c.query({ agencySpaceId, targetSpaceId, id: ids });
  return (
    (await c.handleAxiosError(() =>
      axios.get(url, {
        headers: c.authHeader(jwt, apikey),
      })
    )) as AgencyConnectionOutput[]
  ).map((connection) => ({
    ...connection,
    createdAt: new Date(connection.createdAt),
  }));
}

// POST agency/connection
export async function createAgencyConnection(data: AgencyConnectionInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'connection');
  return (await c.handleAxiosError(() =>
    axios.post(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// DELETE agency/connection/{id}
export async function deleteAgencyConnection(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'connection', id);
  return (await c.handleAxiosError(() =>
    axios.delete(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET agency/transaction?agencySpaceId={agencySpaceId}&targetSpaceId={targetSpaceId}
export async function getAgencyTransactions(
  options: {
    agencySpaceId?: string;
    targetSpaceId?: string;
  },
  jwt?: string,
  apikey?: string
) {
  const { agencySpaceId, targetSpaceId } = options;
  const url = c.getUrl('agency', 'transaction') + '?' + c.query({ agencySpaceId, targetSpaceId });
  return (
    (await c.handleAxiosError(() =>
      axios.get(url, {
        headers: c.authHeader(jwt, apikey),
      })
    )) as AgencyTransactionOutput[]
  ).map((transaction) => ({
    ...transaction,
    createdAt: new Date(transaction.createdAt),
  }));
}

// POST agency/transaction
export async function createAgencyTransaction(data: AgencyTransactionInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'transaction');
  await c.handleAxiosError(() =>
    axios.post(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  );
}

// GET agency/payout?agencySpaceId={agencySpaceId}
export async function getAgencyPayouts(options: { agencySpaceId?: string }, jwt?: string, apikey?: string) {
  const { agencySpaceId } = options;
  const url = c.getUrl('agency', 'payout') + '?' + c.query({ agencySpaceId });
  return (
    (await c.handleAxiosError(() =>
      axios.get(url, {
        headers: c.authHeader(jwt, apikey),
      })
    )) as AgencyPayoutOutput[]
  ).map((payout) => ({
    ...payout,
    requested: new Date(payout.requested),
    rejected: payout.rejected ? new Date(payout.rejected) : undefined,
    paid: payout.paid ? new Date(payout.paid) : undefined,
  }));
}

// POST agency/payout/request?agencySpaceId={agencySpaceId}
export async function requestAgencyPayout(data: { agencySpaceId?: string; comment: string }, jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'payout', 'request');
  return (await c.handleAxiosError(() =>
    axios.post(url, data, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// GET agency/payout/pending
export async function getPendingAgencyPayouts(jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'payout', 'pending');
  return (
    (await c.handleAxiosError(() =>
      axios.get(url, {
        headers: c.authHeader(jwt, apikey),
      })
    )) as AgencyPayoutOutput[]
  ).map((payout) => ({
    ...payout,
    requested: new Date(payout.requested),
    rejected: payout.rejected ? new Date(payout.rejected) : undefined,
    paid: payout.paid ? new Date(payout.paid) : undefined,
  }));
}

// PATCH agency/payout/confirm/{id}?amount={amount}
export async function confirmAgencyPayout(
  options: {
    id: string;
    amount?: number;
  },
  jwt?: string,
  apikey?: string
) {
  const { id, amount } = options;
  const url = c.getUrl('agency', 'payout', 'confirm', id) + '?' + c.query({ amount });
  return (await c.handleAxiosError(() =>
    axios.patch(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

// PATCH agency/payout/reject/{id}
export async function rejectAgencyPayout(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'payout', 'reject', id);
  return (await c.handleAxiosError(() =>
    axios.patch(url, null, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as OperationOutput;
}

export type AppSumoLicense = {
  id: string;
  status: 'active' | 'inactive' | 'deactivated';
  userId: string;
  createdAt?: Date;
  lastUpdatedAt?: Date;
  claimedAt?: Date;
  tier: number;
};

// GET appsumo/license?license_id={license_id}
export async function getAppSumoLicense(license_id?: string, jwt?: string, apikey?: string) {
  let url = c.getUrl('appsumo', 'license');
  if (license_id) {
    url += '?license_id=' + license_id;
  }
  const license = (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as AppSumoLicense;
  if (license.createdAt) {
    license.createdAt = new Date(license.createdAt);
  }
  if (license.lastUpdatedAt) {
    license.lastUpdatedAt = new Date(license.lastUpdatedAt);
  }
  if (license.claimedAt) {
    license.claimedAt = new Date(license.claimedAt);
  }
  return license;
}

// GET appsumo/can-have-team
export async function getAppSumoCanHaveTeam(jwt?: string, apikey?: string) {
  const url = c.getUrl('appsumo', 'can-have-team');
  return (await c.handleAxiosError(() =>
    axios.get(url, {
      headers: c.authHeader(jwt, apikey),
    })
  )) as boolean;
}
