<script setup lang="ts">
import { InviteOutput } from '@bluepic/types/src/Auth/invite.output';
import { NCard, NButton, NIcon, NSpin } from 'naive-ui';
import CheckmarkIcon from '../../../../assets/icons/checkmark.svg';
import CloseIcon from '../../../../assets/icons/close.svg';
import { declineInvite, redeemInvite } from '../../../../controllers/authController';
import { oneWaySync } from '../../../../util/reactivity';
const message = useMessage();
const request = useSafeHTTP();
interface Props {
  invite: InviteOutput;
  onDecided?: () => void;
}
const props = defineProps<Props>();
const emit = defineEmits(['accepted', 'declined']);
const { invite } = toRefs(props);
const themeStore = useThemeStore();
const teamStore = useTeamStore();
teamStore.getTeam(invite.value.teamId);
const team = computed(() => {
  return teamStore.teams.find((t) => t.id == invite.value.teamId);
});
const authStore = useAuthStore();
authStore.getUser(invite.value.inviterId);
const inviter = computed(() => {
  if (!authStore.users.has(invite.value.inviterId)) {
    return null;
  }
  return authStore.users.get(invite.value.inviterId);
});
const accepting = ref(false);
function switchTeam(id?: string) {
  if (!authStore.user || !authStore.jwt) return;
  request(
    () =>
      updateUser(
        authStore.user!.id,
        {
          // @ts-ignore - wrong type
          teamId: id ?? null,
        },
        authStore.jwt!
      ),
    (res) => {}
  );
}
function accept() {
  if (!authStore.jwt) return;
  const pending = request(
    () => redeemInvite(invite.value.id, authStore.jwt!),
    (res) => {
      props.onDecided?.();
      emit('accepted');
      message.success(t('invite-accepted'));
      switchTeam(invite.value.teamId);
    }
  );
  oneWaySync(pending, accepting);
}
const declining = ref(false);
function decline() {
  if (!authStore.jwt) return;
  const pending = request(
    () => declineInvite(invite.value.id, authStore.jwt!),
    (res) => {
      props.onDecided?.();
      emit('declined');
      message.success(t('invite-declined'));
    }
  );
  oneWaySync(pending, declining);
}
const tx = t;
</script>

<template>
  <n-card class="incoming-invite-list-item">
    <div class="flex items-center justify-between gap-4">
      <profile-picture class="avatar" :teamid="invite.teamId" />
      <div class="flex-grow flex flex-col">
        <h2>{{ team?.name }}</h2>
        <p :style="{ color: 'var(--text-hover)' }">
          {{ tx('invited-by-inviter-name', [inviter?.name]) }}
        </p>
      </div>
      <div class="flex gap-2">
        <n-spin :show="accepting" :stroke="themeStore.theme().value.colors.success">
          <n-button circle ghost type="success" @click="accept">
            <n-icon>
              <CheckmarkIcon />
            </n-icon>
          </n-button>
        </n-spin>
        <n-spin :show="declining" :stroke="themeStore.theme().value.colors.error">
          <n-button circle ghost type="error" @click="decline">
            <n-icon>
              <CloseIcon />
            </n-icon>
          </n-button>
        </n-spin>
      </div>
    </div>
  </n-card>
</template>

<style lang="scss" scoped>
.incoming-invite-list-item {
  .avatar {
    @apply w-16 h-16;
  }
  .n-card__content {
    @apply p-0;
  }
}
</style>
