<script setup lang="ts">
import { NIcon } from 'naive-ui';
import { InformationCircleOutline, CheckmarkCircleOutline } from '@vicons/ionicons5';
import { editorFlow, settingsFlow } from '../../onboarding';
import { ProvidedOnboarding } from '../../composables/useOnboarding';
import { OnboardingFlow } from '../../onboarding/types';
import { notificationsFlow } from '../../onboarding/flows/notificationsFlow';

const flows = [settingsFlow, editorFlow, notificationsFlow];

const route = useRoute();

const visibleOnboardingElements = ref([] as string[]);
const root = ref<HTMLElement>();
const stopWatching = watch(
  root,
  (nv) => {
    if (!nv) return;
    new MutationObserver(() => {
      visibleOnboardingElements.value = Array.from(nv.querySelectorAll('[data-onboarding]')).map((el) => {
        return el.getAttribute('data-onboarding')!;
      });
    }).observe(nv, {
      childList: true,
      subtree: true,
    });
    nextTick(() => {
      stopWatching();
    });
  },
  { immediate: true }
);
const progressLoaded = ref(false);
const availableFlows = computed(() => {
  if (!progressLoaded.value) return [];
  return flows.filter((f) => {
    return (
      route.path === f.startRoute &&
      f.steps[progress.get(f.key) ?? 0]?.elements.some((el: string) => visibleOnboardingElements.value.includes(el))
    );
  });
});

const authStore = useAuthStore();
// 'flow-key:step-index;flow-key:step-index'
const storedProgress = ref('');
let loadCycle = false;
const stopWatchingUser = watch(
  authStore.$state,
  (nv) => {
    if (!nv.user) return;
    loadCycle = true;
    storedProgress.value = nv.user.onboardingProgress ?? '';
    progressLoaded.value = true;
    nextTick(() => {
      stopWatchingUser();
    });
  },
  { immediate: true }
);
const request = useSafeHTTP();
let writeCycle = false;
const writeProgress = debounce((serialized: string, cb?: () => void) => {
  if (!authStore.user?.id || !authStore.jwt) throw new Error('no auth');
  writeCycle = true;
  request(
    () => {
      return updateUser(
        authStore.user!.id,
        {
          onboardingProgress: serialized,
        },
        authStore.jwt!
      );
    },
    () => {
      if (import.meta.env.DEV) {
        console.log('onboarding progress saved');
      }
      cb?.();
      writeCycle = false;
    }
  );
}, 1000);
const progress = reactive(new Map<string, number>());
watch(
  storedProgress,
  (nv) => {
    if (writeCycle) {
      return;
    }
    nv?.split(';').forEach((flowProgress) => {
      const [flowKey, stepIndex] = flowProgress.split(':');
      if (!flowKey || !stepIndex) return;
      if (isNaN(stepIndex as any)) return;
      progress.set(flowKey, Number(stepIndex));
    });
  },
  { immediate: true }
);
watch(
  progress,
  (nv) => {
    if (loadCycle) {
      loadCycle = false;
      return;
    }
    const serialized = Array.from(nv.entries())
      ?.map(([flowKey, stepIndex]) => {
        return `${flowKey}:${stepIndex}`;
      })
      ?.join(';');
    writeProgress(serialized ?? '');
  },
  { deep: true }
);

const storedActiveStep = useLocalStorage('active-onboarding-step', null as string | null);
const activeStep = computed(() => {
  if (!storedActiveStep.value) return null;
  const [flowKey, stepIndex] = storedActiveStep.value.split(':');
  if (!flowKey || !stepIndex) return null;
  if (isNaN(stepIndex as any)) return null;
  const flow = flows.find((f) => f.key === flowKey);
  if (!flow) return null;
  if (flow.steps.length <= Number(stepIndex)) return null;
  return {
    flow,
    step: flow?.steps[Number(stepIndex)],
  };
});
function setActiveStep(key: string, stepIndex: number) {
  storedActiveStep.value = `${key}:${stepIndex}`;
  progress.set(key, stepIndex);
}

function exit() {
  storedActiveStep.value = null;
}

const highlighted = reactive<Map<string, HTMLElement>>(new Map());
const highlightedValues = computed(() => {
  return Array.from(highlighted.values());
});

watch(
  [activeStep, visibleOnboardingElements, route],
  ([newStep, els, newRoute]) => {
    // console.log({ newStep, els, newRoute });
    if (!newStep) {
      highlighted.clear();
      return;
    }
    const { flow, step } = newStep;
    const targets = step.elements
      .map((el: string) => {
        const target = root.value?.querySelector(`[data-onboarding="${el}"]`);
        return target;
      })
      .filter((el: any) => el) as HTMLElement[];
    highlighted.clear();
    targets.forEach((el) => {
      highlighted.set(el.getAttribute('data-onboarding')!, el);
    });
    if (step.nextCondition) {
      switch (step.nextCondition.type) {
        case 'route':
          if (route.path == step.nextCondition.target) {
            return next();
          }
          break;
        case 'click':
          const target = root.value?.querySelector(`[data-onboarding="${step.nextCondition.target}"]`);
          console.log({ target });
          if (!target) break;
          const clickListener = () => {
            console.log('click');
            target.removeEventListener('click', clickListener);
            next();
          };
          target.addEventListener('click', clickListener);
          break;
        case 'event':
          const eventListener = (e: Event) => {
            document.body.removeEventListener(step.nextCondition!.target, eventListener);
            next();
          };
          document.body.addEventListener(step.nextCondition!.target, eventListener);
          break;
      }
    }
  },
  { immediate: true }
);

const modal = reactive({
  show: false,
  success: false,
  content: '',
  confirmText: '',
  cancelText: '',
  confirm: () => {},
  cancel: () => {},
});

function startFlow(flow: OnboardingFlow) {
  if (!progress.get(flow.key) && flow.startMessage) {
    modal.content = flow.startMessage;
    modal.success = false;
    modal.confirmText = 'Start';
    modal.cancelText = 'Not now';
    modal.confirm = () => {
      modal.show = false;
      setActiveStep(flow.key, progress.get(flow.key) ?? 0);
    };
    modal.cancel = () => {
      modal.show = false;
    };
    modal.show = true;
    return;
  }
  setActiveStep(flow.key, progress.get(flow.key) ?? 0);
}
const { showFor, show, hide } = useConfetti();
function next() {
  if (!activeStep.value) return;
  const { flow, step } = activeStep.value;
  const nextStepIndex = flow.steps.indexOf(step) + 1;
  if (nextStepIndex >= flow.steps.length) {
    exit();
    if (flow.endMessage) {
      show();
      modal.content = flow.endMessage;
      modal.success = true;
      modal.confirmText = 'Got it';
      modal.cancelText = '';
      modal.confirm = () => {
        hide();
        modal.show = false;
        setActiveStep(flow.key, nextStepIndex);
      };
      modal.cancel = () => {
        hide();
        modal.show = false;
        setActiveStep(flow.key, nextStepIndex);
      };
      modal.show = true;
      return;
    } else {
      showFor(5000);
    }
    return;
  }
  setActiveStep(flow.key, nextStepIndex);
}

function prev() {
  if (!activeStep.value) return;
  const { flow, step } = activeStep.value;
  const nextStepIndex = flow.steps.indexOf(step) - 1;
  if (nextStepIndex < 0) {
    exit();
    return;
  }
  setActiveStep(flow.key, nextStepIndex);
}

const dialog = useDialog();
const showAbortDialog = ref(false);
function abort() {
  showAbortDialog.value = true;
}

provide<ProvidedOnboarding>('onboarding', {
  flows,
  highlighted,
  activeStep,
  progress,
  startFlow,
  setActiveStep,
  exit,
  next,
  prev,
});
function handleSelect(key?: string) {
  if (!key) {
    if (availableFlows.value.length === 1) {
      startFlow(availableFlows.value[0]);
    }
  } else {
    startFlow(availableFlows.value.find((f) => f.key === key)!);
  }
}
const { featureFlags } = useFeatureFlags();
</script>

<template>
  <div ref="root" class="absolute inset-0">
    <slot />
    <div class="onboarding-overlay" v-if="featureFlags.onboarding">
      <t-fade>
        <onboarding-starter
          v-if="!activeStep?.flow"
          v-for="flow in availableFlows"
          :key="flow.key"
          :flow="flow"
          @click="handleSelect(flow.key)"
        />
      </t-fade>
      <t-fade>
        <onboarding-highlighter
          v-if="highlightedValues.length"
          :targets="highlightedValues"
          :active-step="activeStep!"
          @mask-click="abort"
          @next="next"
        />
      </t-fade>
    </div>
    <onboarding-modal
      :show="modal.show"
      :content="modal.content"
      :confirm-text="modal.confirmText"
      :cancel-text="modal.cancelText"
      @confirm="modal.confirm"
      @cancel="modal.cancel"
    >
      <template #icon>
        <n-icon size="1.8rem" :color="modal.success ? 'var(--success)' : ''">
          <checkmark-circle-outline v-if="modal.success" />
          <information-circle-outline v-else />
        </n-icon>
      </template>
    </onboarding-modal>
    <onboarding-modal
      :show="showAbortDialog"
      :content="'Are you sure you want to abort the onboarding process? You can always restart it later.'"
      confirm-text="Abort"
      cancel-text="Continue"
      @confirm="() => exit()"
      @cancel="showAbortDialog = false"
    >
    </onboarding-modal>
  </div>
</template>

<style scoped lang="scss">
.onboarding-overlay {
  @apply fixed inset-0 z-[9999] pointer-events-none;
}
</style>
