import { randomString } from '.';
import { HTTPRequest } from './types';

export function initEventSourceListener(excludeUrl?: RegExp) {
  const listeners = new Map<string, (req: HTTPRequest) => void>();
  const messageListeners = new Map<string, (msg: MessageEvent) => void>();
  const original = EventSource;
  // @ts-ignore
  EventSource = function (this: EventSource, ...args: any[]) {
    const [url] = args;
    if (excludeUrl?.test(url)) {
      // @ts-ignore
      return new original(...args);
    }
    // @ts-ignore
    const es = new original(...args);
    const data: HTTPRequest = {
      location: window.location.href,
      initiator: 'eventsource',
      method: 'GET',
      url,
      startTime: Date.now(),
    };
    es.addEventListener('error', function (this: EventSource, ev: Event) {
      data.error = JSON.stringify(ev);
      listeners.forEach((listener) => {
        listener(data);
      });
    });
    es.addEventListener('open', function (this: EventSource) {
      data.endTime = Date.now();
      listeners.forEach((listener) => {
        listener(data);
      });
    });
    es.addEventListener('message', function (this: EventSource, ev: MessageEvent) {
      messageListeners.forEach((listener) => {
        listener(ev);
      });
    });
    return es;
  };
  return {
    listen: (listener: (req: HTTPRequest) => void) => {
      const id = randomString(8);
      listeners.set(id, listener);
      return () => {
        listeners.delete(id);
      };
    },
    listenToMessages: (listener: (msg: MessageEvent) => void) => {
      const id = randomString(8);
      messageListeners.set(id, listener);
      return () => {
        messageListeners.delete(id);
      };
    },
  };
}
