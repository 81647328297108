import axios from "axios";
import { BaseController } from "./baseController";
import { NotificationSettingsOutput } from "@bluepic/types/src/Auth/notificationSettings.output";
import { NotificationSettingsUpdate } from "@bluepic/types/src/Auth/notificationSettings.update";
import { PaginatedResponseDto } from "@bluepic/types/src/Auth/paginatedResponse.dto";
import { OperationOutput } from "@bluepic/types/src/Auth/operation.output";
import { NotificationOutput } from "@bluepic/types/src/Auth/notification.output";

const baseUrl = import.meta.env.V_NOTIFICATIONS_BASE_URL;

const c = new BaseController(baseUrl);

// GET /settings/{userId}
export async function getSettings(userId?: string, jwt?: string, apikey?: string) {
  return (await c.handleAxiosError(() =>
    axios.get(c.getUrl("settings", userId), {
      headers: {
        ...c.authHeader(jwt, apikey),
      },
    })
  )) as NotificationSettingsOutput;
}

// PATCH /settings/{userId}
export async function updateSettings(data: NotificationSettingsUpdate, userId?: string, jwt?: string, apikey?: string) {
  return (await c.handleAxiosError(() =>
    axios.patch(c.getUrl("settings", userId), data, {
      headers: {
        ...c.authHeader(jwt, apikey),
      },
    })
  )) as NotificationSettingsOutput;
}

// GET /notification/{userId}?skip={skip}&take={take}
export async function getNotifications(
  options: {
    userId?: string;
    skip?: number;
    take?: number;
  },
  jwt?: string,
  apikey?: string
) {
  const { userId, skip, take } = options;
  const res = (await c.handleAxiosError(() =>
    axios.get(c.getUrl("notification", userId), {
      params: {
        skip,
        take,
      },
      headers: {
        ...c.authHeader(jwt, apikey),
      },
    })
  )) as PaginatedResponseDto<NotificationOutput>;
  for (const n of res.results) {
    n.timestamp = new Date(n.timestamp);
    n.read = n.read ? new Date(n.read) : null;
  }
  return res;
}

// PATCH /notification/mark-as-read
export async function markAsRead(ids: string[], jwt?: string, apikey?: string) {
  return (await c.handleAxiosError(() =>
    axios.patch(c.getUrl("notification/mark-as-read"), ids, {
      headers: {
        ...c.authHeader(jwt, apikey),
      },
    })
  )) as OperationOutput;
}

// DELETE /notification?id={id}&id={id2}
export async function deleteNotifications(ids: string[], jwt?: string, apikey?: string) {
  const query = c.query({ id: ids });
  return (await c.handleAxiosError(() =>
    axios.delete(c.getUrl("notification?") + query, { headers: c.authHeader(jwt, apikey) })
  )) as OperationOutput;
}

// POST /notification/broadcast/{spaceId}
export async function broadcastNotification(spaceId: string, message: string, jwt?: string, apikey?: string) {
  return (await c.handleAxiosError(() =>
    axios.post(
      c.getUrl("notification/broadcast", spaceId),
      { message },
      {
        headers: {
          ...c.authHeader(jwt, apikey),
        },
      }
    )
  )) as OperationOutput;
}
