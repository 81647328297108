export type JimpMIME = "image/jpeg" | "image/png" | "image/bmp" | "image/tiff";

export function useJimpWorker() {
  const worker = new Worker("/workers/jimp-worker.js");
  return {
    convert(blob: Blob, mime: JimpMIME) {
      return new Promise<Blob>((resolve, reject) => {
        const msgListener = (e: MessageEvent) => {
          worker.removeEventListener("message", msgListener);
          worker.removeEventListener("error", errListener);
          const { err, src } = e.data;
          if (err) {
            console.error(err);
            reject(new Error(err));
            return;
          }
          if (!src) {
            reject(new Error("Failed to convert image"));
            return;
          }
          //convert datUrl to Blob
          const byteString = atob(src.split(",")[1]);
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], { type: mime });
          resolve(blob);
        };
        const errListener = (e: ErrorEvent) => {
          worker.removeEventListener("message", msgListener);
          worker.removeEventListener("error", errListener);
          console.error(e);
          reject(e);
        };
        worker.addEventListener("message", msgListener);
        worker.addEventListener("error", errListener);
        worker.postMessage({
          url: URL.createObjectURL(blob),
          mime,
        });
      });
    },
    crop(
      blob: Blob,
      x?: number,
      y?: number,
      w?: number,
      h?: number,
      mime?: JimpMIME,
      returnType: "blob" | "base64" | "both" = "blob"
    ) {
      return new Promise<Blob | string | { blob: Blob; b64: string }>((resolve, reject) => {
        const msgListener = (e: MessageEvent) => {
          worker.removeEventListener("message", msgListener);
          worker.removeEventListener("error", errListener);
          const { err, src } = e.data;
          if (err) {
            console.error(err);
          }
          if (!src) {
            reject(new Error("Failed to crop image"));
            return;
          }
          if (returnType === "base64") {
            resolve(src);
            return;
          }
          //convert datUrl to Blob
          const byteString = atob(src.split(",")[1]);
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const _blob = new Blob([ab], { type: blob.type });
          if (returnType === "both") {
            resolve({ blob: _blob, b64: src });
            return;
          }
          resolve(_blob);
        };
        const errListener = (e: ErrorEvent) => {
          worker.removeEventListener("message", msgListener);
          worker.removeEventListener("error", errListener);
          reject(e);
        };
        worker.addEventListener("message", msgListener);
        worker.addEventListener("error", errListener);
        const crop = {
          x: Number(x?.toFixed(0)),
          y: Number(y?.toFixed(0)),
          w: Number(w?.toFixed(0)),
          h: Number(h?.toFixed(0)),
        };
        worker.postMessage({
          url: URL.createObjectURL(blob),
          mime: mime ?? blob.type,
          ...crop,
        });
      });
    },
    terminate() {
      worker.terminate();
    },
  };
}
