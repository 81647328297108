import { Template } from '../types/bluepic';
import { File } from '@bluepic/types/src/StudioResources/file';
import { getTemplateLiveUrl } from '@bluepic/embed';

export interface GetTemplatesOptions {
  teamIds?: string[];
  start?: number;
  limit?: number;
  meta?: Record<string, string>;
  abortController?: AbortController;
}

export async function getTemplates(options: GetTemplatesOptions, jwt?: string, apikey?: string) {
  const files = await listFiles(
    {
      type: '^application/json\\+bx$',
      mode: 'read',
      teams: options.teamIds,
      start: options.start,
      limit: options.limit,
      meta: options.meta,
      abortController: options.abortController,
    },
    jwt,
    apikey
  );
  if (options.abortController?.signal.aborted) {
    return null;
  }
  return {
    total: files.total,
    templates: files.files.map(convertFileToTemplate) as Template[],
  };
}

export async function getTemplate(id: string, jwt?: string) {
  const file = await getFile(id, jwt);
  return convertFileToTemplate(file);
}

function convertFileToTemplate(f: File) {
  let lastModified: Date | undefined;
  if (!f.objects) return null;
  const { 'published:latest': latest } = f.objects as {
    [key: string]: { location: string };
  };
  if (latest?.location) {
    const [_, __, timestamp] = latest.location.split('_');
    lastModified = new Date(parseInt(timestamp));
  }
  const authStore = useAuthStore();
  if (!f.meta?.width || !f.meta?.height) throw new Error('Missing template dimensions');
  const dimensions = {
    width: f.meta?.width,
    height: f.meta?.height,
  };

  // Try to assume the bx core version from the stored s3 object (but sadly this is not provided on older template files)
  const bxCoreVersionOfPublishedLatest =
    f.objects['published:latest'].type === 's3' ? f.objects['published:latest'].bxCoreVersion ?? 'auto' : 'auto';

  const liveUrl = getTemplateLiveUrl(f._id, 'latest', bxCoreVersionOfPublishedLatest, {
    authorization: `Bearer ${authStore.jwt}`,
    studioResourcesBaseUrl: import.meta.env.V_STUDIO_RESOURCES_BASE_URL,
    cloudflarePagesHostname: import.meta.env.V_BLUEPIC_CORE_CLOUDFLARE_PAGES_LIVE_HOSTNAME,
    useBoilerplate: false,
    config: {
      fonts: [],
      resolution: 1,
      webgl: false,
    },
  });

  return {
    id: f._id,
    name: f.meta?.name,
    version: (f.objects?.['published:latest'] as { location: string }).location.split('_')[2].split('.')[0],
    lastModified,
    ...dimensions,
    platform: f.meta?.platform,
    format: normalizeAspectRatio(dimensions),
    target: f.meta?.target,
    tags: f.meta?.tags,
    category: f.meta?.category,
    read: f.teams?.read,
    write: f.teams?.write,
    authorId: f.userId,
    liveUrl,
    previewUrl:
      import.meta.env.V_STUDIO_RESOURCES_BASE_URL +
      `/api/files/${f._id}/preview:latest?ba_username=${f.auth?.username}&ba_password=${f.auth?.password}`,
    bxCoreVersionOfPublishedLatest,
  } as Template;
}

function normalizeAspectRatio(dimensions: { width: number; height: number }) {
  const { width, height } = dimensions;
  const gcd = (a: number, b: number): number => (b ? gcd(b, a % b) : a);
  const divisor = gcd(width, height);
  return `${width / divisor}:${height / divisor}`;
}
