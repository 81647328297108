{
  "email": "Email Addresse",
  "password": "Passwort",
  "username": "Benutzername",
  "consent": {
    "agree": "Durch das registrieren stimme ich den",
    "terms": "Nutzungsbedingungen",
    "and": "zu und nehme die",
    "privacy": "Datenschutzerklärung",
    "suffix": " zur Kenntnis"
  },
  "password-rating": {
    "weak": "schwach",
    "medium": "mittel",
    "strong": "stark",
    "tooWeak": "zu schwach"
  },
  "error": "Da ist etwas schief gelaufen...",
  "already-have-account": "Du hast schon einen Account?",
  "back-to-sign-in": "Zurück zur Anmeldung",
  "confirm-success": "Deine Email Adresse wurde bestätigt. Jetzt kannst du dich einloggen",
  "confirming-email": "Deine Email Adresse wird bestätigt...",
  "consent-required": "Zustimmung wird benötigt",
  "do-sign-in": "Anmelden",
  "do-sign-up": "Registrieren",
  "dont-have-an-account": "Noch kein Konto?",
  "email-invalid": "Email Adresse ist ungültig",
  "email-required": "Email Adresse wird benötigt",
  "email-resent": "Email nochmal verschickt",
  "forgot-password": "Passwort vergessen?",
  "forgot-text": "Gib deine Email Adresse ein und wir schicken dir einen Link um dein Passwort zurückzusetzen.",
  "invalid-confirm-token": "Ungültiger Bestätigungslink",
  "new-password": "neues Passwort",
  "password-invalid": "Passwort entspricht nicht den Vorgaben",
  "password-required": "Passwort wird benötigt",
  "password-strength": "Passwort Stärke",
  "passwords-dont-match": "Passwörter stimmen nicht überein",
  "redirecting-to-sign-in": "Du wirst zur Anmeldung weiter geleitet...",
  "register-success": "Jetzt musst du nur noch deine Email Adresse bestätigen. Schau in dein Postfach!",
  "remember-me": "Eingeloggt bleiben",
  "repeat-password": "Passwort wiederholen",
  "resend-email": "erneut senden",
  "reset-password": "Passwort zurücksetzen",
  "return-to-sign-in": "Zurück zum Anmelden",
  "send-email": "Email verschicken",
  "send-sign-in-link": "Schick mir einen Anmeldungs-Link",
  "sign-in": "Anmelden",
  "sign-in-link": "Anmeldungs-Link",
  "sign-up": "Registrieren",
  "this-shouldnt-happen": "Das sollte nicht passieren... Bitte melde dich bei uns.",
  "admin": "Admin",
  "admin-only": "Nur Admins",
  "advanced": "mehr",
  "affiliate": "Affiliate",
  "afternoon": "Nachmittag",
  "api-keys": "API-Key",
  "appearance": "Allgemein",
  "are-you-absolutely-sure": "Bist du dir absolut sicher?",
  "are-you-sure-this-action-cannot-be-undone": "Bist du dir sicher? \nDiese Aktion kann nicht rückgängig gemacht werden",
  "are-you-sure-you-want-to-delete-this-metadata-entry": "Möchtest du diesen Metadaten-Eintrag wirklich löschen?",
  "are-you-sure-you-will-be-logged-out-on-all-other-devices": "Bist du dir sicher? \nDu wirst auf allen anderen Geräten abgemeldet",
  "are-you-sure-you-will-be-logged-out-on-this-device": "Bist du dir sicher? \nDu wirst auf diesem Gerät abgemeldet",
  "background": "Hintergrund",
  "background-1": "Hintergrund 1",
  "background-2": "Hintergrund 2",
  "background-gradient": "Hintergrundverlauf",
  "billing": "Abrechnung",
  "bluepic-admin": "Bluepic-Admin",
  "borders": "Ränder",
  "cancel": "Abbrechen",
  "change": "ändern",
  "change-picture": "Bild ändern",
  "click-or-drag-a-file-to-this-area-to-upload": "Klicke oder ziehe eine Datei in diesen Bereich, um sie hochzuladen",
  "close-all-other-sessions": "Alle anderen Sitzungen schließen",
  "collection-deleted": "Post gelöscht",
  "comment": "Kommentar",
  "confirm-delete-account": "Möchtest du dein Konto wirklich löschen? \nFür deine Privatsphäre ist dies dauerhaft und kann nicht rückgängig gemacht werden.",
  "confirm-delete-space": "Möchtest du dieses Space wirklich löschen? Sofern ein Abonnement für diesen Space vorliegt, wird dieses zum Ende der Zahlungperiode beendet.",
  "confirm-logout": "Möchtest du dich wirklich abmelden?",
  "confirm-permanently-delete-collection": "Möchtest du diesen Post wirklich dauerhaft löschen? \nDies kann nicht rückgängig gemacht werden!",
  "copied-to-clipboard": "In die Zwischenablage kopiert",
  "create": "Erstellen",
  "create-profile": "Profil erstellen",
  "create-sub-space": "Sub-Space erstellen",
  "create-space": "Space erstellen",
  "created-by-creator-name": "erstellt von {0}",
  "created-created": "erstellt: {0}",
  "delete": "Löschen",
  "delete-account": "Konto löschen",
  "delete-api-key": "API-Key löschen",
  "delete-collection": "Post löschen",
  "delete-metadata": "Metadaten löschen",
  "delete-space": "Space löschen",
  "delete-user": "Benutzer löschen",
  "demote": "Adminrechte entziehen",
  "digits": "Ziffern",
  "disabled": "Deaktiviert",
  "download": "Download",
  "edit": "Bearbeiten",
  "edit-templates": "Templates bearbeiten",
  "edit-theme": "Theme bearbeiten",
  "editor": "Editor",
  "editor-warning.confirm": "OK ich verstehe",
  "editor-warning.content": "Dies ist eine frühe Beta-Version. \nWir empfehlen dringend, Google Chrome zu verwenden, um sicherzustellen, dass alles so aussieht und funktioniert, wie du es erwartest.",
  "editor-warning.title": "Betaversion!",
  "email-is-required": "E-Mail ist erforderlich",
  "email-sent": "E-Mail gesendet",
  "error-0": "Error",
  "evening": "Abend",
  "expires": "läuft ab: {0}",
  "failed-invites": "Fehlgeschlagene Einladungen:",
  "failed-to-update-user": "Benutzer konnte nicht aktualisiert werden",
  "feed": "Historie",
  "feed-type.they.CREATED": "hat einen Entwurf erstellt",
  "feed-type.they.COPIED": "hat einen Entwurf kopiert",
  "feed-type.they.MOVED": "hat einen Entwurf verschoben",
  "feed-type.they.DELETED": "hat einen Entwurf gelöscht",
  "feed-type.they.RESTORED": "hat einen Entwurf wieder hergestellt",
  "feed-type.they.UPDATED": "hat einen Entwurf aktualisiert",
  "feed-type.they.COMMENT": "hat kommentiert",
  "feed-type.you.CREATED": "Du hast einen Entwurf erstellt",
  "feed-type.you.COPIED": "Du hast einen Entwurf kopiert",
  "feed-type.you.MOVED": "Du hast einen Entwurf verschoben",
  "feed-type.you.DELETED": "Du hast einen Entwurf gelöscht",
  "feed-type.you.RESTORED": "Du hast einen Entwurf wieder hergestellt",
  "feed-type.you.UPDATED": "Du hast einen Entwurf aktualisiert",
  "feed-type.you.COMMENT": "Du hast kommentiert",
  "field-not-found": "Feld nicht gefunden",
  "finish": "Fertig",
  "font": "Schriftart",
  "font-size": "Schriftgröße",
  "foreground-1": "Vordergrund 1",
  "foreground-2": "Vordergrund 2",
  "gallery": "Galerie",
  "get-invite": "Einladung erhalten",
  "greyscale-background": "Graustufen-Hintergrund",
  "im-sure": "Ich bin sicher",
  "incoming-invites": "Eingehende Einladungen:",
  "invalid-magic-link": "ungültiger Anmeldelink",
  "invite-accepted": "Einladung angenommen!",
  "invite-declined": "Einladung abgelehnt!",
  "invite-redeemed": "Einladung eingelöst",
  "invite-resent": "Einladung erneut senden",
  "invite-revoked": "Einladung widerrufen",
  "invite-sent": "Einladung gesendet",
  "invite-sent-successfully": "Einladung erfolgreich gesendet",
  "invite-user": "Benutzer einladen",
  "invite-will-be-sent": "Einladung wird verschickt",
  "invited-by-inviter-name": "eingeladen von {0}",
  "invites": "Einladungen",
  "invites-could-not-be-sent": "Einladungen konnten nicht gesendet werden",
  "invites-sent-successfully": "Einladungen erfolgreich gesendet",
  "ip-session-ip": "IP-Adresse: {0}",
  "key": "Key",
  "key-is-required": "Key ist erforderlich",
  "kill-all-sessions": "Beende alle Sitzungen",
  "kill-session": "Sitzung schließen",
  "language": "Sprache",
  "last-chance-to-keep-account": "Dies ist deine letzte Chance, abzubrechen. \nSobald du dein Konto löschst, ist es für immer weg.",
  "last-edit-lastedit": "letzte Änderung: {0}",
  "last-seen-lastseen": "Zuletzt gesehen: {0}",
  "leave": "Verlassen",
  "leave-space": "Space verlassen",
  "left-space": "Space verlassen",
  "log-out": "Ausloggen",
  "lower-case": "Kleinbuchstaben",
  "magic-link-sent": "Wir haben dir einen Link geschickt, der dich automatisch anmeldet. Überprüfe dein Postfach",
  "max-16mb-allowed-png-jpg-jpeg": "max. \n20 MB, erlaubt: .png .jpg .jpeg",
  "me": "Ich",
  "metadata": "Metadaten",
  "metadata-created": "Metadaten erstellt",
  "metadata-deleted": "Metadaten gelöscht",
  "min-8-characters": "Mindest. \n8 Zeichen",
  "morning": "Morgen",
  "my-spaces": "Meine Spaces",
  "my-templates": "Meine Templates",
  "name": "Name",
  "name-cannot-contain-spaces": "Name darf keine Leerzeichen enthalten",
  "name-is-required": "Name ist erforderlich",
  "night": "Nacht",
  "no-category": "Keine Kategorie",
  "no-field-for-datatype-type-properties-modelvalue": "Kein Feld für Datentyp \"{0}\" {1} {2}",
  "none": "Keins",
  "notifications": "Benachrichtigungen",
  "offboarding": "Vielen Dank, dass du unseren Service genutzt hast. \nAlle deine Daten wurden dauerhaft gelöscht. \nDu wirst jetzt abgemeldet...",
  "only-png-and-jpeg-files-are-allowed": "Es sind nur PNG- und JPEG-Dateien zulässig",
  "only-redeemed": "Nur eingelöste",
  "parent": "Parent",
  "pending-invites": "Ausstehende Einladungen",
  "preview": "Vorschau",
  "primary": "Primär",
  "profile-picture": "Profilbild",
  "profile-picture-updated": "Profilbild aktualisiert",
  "profile-updated-successfully": "Profil erfolgreich aktualisiert",
  "promote": "Zu Admin machen",
  "remove": "Entfernen",
  "resend": "Erneut senden",
  "resend-confirmation-email": "Bestätigungsmail erneut senden",
  "reset": "Zurücksetzen",
  "reset-view": "Ansicht zurücksetzen",
  "retry": "Wiederholen",
  "revoke": "Widerrufen",
  "roundness": "Rundheit",
  "save": "Speichern",
  "save-to-collection": "In Post speichern",
  "saved": "Gespeichert",
  "search": "Suche",
  "secondary": "Sekundär",
  "select-a-workspace": "Wähle einen Space aus",
  "select-space": "Space auswählen",
  "select-variant": "Variante wählen:",
  "sessions": "Sitzungen",
  "set-metadata": "Metadaten festlegen",
  "settings": "Einstellungen",
  "share": "Teilen",
  "share-with-sub-spaces": "mit Sub-Spaces teilen",
  "signing-in": "Einloggen",
  "space": "Space",
  "spaces": "Spaces",
  "special-characters": "Sonderzeichen",
  "standalone-link": "Direkter Link:",
  "success": "Erfolg",
  "tap-here-to-upload": "Tippe hier, um hochzuladen",
  "space-created": "Space erstellt",
  "space-deleted": "Space gelöscht",
  "templates": "Templates",
  "text": "Text",
  "theme": "Theme",
  "title": "Titel",
  "title-is-required": "Titel ist erforderlich",
  "upload": "Hochladen",
  "upper-case": "Großbuchstaben",
  "uses-apikey": "verwendet: {0}{1}",
  "value": "Wert",
  "value-is-required": "Wert erforderlich",
  "warning": "Warnung",
  "workspace-is-required": "Space ist erforderlich",
  "yes": "Ja",
  "you-can-close-this-tab": "Du kannst diesen Tab jetzt schließen",
  "you-cant-leave-if-youre-the-only-admin": "Du kannst das Space nicht verlassen, wenn du der einzige Administrator bist",
  "your-spaces": "Deine Spaces:",
  "zoom": "Zoomen",
  "zoom-and-crop": "Zoomen und Croppen",
  "errors.ERR_AUTH_65": "ungültige Sitzung",
  "content": "Content",
  "fontsize": {
    "small": "klein",
    "medium": "mittel",
    "large": "groß"
  },
  "livemode": {
    "auto": "Auto",
    "always": "Immer",
    "never": "Nie"
  },
  "editor-live-preview": "Live-Vorschau im Editor",
  "live-preview-help": "Änderungen im Editor werden sofort angezeigt. Kann auf manchen Geräten zu Performance-Problemen führen.",
  "rendering": "wird gerendert",
  "drafts": "Entwürfe",
  "s-space": "'s Space",
  "draft": "Entwurf",
  "found-drafts": "Entwürfe:",
  "start-fresh": "ohne Entwurf fortfahren",
  "only-unredeemed": "Nur uneingelöst",
  "redeemed-invites": "Eingelöste Einladungen:",
  "you": "Du",
  "email-confirmed": "email bestätigt",
  "organization": "Organisation",
  "organization-shorthand": "Org",
  "email-template": "E-Mail-Vorlage",
  "add-payment-method": "Zahlungsmethode hinzufügen",
  "address-line-2": "Adresszeile 2",
  "are-you-sure-you-want-to-cancel-this-subscription": "Möchtest du dieses Abonnement wirklich kündigen?",
  "are-you-sure-you-want-to-delete-this-payment-method": "Möchtest du diese Zahlungsmethode wirklich löschen?",
  "billing-contact": "Rechnungskontakt",
  "cancel-subscription": "Abonnement kündigen",
  "city": "Stadt",
  "confirm": "Bestätigen",
  "current-plan": "Aktueller Plan",
  "default": "Standard",
  "delete-payment-method": "Zahlungsmethode löschen",
  "enterprise": "Unternehmen",
  "free": "Free",
  "invoices": "Rechnungen",
  "load-more": "Mehr laden",
  "monthly": "Monatlich",
  "no-invoices-found": "Keine Rechnungen gefunden",
  "no-payment-methods-found": "Keine Zahlungsmethoden gefunden",
  "open": "Offen",
  "paid": "Bezahlt",
  "pay": "Zahlen",
  "payment-methods": "Zahlungsarten",
  "per": "pro",
  "phone": "Telefon",
  "pro": "Pro",
  "state": "Bundesland",
  "street": "Adresszeile 1",
  "subscriptions": "Abonnements",
  "switch-to-monthly-billing": "Wechseln zur monatlichen Abrechnung",
  "switch-to-yearly-billing": "Wechseln zur jährlichen Abrechnung",
  "uncollectible": "Buchung abgelehnt",
  "void": "Ungültig",
  "yearly": "Jährlich",
  "zip": "Postleitzahl",
  "expiration-date": "Ablaufdatum",
  "month": "Monat",
  "year": "Jahr",
  "address-line-1-is-required": "Adresszeile 1 ist erforderlich",
  "city-is-required": "Stadt ist erforderlich",
  "fast": "schnell",
  "phone-is-required": "Telefon ist erforderlich",
  "postal-code-is-required": "Postleitzahl ist erforderlich",
  "slow": "langsam",
  "state-is-required": "Staat ist erforderlich",
  "transition-speed": "Übergangsgeschwindigkeit",
  "are-you-in-the-right-space": "Bist du im richtigen Space?",
  "you-dont-have-permission-for-this-template": "Du darfst dieses Template nicht benutzen.",
  "copy-link": "Link kopieren",
  "accept": "Annehmen",
  "by": "von",
  "decline": "Ablehnen",
  "invalid-invite": "Ungültige Einladung",
  "you-are-not-the-intended-recipient-of-this-invite": "Du bist nicht der beabsichtigte Empfänger dieser Einladung.",
  "you-have-been-invited-to-join": "Du wurdest eingeladen einem Space beizutreten:",
  "pro-badge": "PRO",
  "next-payment": "Nächste Zahlung",
  "cancel-subscription-short": "kündigen",
  "keep-it": "behalten",
  "subscription-canceled": "Abonnement gekündigt",
  "5-personal-templates": "5 private Templates",
  "early-access-to-new-template-collections": "früher Zugriff auf neue Template-Kollektionen",
  "free-forever": "Für immer kostenlos",
  "full-access-to-learning-material": "vollen Zugriff auf Lernmaterial",
  "pro-header": "Pro - 5€ / Monat",
  "pro-subtitle": "Für unabhängige Content-Creator",
  "professional": "Pro",
  "switch-to-free": "Wechseln zu Free",
  "switch-to-pro": "Wechseln zu Pro",
  "unlimited-personal-templates": "unbegrenzte private Templates",
  "unlimited-projects": "unbegrenzte Projekte",
  "cancel-pro-subscription": "Möchtest du dein Pro-Abonnement wirklich kündigen? \nDu verlierst den Zugriff auf alle Pro-Funktionen. \nDeine überzähligen privaten Templates werden gelöscht.",
  "running-out": "Läuft aus",
  "reactivate": "Reaktivieren",
  "delete-all": "alle löschen",
  "are-you-sure-you-want-to-delete-all-drafts-this-cannot-be-undone": "Bist du sicher, dass du alle Entwürfe löschen möchtest? Das kann nicht rückgängig gemacht werden!",
  "vat": "UST",
  "apply": "Anwenden",
  "confirm-purchase": "Kauf bestätigen",
  "coupon": "Coupon",
  "renews-automatically": "wird automatisch verlängert",
  "subtotal": "Summe",
  "total": "Rechnungsbetrag",
  "shared": "geteilt",
  "all-subscriptions": "Alle Abonnements",
  "deleted-user": "gelöschter Nutzer",
  "upcoming-payments": "anstehende Zahlungen",
  "hidden": "Versteckt",
  "visible": "Sichtbar",
  "failed-to-update-space": "Space konnte nicht aktualisiert werden",
  "mixed": "gemischt",
  "enabled": "aktiviert",
  "extra": "Extra",
  "medium": "Mittel",
  "minimal": "Minimal",
  "coupon-applied": "Gutschein angewendet",
  "active": "Aktiv",
  "make-default": "Zur Standard-Zahlungsmethode machen",
  "no-subscription": "Kein Abo",
  "owner": "Besitzer",
  "sub_inactive": "Abonnement ist inaktiv",
  "sub_noPayment": "Dieses Abonnement wurde nicht bezahlt",
  "sub_noSubscription": "Wir konnten für dieses Space kein Abonnement finden",
  "sub_notEnoughDepth": "Dein Space hat mehr Subteam-Ebenen, als dein Abonnement zulässt",
  "sub_notEnoughSlots": "Dein Space hat mehr Mitglieder, als dein Abonnement zulässt",
  "action-required": "Handlung erforderlich",
  "hierarchy-layers": "Subteam-Ebenen",
  "pay-now": "Jetzt bezahlen",
  "slots": "Slots",
  "subscribe-now": "Abonniere jetzt",
  "subscription": "Abonnement",
  "upgrade-subscription-now": "Abonnement jetzt upgraden",
  "excl": "excl",
  "incl": "incl",
  "incl-tax": "incl. Ust",
  "tax": "Ust",
  "payment-interval": "Zahlungsintervall",
  "hard-delete-space": "Space HART-löschen",
  "edit-subscription": "Abonnement bearbeiten",
  "hierarchy-layers-are-required": "Subteam-Ebenen sind erforderlich",
  "maximum-1-layer": "Maximal 1 Ebene",
  "maximum-300-slots": "Maximal 300 Steckplätze",
  "minimum-status-value-actualdepth-1-layer": "Mindestens {0} Ebenen",
  "minimum-status-value-totalmembers-5-slots": "Mindestens {0} Slots",
  "slots-are-required": "Steckplätze sind erforderlich",
  "you-need-to-add-a-payment-method-before-you-can-purchase-a-subscription": "Du musst eine Zahlungsmethode hinzufügen, bevor du ein Abonnement abschließen kannst.",
  "all-spaces": "Alle Spaces",
  "all-users": "Alle User",
  "custom-prices": "Custom Preise",
  "admin_prices": "Custom Preise",
  "admin_teams": "Alle Spaces",
  "admin_users": "Alle User",
  "no-custom-prices-found": "Keine custom Preise gefunden",
  "create-custom-price": "Custom Preis erstellen",
  "choose-your-own-price": "Pay what you want",
  "custom-price": "Custom Preis",
  "expression": "Formel",
  "expression-is-required": "Formel wird benötigt",
  "invalid-expression": "Ungültige Formel",
  "javascript-expression": "Javascript Ausdruck",
  "min-newprice": "min. {0}",
  "no-subscription-needed": "Kein Abonnement nötig",
  "price": "Preis",
  "search-users-by-email": "Suche User nach email",
  "select": "Auswählen",
  "try-it-out": "Tester",
  "update": "Update",
  "user-ids": "User Ids",
  "users": "Users",
  "admin-settings": "Admin Einstellungen",
  "experimental": "Experimental-",
  "use-webgl-when-available": "WebGL nutzen, wenn verfügbar",
  "uploading": "Hochladen",
  "normalizing": "Normalisieren",
  "checking-permission": "Erlaubnis prüfen",
  "upload-failed": "Hochladen fehlgeschlagen",
  "image-file-size-is-too-big": "Bilddatei ist zu groß.",
  "please-let-us-know-if-this-keeps-happening": "Bitte sag uns Bescheid, falls dies weiterhin auftritt.",
  "image-file-is-corrupted": "Bilddatei ist beschädigt.",
  "learn-to-use-bluepic-social": "Lerne Bluepic Social kennen",
  "onboarding": "Onboarding",
  "reset-all": "Alles zurücksetzen",
  "reset-progress": "Fortschritt zurücksetzen",
  "resume": "Fortsetzen",
  "start": "Start",
  "admin_feature_flags": "Feature-Flags",
  "are-you-sure-you-want-to-reset-all-progress": "Bist du sicher, dass du den gesamten Fortschritt zurücksetzen möchtest?",
  "feature-flags": "Feature-Flags",
  "firebase-messaging": "Firebase Messaging",
  "nothing-to-see-here": "Hier gibt's nichts zu sehen",
  "reset-all-progress": "Gesamten Fortschritt zurücksetzen",
  "add-connection": "Verbindung hinzufügen",
  "are-you-sure-you-want-to-remove-this-connection": "Möchtest du diese Verbindung wirklich entfernen?",
  "connected-accounts": "Verbundene Konten",
  "connected-accounts-num-0": "Keine Verbindungen",
  "connected-accounts-num-1": "eine Verbindung",
  "connected-accounts-num-x": "{0} Verbindungen",
  "continue-with": "Weiter mit:",
  "or": "oder",
  "rate-limit-exceeded-please-try-again-in-a-few-minutes": "Rate-Limit überschritten. \nBitte versuchs in ein paar Minuten erneut.",
  "remove-connection": "Verbindung entfernen",
  "unauthorized": "Nicht autorisiert",
  "errors.ERR_AUTH_1": "Du bist nicht berechtigt, einen API-Schlüssel für diesen Benutzer zu erstellen",
  "errors.ERR_AUTH_10": "Benutzer nicht gefunden",
  "errors.ERR_AUTH_100": "Der Benutzer ist kein Teambesitzer",
  "errors.ERR_AUTH_101": "Preis konnte nicht generiert werden",
  "errors.ERR_AUTH_102": "Abonnement konnte nicht erstellt werden",
  "errors.ERR_AUTH_103": "Ungültiger benutzerdefinierter Preisausdruck",
  "errors.ERR_AUTH_104": "Der Benutzer ist bereits Mitglied dieses Spaces",
  "errors.ERR_AUTH_105": "Du kannst Metadaten nicht sowohl für Benutzer als auch für Spaces festlegen",
  "errors.ERR_AUTH_106": "Unbekannter Emittent",
  "errors.ERR_AUTH_107": "Nicht implementiert",
  "errors.ERR_AUTH_108": "Du darfst kein Space ohne Abonnement erstellen",
  "errors.ERR_AUTH_109": "Du darfst dieses Space nicht endgültig löschen",
  "errors.ERR_AUTH_11": "Ungültiger Schlüssel",
  "errors.ERR_AUTH_110": "Du bist nicht berechtigt, dieses Spaces-Abonnement zu bearbeiten",
  "errors.ERR_AUTH_111": "Du bist nicht berechtigt, dieses Spaces-Abonnement zu überprüfen",
  "errors.ERR_AUTH_112": "Nur Root-Spaces können Abonnements haben",
  "errors.ERR_AUTH_113": "Kein Token",
  "errors.ERR_AUTH_114": "Keine E-Mail im Token",
  "errors.ERR_AUTH_115": "Ungültiges OIDC-ID-Token",
  "errors.ERR_AUTH_116": "E-Mail ist für Nicht-Dummy-Benutzer erforderlich",
  "errors.ERR_AUTH_117": "Dieser Benutzer ist nicht Teil dieses Spaces",
  "errors.ERR_AUTH_118": "keine Email",
  "errors.ERR_AUTH_119": "ID ist bereits mit einem Konto verknüpft",
  "errors.ERR_AUTH_12": "Du hast zu lange gebraucht, um den Code einzugeben.",
  "errors.ERR_AUTH_120": "ID nicht gefunden",
  "errors.ERR_AUTH_13": "E-Mail-Bestätigung nicht gefunden",
  "errors.ERR_AUTH_14": "E-Mail-Bestätigung abgelaufen",
  "errors.ERR_AUTH_15": "Fehler beim Bestätigen der E-Mail",
  "errors.ERR_AUTH_16": "Der Benutzer darf dieses Space nicht einladen",
  "errors.ERR_AUTH_17": "Der Benutzer darf diese Einladung nicht einlösen",
  "errors.ERR_AUTH_18": "Einladung nicht gefunden",
  "errors.ERR_AUTH_19": "Benutzer darf Einladungen nicht lesen",
  "errors.ERR_AUTH_2": "Der Apikey-Ablauf darf nicht mehr als 1 Jahr in der Zukunft liegen",
  "errors.ERR_AUTH_20": "Der Benutzer darf diese Einladung nicht widerrufen",
  "errors.ERR_AUTH_21": "Der Benutzer darf diese Einladung nicht ablehnen",
  "errors.ERR_AUTH_22": "Du bist nicht berechtigt, Metadaten für diesen Benutzer abzurufen",
  "errors.ERR_AUTH_23": "Du bist nicht berechtigt, Metadaten für diesen Benutzer festzulegen",
  "errors.ERR_AUTH_24": "Metadaten nicht gefunden",
  "errors.ERR_AUTH_25": "Du bist nicht berechtigt, die Berechtigungen dieses Benutzers zu erhalten",
  "errors.ERR_AUTH_26": "Zurücksetzen nicht gefunden",
  "errors.ERR_AUTH_27": "Zurücksetzen abgelaufen",
  "errors.ERR_AUTH_28": "Du kannst keine andere Benutzersitzung löschen",
  "errors.ERR_AUTH_29": "Du kannst die Sitzungen dieses Benutzers nicht anzeigen",
  "errors.ERR_AUTH_3": "Du bist nicht berechtigt, Mehrzweck-Apikeys zu erstellen",
  "errors.ERR_AUTH_30": "Du darfst kein Space erstellen",
  "errors.ERR_AUTH_31": "Du bist nicht berechtigt, ein Unterteam für dieses Space zu erstellen",
  "errors.ERR_AUTH_33": "Der Teamname existiert bereits in deiner Organisation",
  "errors.ERR_AUTH_35": "Du darfst nicht alle Spaces bekommen",
  "errors.ERR_AUTH_36": "Space nicht gefunden",
  "errors.ERR_AUTH_37": "Benutzer ist bereits Administrator",
  "errors.ERR_AUTH_38": "Du kannst diesem Space keine Administratoren hinzufügen",
  "errors.ERR_AUTH_39": "Benutzer ist kein Administrator",
  "errors.ERR_AUTH_4": "Du bist nicht berechtigt, einen magischen Link für diesen Benutzer zu erstellen",
  "errors.ERR_AUTH_40": "Du kannst den letzten Administrator nicht entfernen",
  "errors.ERR_AUTH_41": "Du kannst keine Administratoren aus diesem Space entfernen",
  "errors.ERR_AUTH_42": "Benutzer ist bereits Mitglied",
  "errors.ERR_AUTH_43": "Benutzer ist kein Mitglied",
  "errors.ERR_AUTH_44": "Du kannst keine Mitglieder aus diesem Space entfernen",
  "errors.ERR_AUTH_45": "Benutzer ist ein Administrator",
  "errors.ERR_AUTH_46": "Du darfst diesen Benutzer nicht sperren",
  "errors.ERR_AUTH_47": "Space ist nicht gesperrt",
  "errors.ERR_AUTH_48": "Du bist nicht berechtigt, dieses Space zu bearbeiten",
  "errors.ERR_AUTH_49": "Space mit diesem Namen existiert bereits",
  "errors.ERR_AUTH_5": "Apikey nicht gefunden",
  "errors.ERR_AUTH_50": "Du bist nicht berechtigt, Teammitglieder zu bearbeiten",
  "errors.ERR_AUTH_51": "Du bist nicht berechtigt, Teamthemen zu bearbeiten",
  "errors.ERR_AUTH_52": "Du bist nicht berechtigt, die Passthrough-Berechtigungen dieses Spaces zu ändern",
  "errors.ERR_AUTH_53": "Du kannst nur Berechtigungen weitergeben, die bereits im Space vorhanden sind",
  "errors.ERR_AUTH_54": "Du bist nicht berechtigt, die Berechtigungen dieses Spaces zu ändern",
  "errors.ERR_AUTH_55": "Du bist nicht berechtigt, dieses Space zu löschen",
  "errors.ERR_AUTH_56": "Du kannst ein Space mit Subteams nicht löschen",
  "errors.ERR_AUTH_57": "Dummy-Benutzer können TOTP nicht aktiviert haben",
  "errors.ERR_AUTH_58": "Ein Benutzer mit dieser E-Mail existiert bereits",
  "errors.ERR_AUTH_59": "Einem Space muss ein Dummy-Benutzer zugewiesen werden",
  "errors.ERR_AUTH_6": "Du bist nicht berechtigt, die API-Schlüssel dieses Benutzers anzuzeigen",
  "errors.ERR_AUTH_60": "Passwörter müssen mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Ziffer enthalten",
  "errors.ERR_AUTH_61": "Du kannst nur Dummy-Benutzer für dein eigenes Space erstellen",
  "errors.ERR_AUTH_62": "Du bist nicht berechtigt, diesen Benutzer zu entsperren",
  "errors.ERR_AUTH_63": "Du bist nicht berechtigt, diesen Benutzer zu entfernen",
  "errors.ERR_AUTH_64": "keine Benutzer gefunden",
  "errors.ERR_AUTH_67": "Dummy-Benutzer können 2fa nicht verwenden",
  "errors.ERR_AUTH_68": "kein neues Topp-Geheimnis zu bestätigen",
  "errors.ERR_AUTH_69": "ungültiges Token",
  "errors.ERR_AUTH_7": "Du bist nicht berechtigt, diesen API-Schlüssel zu löschen",
  "errors.ERR_AUTH_70": "Du bist nicht berechtigt, diesen Benutzer zu bearbeiten",
  "errors.ERR_AUTH_72": "Du bist nicht berechtigt, die maximale Sitzungsanzahl zu bearbeiten",
  "errors.ERR_AUTH_73": "Du bist nicht berechtigt, die Berechtigungen dieses Benutzers zu ändern",
  "errors.ERR_AUTH_74": "Du bist nicht berechtigt, das Passwort dieses Benutzers zu ändern",
  "errors.ERR_AUTH_76": "Keine neue E-Mail zur Bestätigung",
  "errors.ERR_AUTH_79": "Wert erforderlich",
  "errors.ERR_AUTH_80": "Apikey abgelaufen",
  "errors.ERR_AUTH_81": "Admins und Mitglieder dürfen sich nicht überschneiden",
  "errors.ERR_AUTH_82": "Profil ist deaktiviert",
  "errors.ERR_AUTH_83": "Ungültiger Schlüssel",
  "errors.ERR_AUTH_84": "Während der geschlossenen Beta ist eine Einladung erforderlich",
  "errors.ERR_AUTH_85": "Der Benutzer ist kein Affiliate",
  "errors.ERR_AUTH_86": "Profil ist deaktiviert",
  "errors.ERR_AUTH_87": "Kein Auth-Header bereitgestellt",
  "errors.ERR_AUTH_88": "Ungültiger Auth-Header",
  "errors.ERR_AUTH_89": "Ungültiger API-Schlüssel",
  "errors.ERR_AUTH_9": "Benutzer ist gesperrt. \nÜberprüfe deine Emails.",
  "errors.ERR_AUTH_90": "Ungültige Sitzung",
  "errors.ERR_AUTH_92": "Ungültiger OIDC Token",
  "errors.ERR_AUTH_93": "Benutzerdefinierter Preis nicht gefunden",
  "errors.ERR_AUTH_94": "Benutzerdefinierter Preis wird von Spaces verwendet",
  "errors.ERR_AUTH_95": "Abonnement nicht gefunden",
  "errors.ERR_AUTH_96": "Kunde nicht gefunden",
  "errors.ERR_AUTH_97": "Wähle deinen eigenen Preis nicht erlaubt",
  "errors.ERR_AUTH_98": "Space nicht gefunden",
  "errors.ERR_AUTH_99": "Subteams können keine eigenen Abonnements haben",
  "errors.ERR_AUTH_122": "Dieser Space kann keine weiteren Mitglieder aufnehmen. Kontaktiere den Besitzer des Spaces.",
  "login-successful": "Anmeldung erfolgreich",
  "you-can-close-this-window-now": "Du kannst dieses Fenster jetzt schließen",
  "contact-us": "Kontaktiere uns",
  "invalid-email-or-password": "Ungültige E-Mail oder Passwort",
  "login-failed": "Fehler bei der Anmeldung",
  "country": "Land",
  "country-is-required": "Land ist erforderlich",
  "edit-billing-contact": "Rechnungskontakt bearbeiten",
  "incomplete": "Unvollständig",
  "please-complete-your-billing-information": "Bitte vervollständige deinen Rechnungskontakt",
  "reactivate-subscription": "Abo reaktivieren",
  "select-profile": "Space wählen",
  "sub_canceled": "Abo gekündigt",
  "you-cant-leave-if-youre-the-owner": "Du kannst das Space nicht verlassen wenn du der Besitzer bist",
  "please-wait": "bitte warten",
  "credit": "Guthaben",
  "send-invite": "Einladung schicken",
  "contrast-between-primary-and-background-is-low": "Kontrast zwischen Primär und Hintergrund ist gering!",
  "reset-to-default": "Auf Standart zurücksetzen",
  "failed-to-load-image": "Bild konnte nicht geladen werden",
  "dev-warning-part-1": "Du befindest dich in der Entwicklungs-Umgebung. Erwarte häufige Updates und fatale Bugs. Gehe zu ",
  "dev-warning-part-2": " für die stabile Version.",
  "development-build": "Entwicklungs-Umgebung",
  "go-to-stable": "Gehe zur stabilen Version",
  "stay": "hier bleiben",
  "unsaved-changes": "Ungespeicherte Änderungen",
  "you-have-unsaved-changes-are-you-sure-you-want-to-leave": "Du hast ungespeicherte Änderungen, bist du sicher, dass du gehen willst?",
  "dont-show-again": "nicht nochmal anzeigen",
  "are-you-sure-you-want-to-reset-all-values-to-their-defaults": "Bist du sicher, dass du alle Werte auf Standard zurücksetzen willst?",
  "reset-to-default-values": "Auf Standard zurücksetzen?",
  "caption": "Beschreibung",
  "save-as-draft": "Entwurf speichern",
  "tags": "Tags",
  "choose-tags": "Tags wählen",
  "enter-a-caption": "Schreib eine Caption",
  "enter-a-title": "Schreib einen Titel",
  "owner-only": "Nur Besitzer",
  "hub": "Hub",
  "are-you-sure-you-want-to-delete-this-draft": "Bist du sicher, dass du diesen Entwurf löschen willst?",
  "are-you-sure-you-want-to-leave-this-page-any-unsaved-changes-will-be-lost": "Bist du sicher, dass du diese Seite verlassen willst? Jeglicher noch nicht gespeicherter Fortschritt wird verworfen.",
  "copy": "kopieren",
  "copy-suffix": "(Kopie)",
  "delete-draft": "Entwurf löschen",
  "deleted-at": "gelöscht {0} {1}",
  "discard-changes": "Änderungen verwerfen",
  "draft-deleted": "Entwurf gelöscht",
  "incl-sub-spaces": "(inkl.) Sub-Spaces",
  "invite": "Einladen",
  "members": "Mitglieder",
  "members-sub-spaces": "Mitglieder / Sub-Spaces",
  "no-render-available": "Kein Render verfügbar",
  "only billed for X unique": "Du bezahlst nur für {0} Mitglieder",
  "overwrite": "überschreiben",
  "restore": "Wiederherstellen",
  "save-as-new": "Als Kopie speichern",
  "sharing-is-not-supported-on-this-platform": "Teilen ist nicht verfügbar auf dieser Platform",
  "show-all": "Alles anzeigen",
  "sub-spaces": "Sub-Spaces",
  "this-draft-has-been-modified-since-you-started-editing": "Dieser Entwurf wurde geändert, seitdem du angefangen hast ihn zu bearbeiten",
  "trash": "Papierkorb",
  "untitled": "Ohne Titel",
  "you-dont-have-access-to-this-template": "Du hast keinen Zugriff auf dieses Template",
  "you-have-unsaved-changes": "Du hast ungespeicherte Änderungen",
  "keep-original": "Original behalten",
  "move": "verschieben",
  "move-copy-draft": "Entwurf verschieben / kopieren",
  "need-templates-check-out": "Brauchst du Templates? Besuche: ",
  "target-spaces": "Ziel-Spaces:",
  "teams": "Spaces",
  "account": "Account",
  "all-length-0-hidden-length-0-visible-templates": "{0} sichtbare templates",
  "draft-saved": "Entwurf gespeichert",
  "draft-restored": "Entwurf wiederhergestellt",
  "draft-keeporiginal-value-copied-moved-successfully-to-targetspaces-size-spaces": "Entwurf wurde erfolgreich in {1} Spaces {0}",
  "hidden-length-0-hidden-templates": "{0} ausgeblendete Templates",
  "select-target-spaces": "Ziel-Spaces auswählen",
  "logout": "Ausloggen",
  "team-memberids-length-0-team-adminids-length-0-members": "{0} Mitglieder",
  "templatestore-templates-size-templates": "{0} Templates",
  "move-copy": "Verschieben / Kopieren",
  "copied": "kopiert",
  "moved": "verschoben",
  "dont-have-access-to-this-template": "haben keinen Zugriff auf dieses Template",
  "are-you-sure-you-want-to-leave": "Bist du sicher, dass du diesen Space verlassen willst?",
  "you-will-lose-access-to-this-space": "Du wirst Zugriff auf alle Space-Ressourcen verlieren.",
  "theres-nothing-here": "Hier ist nichts",
  "are-you-sure-you-want-to-leave-this-space": "Bist du sicher, dass du diesen Space verlassen willst?",
  "are-you-sure-you-want-to-remove-this-user-from-the-space": "Bist du sicher, dass du diesen User aus dem Space entfernen willst?",
  "remove-member": "Mitglied entfernen",
  "you-can-only-upload-files-up-to-16mb": "Du kannst nur Dateien bis zu 20 MB hochladen",
  "you-can-only-upload-files-up-to-10mb": "Du kannst nur Dateien bis zu 16 MB hochladen",
  "compressing": "Komprimieren",
  "cropping": "Zuschneiden",
  "saving": "Speichern",
  "something-went-wrong": "Da ist etwas schief gegangen...",
  "down": "down",
  "up": "up",
  "too-many-redirects-checking-services": "Zu viele Weiterleitungen, checke Services...",
  "retry-2": "Erneut versuchen",
  "update-banner-bottom-1": "Wenn du irgendwas vermisst, bitte kontaktiere uns unter",
  "update-banner-bottom-2": "oder ruf uns an unter",
  "update-banner-msg-p1": "Wir haben unser Template-System überarbeitet und Workspaces mit einer reinen Entwurf-Funktion ersetzt.",
  "today": "heute",
  "manage-plan": "Abo bearbeiten",
  "shared-seats": "Shared seats",
  "you-dont-have-enough-slots-for-all-members-please-upgrade-your-plan": "Du hast nicht genug slots für alle Mitglieder. Bitte passe dein Abo an.",
  "add-ons": "Add-Ons",
  "next": "Weiter",
  "plan": "Plan",
  "previous": "Zurück",
  "summary": "Zusammenfassung",
  "space-theme": "Space Theme",
  "unlimited-templates": "Unbegrenzte Templates",
  "available-plans": "Erhältliche Pläne:",
  "add-plan": "Plan hinzufügen",
  "no-sub-needed": "Kein Abo nötig",
  "starting-at": "ab",
  "options": "Optionen",
  "need-more-shared-seats-contact-us-at": "Brauchst du mehr Shared Seats? Kontaktiere uns unter:",
  "need-more-slots-contact-us-at": "Brauchst du mehr Slots? Kontaktiere uns unter:",
  "proceed-to-checkout": "Weiter zur Kasse",
  "change-subscription": "Abo ändern",
  "sub-spaces-need-their-own-subscriptions": "Sub-Spaces brauchen ihre eigenen Abos",
  "this-space-is-included-in-subscriptionteam-name-s-subscription": "Dieser Space ist in {0}'s Abo enthalten",
  "premium-feature": "Premium Funktion",
  "want-to-save-and-edit-drafts-upgrade-your-plan-and-check-drafts-at-add-ons": "Willst du Entwürfe speichern und bearbeiten? Upgrade dein Abo und wähle \"Entwürfe\" bei den Add-Ons",
  "want-to-create-sub-spaces-upgrade-your-subscription-to-business": "Willst du Sub-Spaces erstellen? Upgrade dein Abo auf \"Business+\"",
  "social-shared": "Social Shared",
  "want-to-let-people-use-your-templates-without-an-account-subscribe-to-social-shared": "Du willst deine Templates mit anderen teilen, ohne, dass sie einen Account brauchen? Abonniere Social Shared",
  "login-link": "Login Link",
  "generate-new-link": "Neuen Link generieren",
  "logging-in": "Einloggen",
  "all-available-shared-seats-are-filled-try-again-later": "Alle verfügbaren Shared Seats sind besetzt. Versuch es später nochmal",
  "create-dummy-session": "Dummy session erstellen",
  "unknown": "Unbekannt",
  "youve-been-signed-out-due-to-inactivity": "Du wurdest wegen Inaktivität ausgeloggt",
  "session-timeout": "Sitzung läuft bald ab",
  "you-will-be-signed-out-in-timeout-value-getminutes-timeout-value-getseconds": "Du wirst ausgeloggt in {0}:{1}",
  "are-you-sure-you-want-to-generate-a-new-link-the-old-link-will-no-longer-work-and-all-sessions-will-be-signed-out": "Bist du sicher, dass du einen neuen Link generieren willst? Der alte wird nicht mehr funktionieren und alle Sitzungen werden ausgeloggt.",
  "this-link-is-invalid-please-contact-your-space-admin": "Dieser Link ist ungültig. Bitte kontaktiere deinen Space Admin",
  "you-have-successfully-signed-out": "Du wurdest erfolgreich ausgeloggt",
  "SPACE_EVENTS": "etwas in meinem Space passiert (Mitglieder hinzugefügt/entfernt, Besitzer wechselt, etc.)",
  "TEMPLATES": "meine Space Templates sich ändern",
  "INVITES": "Ich zu einem Space eingeladen werde",
  "ACCOUNT_EVENTS": "mein Account sich ändert (Passwort, Email, etc.)",
  "notify-me-when": "Benachrichtige mich, wenn",
  "settings-saved": "Einstellungen gespeichert!",
  "via": "per",
  "app": "App",
  "email-0": "Email",
  "push": "Push",
  "SPACE_INVITE": "<b>{0}</b> hat dich zu seinem Space <b>{1}</b> eingeladen",
  "SPACE_MEMBER_JOINED": "<b>{0}</b> Ist deinem Space <b>{1}</b> beigetreten",
  "SPACE_MEMBER_REMOVED": "<b>{0}</b> hat deinen Space <b>{1}</b> verlassen",
  "SPACE_LOCKED": "Dein Space <b>{0}</b> wurde gesperrt.",
  "SPACE_UNLOCKED": "Dein Space <b>{0}</b> wurde entsperrt",
  "SPACE_PROMOTED": "<b>{0}</b> hat dich zum Space Admin von <b>{1}</b> ernannt",
  "SPACE_DEMOTED": "Du bist nicht mehr Space Admin von <b>{0}</b>. Du wurdest von <b>{1}</b> herunter gestuft",
  "SPACE_OWNER_CHANGED": "Der Besitzer von deinem Space <b>{0}</b> ist jetzt <b>{1}</b>",
  "SPACE_OWNER_TRANSFER_REQUESTED": "<b>{0}</b> möchte den Space <b>{1}</b> an dich übertragen",
  "SPACE_DELETED": "Dein Space <b>{0}</b> wurde von <b>{1}</b> gelöscht",
  "SPACE_TEMPLATE_ADDED": "Ein neues Template <b>{0}</b> wurde zu deinem Space <b>{1}</b> hinzugefügt",
  "USER_EMAIL_CONFIRMED": "Deine Email Adresse wurde bestätigt",
  "USER_LOCKED": "Dein Account wurde von <b>{0}</b> gesperrt. Grund: <b>{1}</b>",
  "USER_UNLOCKED": "Dein Account wurde von <b>{0}</b> entsperrt",
  "UPDATE_EMAIL": "Deine Email Adresse wurde zu <b>{1}</b> geändert",
  "CHANGE_PASSWORD": "Dein Passwort wurde geändert",
  "last-30-days": "letzte 30 Tage",
  "last-7-days": "letzte 7 Tage",
  "new": "neu",
  "broadcast-to-space": "Meldung an Space schicken",
  "enter-your-message-here": "Gib hier deine Nachricht ein",
  "send": "Senden",
  "broadcast-message": "Meldung an Space schicken",
  "override": "Überschreiben",
  "studio": "Bluepic Studio",
  "id": "Bluepic ID Portal",
  "you-are-not-allowed-to-access-the-staging-environment": "Du darfst nicht auf die Staging-Umgebung zugreifen",
  "this-space-cant-have-its-own-subscription": "Dieser Space kann kein eigenes Abo haben",
  "please-enter-a-space-name": "Gib einen Namen für den Space ein",
  "add-tag": "Tag hinzufügen",
  "filter-by-tags": "nach Tags filtern",
  "edit-tags": "Tags bearbeiten",
  "tags-must-have-unique-names": "Mehrere Tags können nicht den selben Namen haben",
  "new-tag": "Neuer Tag",
  "and": "UND",
  "or-0": "ODER",
  "cancels-automatically-on": "Wird automatisch beendet: ",
  "duplicate": "Duplizieren",
  "locked": "gesperrt",
  "your-current-space-is-locked-please-contact-support": "Dein ausgewählter Space ist gesperrt. Kontaktiere unseren Support.",
  "stats": "Statistik",
  "request-ownership-transfer": "Besitz-Transfer anfragen",
  "ownership-transfer-pending": "Besitz-Transfer ausstehend",
  "ownership-transfer-requested": "Besitz-Transfer angefragt",
  "user-demoted-to-member": "Nutzer ist nicht mehr Admin",
  "user-promoted-to-admin": "User ist jetzt Admin",
  "cancel-ownership-transfer": "Besitz-Transfer abbrechen",
  "ownership-transfer-cancelled": "Besitz-Transfer abgebrochen",
  "has-requested-to-transfer-ownership-of-this-space-to-you": "möchte diesen Space an dich übertragen.",
  "comment-sent": "Kommentar versandt",
  "comment-deleted": "Kommentar gelöscht",
  "batch": "Batch",
  "default-prices-are-available-to-all-users": "Default Preise stehen für alle User zur Verfügung",
  "please-use-a-bigger-screen-for-the-batch-editor": "Bitte benutze einen größeren Bildschirm für den Batch-Editor",
  "batch-upload": "mehrere hochladen",
  "create-rows": "Zeilen erstellen",
  "create-x-rows": "X Zeilen erstellen",
  "data-includes-headers": "Daten enthalten Überschriften",
  "drop-csv-file-to-import-data": ".csv Datei ablegen um Daten zu importieren",
  "how-many-rows-should-be-created": "Wie viele Zeilen sollen erstellt werden?",
  "import-data": "Daten importieren",
  "import-from-clipboard": "Aus Zwischenablage importieren",
  "match-data-to-fields": "Felder zu Daten zuordnen",
  "select-a-file-or-import-from-clipboard": "Wähle eine Datei oder importiere von Zwischenablage",
  "select-field": "Feld auswählen",
  "select-file": "Datei auswählen",
  "sharing-is-not-supported-in-batch-mode": "Teilen wird nicht unterstützt im Batch Modus",
  "this-feature-is-not-available-yet-were-working-on-it": "Dieses feature ist noch nicht verfügbar. Wir arbeiten dran!",
  "single": "Einzeln",
  "dont-see-anything-you-like": "Nichts passendes dabei?",
  "ownership-transfer-accepted": "Besitz-Transfer angenommen",
  "were-happy-to-help": "Wir helfen dir gerne",
  "need-templates-ask-your-parent-space-to-share-some-or-check-out": "Brauchst du Templates? Bitte deinen Parent-Space, welche zu teilen oder besuche:",
  "want-to-split-your-organisation-into-sub-spaces-upgrade-your-subscription-to-business": "Willst du deine Organisation in Sub-Spaces aufteilen? Upgrade dein Abo auf \"Business+\"",
  "manage-templates": "Templates verwalten",
  "want-to-theme-bluepic-social-to-fit-in-with-your-corporate-identity-uprade-your-subscription-to-business": "Willst du Bluepic Social an deine Corporate-Identity anpassen? Upgrade dein Abo zu \"Business\"",
  "are-you-sure": "Bist du sicher?",
  "delete-row": "Zeile löschen",
  "pattern": "Muster",
  "from": "von",
  "generate": "generiere",
  "pattern-generator": "Muster-Generator",
  "repeat": "wiederholen",
  "stops": "stopps",
  "to": "zu",
  "export": "Export",
  "edit-multiple-versions-of-this-template-at-once": "Bearbeite mehrere Versionen auf einmal",
  "batchUploadTooltip": "Lade mehrere Bilder hoch. Es wird für jedes Bild eine neue Zeile generiert",
  "delete-rows": "Zeilen löschen",
  "delete-selected": "Lösche",
  "download-selected": "Download",
  "patternTooltip": "Generiere ein Farb-Muster. Für jede Farbe wird eine neue Zeile generiert",
  "select-all": "Alle auswählen",
  "selected": "ausgewählt",
  "new-lines": "neue Zeilen",
  "steps": "Stufen",
  "delete-disabled": "Du musst mindestens eine Zeile übrig lassen",
  "included": "inklusive",
  "member-plural": "Mitglieder",
  "member-singular": "Mitglied",
  "discount-x-off": "(du sparst {0}%)",
  "auto-repeat": "auto",
  "preis": "Preis:",
  "unverified": "nicht verifiziert",
  "want-to-share-templates-with-your-sub-space": "Willst du Templates mit deinem Sub-Space teilen?",
  "used-slots": "belegte Slots",
  "agencies": "Agenturen",
  "admin_agency": "Agenturen",
  "agency": "Agentur",
  "target": "Ziel",
  "customer": "Kunde",
  "commission-total": "Summe Kommission",
  "transactions": "Transaktionen",
  "commission": "Brutto-Kommission",
  "invoice-amount": "Rechnungsbetrag",
  "refunded": "Erstattet",
  "payout-instructions": "Auszahlungs-Informationen",
  "pending": "ausstehend",
  "reject": "Ablehnen",
  "rejected": "Abgelehnt",
  "request-payout": "Auszahlung beantragen",
  "requested": "Beantragt",
  "select-a-space": "Space auswählen",
  "the-minimum-payout-amount-is": "Der Mindestbetrag für Auszahlungen ist",
  "to-get-started": "um loszulegen",
  "your-agency": "Deine Agentur",
  "balance": "Saldo",
  "connections": "Verbindungen",
  "paid-out": "Ausgezahlt",
  "payouts": "Auszahlungen",
  "you-already-have-a-pending-payout": "Du hast bereits eine ausstehende Auszahlung",
  "expired": "abgelaufen",
  "add": "Hinzufügen",
  "click-on-one-or-more-templates-to-change-their-visibility-and-to-share-them-with-sub-space": "Klicke auf ein oder mehrere Templates um ihre Sichtbarkeit in der Galerie zu ändern oder sie mit Sub-Spaces zu teilen",
  "controls-visibility-in-this-space-sub-spaces-can-manage-visibility-independently": "Kontrolliert die Sichtbarkeit in der Galerie dieses Spaces. Sub-Spaces können Sichtbarkeit selber einstellen.",
  "share-templates-with-your-sub-spaces-hover-a-template-to-see-all-subspaces-that-have-access-to-it": "Teile Templates mit deinen Sub-Spaces. Halte deine Maus über ein Template um zu sehen, wer Zugriff darauf hat.",
  "visibility": "Sichtbarkeit",
  "closable": "Schließbar",
  "markdown": "Markdown",
  "remember-closed": "nicht wieder anzeigen",
  "supports": "unterstützt",
  "banner": "Banner",
  "preview-banner": "Banner Vorschau",
  "collect-emails": "Emails sammeln",
  "download-csv": ".csv herunterladen",
  "num-emails-collected": "{0} emails gesammelt",
  "unlimited": "Unbegrenzt",
  "APPSUMO_API": "AppSumo API Credits",
  "APPSUMO_EMBED": "AppSumo EMBED Credits",
  "APPSUMO_SOCIAL": "AppSumo SOCIAL Credits",
  "DAILY": "Täglich",
  "MONTHLY": "Monatlich",
  "WEEKLY": "Wöchentlich",
  "YEARLY": "Jährlich",
  "no-credits": "Deine Credits sind aufgebraucht"
}
