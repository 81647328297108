import { BaseController } from "./baseController";
import axios from "axios";
import { File } from "@bluepic/types/src/StudioResources/file";

const baseUrl = import.meta.env.V_STUDIO_RESOURCES_BASE_URL + "/api";

const c = new BaseController(baseUrl);

export type ListFilesOptions = {
  type?: "^application/json\\+bx$";
  mode?: "read" | "write";
  teams?: string[];
  meta?: Record<string, string>;
  published?: boolean;
  latest?: boolean;
  start?: number;
  limit?: number;
  abortController?: AbortController;
};

export async function listFiles(options: ListFilesOptions, jwt?: string, apikey?: string) {
  const query = Object.fromEntries(
    Object.entries({
      type: options.type ?? "^application/json\\+bx$",
      mode: options.mode ?? "read",
      teams: options.teams?.join(","),
      ...(typeof options.meta === "object"
        ? Object.entries(options.meta).reduce((acc, cur) => {
            const [k, v] = cur;
            return {
              ...acc,
              ["meta." + encodeURIComponent(k)]: v,
            };
          }, {})
        : {}),
      published: options.published ?? true,
      latest: options.latest ?? true,
      start: options.start ?? 0,
      limit: options.limit,
    }).filter(([, value]) => value !== undefined)
  );
  const url = c.getUrl("files") + "?" + c.query(query);
  return (await c.handleAxiosError(() =>
    axios.get(url.toString(), {
      headers: {
        ...c.authHeader(jwt, apikey),
      },
      signal: options.abortController?.signal,
    })
  )) as {
    start: number;
    limit: number;
    total: number;
    files: File[];
  };
}

export async function getFile(id: string, jwt?: string) {
  const query = {
    meta: true,
  };
  const url = c.getUrl("files", id) + "?" + c.query(query);
  return (await c.handleAxiosError(() =>
    axios.get(url.toString(), {
      headers: {
        ...c.authHeader(jwt),
      },
    })
  )) as File;
}

export type FilePermissionOptions = {
  fileId: string;
  teamId: string;
  mode?: "read" | "write";
};

// PUT /files/:file/teams/:team/:mode
export async function addTeamToFile(options: FilePermissionOptions, jwt?: string, apikey?: string) {
  const url = c.getUrl("files", options.fileId, "teams", options.teamId, options.mode ?? "read");
  return await c.handleAxiosError(() =>
    axios.put(url.toString(), null, {
      headers: {
        ...c.authHeader(jwt, apikey),
      },
    })
  );
}

// DELETE /files/:file/teams/:team/:mode
export async function removeTeamFromFile(options: FilePermissionOptions, jwt?: string, apikey?: string) {
  const url = c.getUrl("files", options.fileId, "teams", options.teamId, options.mode ?? "read");
  return await c.handleAxiosError(() =>
    axios.delete(url.toString(), {
      headers: {
        ...c.authHeader(jwt, apikey),
      },
    })
  );
}
