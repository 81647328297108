import { MonitoringBuffer } from './types';
import { compressAsync } from 'lzutf8';

export function processBuffer(buffer: MonitoringBuffer) {
  // const messagesBySource = new Map<string, number>();
  // buffer.esMessages.forEach((msg) => {
  //   const { origin } = msg;
  //   const current = messagesBySource.get(origin) || 0;
  //   messagesBySource.set(origin, current + 1);
  // });
  // const timeSinceLastFlush = Date.now() - buffer.lastFlush.getTime();
  // const mps = Array.from(messagesBySource.entries()).map(([origin, count]) => {
  //   return {
  //     origin,
  //     mps: count / (timeSinceLastFlush / 1000),
  //   };
  // });
  return {
    http: buffer.http,
    img: buffer.img,
    // mps,
  };
}

export function compress(data: any) {
  const serialized = JSON.stringify(data);
  return new Promise<string>((resolve, reject) => {
    compressAsync(serialized, { outputEncoding: 'StorageBinaryString' }, (result, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
    });
  });
}

export async function submit(payload: string) {
  const authStore = useAuthStore();
  if (!authStore.jwt) throw new Error('No JWT');
  const ua = navigator.userAgent;
  const url = import.meta.env.V_FRONTEND_MONITOR_BASE_URL + '/log/submit';
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      Authorization: `Bearer ${authStore.jwt}`,
      'User-Agent': ua,
    },
    body: payload,
  });
}
