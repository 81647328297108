//convert file to blob
export function fileToBlob(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    file.arrayBuffer().then((arrayBuffer) => {
      const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
      resolve(blob);
    });
  });
}

export function getExt(type: string) {
  const mappedType = new Map([
    ['application/zip', 'zip'],
    ['application/x-zip-compressed', 'zip'],
    ['application/pdf', 'pdf'],
    ['image/jpeg', 'jpg'],
    ['image/jpg', 'jpg'],
    ['image/png', 'png'],
    ['image/svg+xml', 'svg'],
  ]).get(type);
  if (mappedType) {
    return mappedType;
  } else {
    const [, , format] = type.match(/(.*)\/(.*)/) ?? [];
    return format;
  }
}

export function blobToBase64(blob: Blob) {
  return new Promise<string>(function (resolve, reject) {
    const reader = new FileReader();
    reader.addEventListener('load', (event: Event) => {
      resolve((event.target as any).result);
    });
    reader.readAsDataURL(blob);
  });
}

export async function hashBlob(blob: Blob) {
  const buffer = await crypto.subtle.digest('SHA-256', await blob.arrayBuffer());
  return new Uint8Array(buffer).reduce((acc, n) => acc + n.toString(36), '');
}

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}
