<script setup lang="ts">
interface Props {
  direction?: 'horizontal' | 'vertical';
  reverse?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  direction: 'vertical',
});
const { direction, reverse } = toRefs(props);
// stolen from https://github.com/tusen-ai/naive-ui/blob/35a87cbe027f4277ce171b81957460cf1d6c8ff9/src/_internal/fade-in-expand-transition/src/FadeInExpandTransition.ts
function handleBeforeLeave(el: HTMLElement): void {
  if (direction.value === 'horizontal') {
    el.style.maxWidth = `${el.offsetWidth}px`;
  } else {
    el.style.maxHeight = `${el.offsetHeight}px`;
  }
}
function handleLeave(el: HTMLElement): void {
  if (direction.value === 'horizontal') {
    el.style.maxWidth = '0';
    if (reverse?.value) {
      el.style.transform = 'translateX(100%)';
    } else {
      el.style.transform = 'translateX(-100%)';
    }
  } else {
    el.style.maxHeight = '0';
    if (reverse?.value) {
      el.style.transform = 'translateY(100%)';
    } else {
      el.style.transform = 'translateY(-100%)';
    }
  }
}
function handleAfterLeave(el: HTMLElement): void {
  if (direction.value === 'horizontal') {
    el.style.maxWidth = '';
  } else {
    el.style.maxHeight = '';
  }
}
const handleEnter = (el: HTMLElement) => {
  if (direction.value === 'horizontal') {
    const memorizedWidth = el.offsetWidth;
    el.style.maxWidth = '0';
    void el.offsetWidth;
    el.style.maxWidth = `${memorizedWidth}px`;
  } else {
    const memorizedHeight = el.offsetHeight;
    el.style.maxHeight = '0';
    void el.offsetHeight;
    el.style.maxHeight = `${memorizedHeight}px`;
  }
};
function handleAfterEnter(el: HTMLElement): void {
  if (direction.value === 'horizontal') {
    el.style.maxWidth = '';
  } else {
    el.style.maxHeight = '';
  }
}
</script>

<template>
  <transition
    :name="direction"
    @enter="() => handleEnter"
    @after-enter="() => handleAfterEnter"
    @before-leave="() => handleBeforeLeave"
    @leave="() => handleLeave"
    @after-leave="() => handleAfterLeave"
  >
    <slot></slot>
  </transition>
</template>

<style scoped>
.vertical-enter-active,
.vertical-leave-active {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  transition: opacity var(--t-dur) ease-in-out, transform var(--t-dur) ease-in-out, max-height var(--t-dur) ease-in-out,
    max-width var(--t-dur) ease-in-out;
}

.vertical-enter-from,
.vertical-leave-to {
  opacity: 0;
}
</style>
