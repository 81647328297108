import { BaseController } from "./baseController";

export interface LogDTO {
  service: string;
  env: string;
  action: string;
  data?: any;
}

const baseUrl = import.meta.env.V_ANALYTICS_BASE_URL;

const c = new BaseController(baseUrl);

// POST /api/{type}
export async function analytics(type: string, data: LogDTO, jwt?: string, apikey?: string) {
  const url = c.getUrl(type);
  return (await c.handleAxiosError(
    () =>
      axios.post(url, data, {
        headers: c.authHeader(jwt, apikey),
      }),
    false
  )) as string;
}
