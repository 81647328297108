<script setup lang="ts">
import { TeamOutput } from '@bluepic/types/src/Auth/team.output';
interface Props {
  teams: TeamOutput[];
  active?: string;
  editable?: boolean;
  hoverable?: boolean;
}
const props = defineProps<Props>();
const { teams, active, editable, hoverable } = toRefs(props);
const emit = defineEmits(['select', 'edit']);
</script>

<template>
  <div class="team-list">
    <team-list-item
      v-for="team in teams"
      :key="team.id"
      :team="team"
      :active="active === team.id"
      @select="(team) => emit('select', team)"
      @edit="(team) => emit('edit', team)"
      :editable="editable"
      :hoverable="hoverable"
    />
  </div>
</template>

<style lang="scss" scoped>
.team-list {
  @apply w-full flex flex-col gap-2;
}
</style>
