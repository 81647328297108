<script setup lang="ts">
import { OverlayParams } from '../../../composables/useOverlay';
const params = reactive<OverlayParams>({});
provide('b-overlay-params', params);
function close(e: { clientX: number; clientY: number }) {
  params.origin = {
    x: e.clientX,
    y: e.clientY,
  };
  params.onClose?.();
  params.show = false;
}
const router = useRouter();
router.beforeEach((to, from, next) => {
  if (params.show) {
    close({
      clientX: params.origin!.x,
      clientY: params.origin!.y,
    });
    next(false);
  } else {
    next();
  }
});
</script>

<template>
  <div class="overlay-provider">
    <slot />
    <div
      class="overlay-wrapper"
      :class="{ _hidden: !params.show }"
      :style="{
        '--origin-x': params.origin ? params.origin.x + 'px' : undefined,
        '--origin-y': params.origin ? params.origin.y + 'px' : undefined,
      }"
    >
      <div class="background-wrapper">
        <div class="background" @click="close" />
      </div>
      <div
        class="foreground"
        style="padding-top: var(--safe-area-inset-top, 0); padding-bottom: var(--safe-area-inset-bottom, 0)"
      >
        <header v-if="params.title || params.closable">
          <div v-if="params.title" class="title-wrapper">
            <h1 v-if="typeof params.title === 'string'" v-text="params.title" />
            <component v-else :is="params.title" />
          </div>
          <div class="close-wrapper">
            <b-btn-close v-if="params.closable" @click="close" size="2rem" />
          </div>
        </header>
        <main>
          <div v-if="params.content" class="content-wrapper" :class="params.centerContent ? 'grid place-content-center' : ''">
            <span v-if="typeof params.content === 'string'" v-text="params.title" />
            <component v-else :is="params.content" />
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.overlay-provider {
  @apply relative w-full h-full;
  .overlay-wrapper {
    @apply fixed inset-0 z-[10];
    &._hidden {
      @apply pointer-events-none;
      > .background-wrapper {
        > .background {
          @apply opacity-100 scale-0;
        }
      }
      > .foreground {
        padding-top: var(--safe-area-inset-top, 0);
        @apply opacity-0;
        > header {
          @apply pointer-events-none;
        }
        > main {
          > .content-wrapper {
            @apply flex-grow pointer-events-none;
          }
        }
      }
    }
    > .background-wrapper {
      @apply absolute;
      transform: translateX(var(--origin-x, 0)) translateY(var(--origin-y, 0));
      > .background {
        @apply absolute
            w-[300vmax]
            h-[300vmax]
            -translate-x-1/2
            -translate-y-1/2
            overflow-hidden
            bg-black 
            bg-opacity-70
            backdrop-blur-lg
            rounded-full;
        transition: transform ease;
        transition-duration: var(--t-dur);
      }
    }
    > .foreground {
      @apply absolute inset-0 flex flex-col z-[1];
      transition: opacity var(--t-dur) ease;
      > header {
        @apply flex items-center p-4;
        .title-wrapper {
          @apply flex;
        }
        .close-wrapper {
          @apply flex-grow flex justify-end;
        }
      }
      > main {
        @apply flex-grow flex flex-col;
        .content-wrapper {
          @apply flex-grow;
        }
      }
    }
  }
}
</style>
