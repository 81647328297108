<script setup lang="ts">
import { ClipboardOutline } from '@vicons/ionicons5';
interface Props {
  value?: string;
  secret?: boolean;
  plain?: boolean;
  noText?: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(['update:value']);
const { value, secret, plain, noText } = toRefs(props);
const copied = ref(false);
const clipboardSupported = computed(() => {
  return navigator.clipboard !== undefined;
});
function copyToClipboard(value?: string) {
  if (!value) return;
  navigator.clipboard?.writeText(value).then(() => {
    copied.value = true;
    setTimeout(() => {
      copied.value = false;
    }, 2000);
  });
}
</script>

<template>
  <div v-if="plain" class="b-clipboard plain">
    <n-popover v-if="clipboardSupported" trigger="manual" :show="copied">
      <template #trigger>
        <div class="flex items-center gap-2 cursor-pointer" @click="() => copyToClipboard(value)">
          <slot />
          <p v-if="!noText" class="text">
            {{ value }}
          </p>
          <n-icon size="large" class="copy-icon">
            <clipboard-outline />
          </n-icon>
        </div>
      </template>
      <span>{{ $t('copied-to-clipboard') }}</span>
    </n-popover>
  </div>
  <n-input-group v-else class="b-clipboard">
    <slot />
    <n-input :value="value" @update:value="emit('update:value', $event)" readonly :type="secret ? 'password' : 'text'" round>
    </n-input>
    <n-popover v-if="clipboardSupported" trigger="manual" :show="copied">
      <template #trigger>
        <n-button type="primary" ghost round @click="() => copyToClipboard(value)">
          {{ $t('copy') }}
        </n-button>
      </template>
      <span>{{ $t('copied-to-clipboard') }}</span>
    </n-popover>
  </n-input-group>
</template>

<style scoped>
.b-clipboard {
  &.plain {
    .text {
      @apply text-ellipsis whitespace-nowrap overflow-hidden;
    }
  }
}
</style>
