<script setup lang="ts">
import { NEmpty } from 'naive-ui';
const notificationStore = useNotificationStore();
const notifications = computed(() =>
  notificationStore.notifications
    .filter((n) => !notificationStore.deleted.includes(n.id))
    .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
);

const _today = new Date();
_today.setHours(0, 0, 0, 0);
const _last7Days = new Date(_today.getTime() - 1000 * 60 * 60 * 24 * 7);
const _last30Days = new Date(_today.getTime() - 1000 * 60 * 60 * 24 * 30);

const today = computed(() => {
  return notifications.value.filter((n) => n.timestamp.getTime() >= _today.getTime());
});

const last7Days = computed(() => {
  return notifications.value.filter(
    (n) => n.timestamp.getTime() >= _last7Days.getTime() && n.timestamp.getTime() < _today.getTime()
  );
});

const last30Days = computed(() => {
  return notifications.value.filter(
    (n) => n.timestamp.getTime() >= _last30Days.getTime() && n.timestamp.getTime() < _last7Days.getTime()
  );
});

const onScroll = throttle((e: WheelEvent) => {
  fetchMore();
}, 100);
const containerRef = ref<HTMLElement | null>(null);
function fetchMore() {
  if (!containerRef.value) return;
  const container = containerRef.value;
  if (container.scrollHeight - container.scrollTop - container.clientHeight < container.clientHeight / 3) {
    notificationStore.fetchMoreNotifications();
  }
}

const unread = computed(() => notifications.value.filter((n) => !n.read));
const authStore = useAuthStore();
watchEffect(() => {
  if (authStore?.jwt && unread.value.length) {
    _markAsRead();
  }
});
function _markAsRead() {
  if (!authStore.jwt) throw new Error('No JWT');
  if (!unread.value.length) return;
  markAsRead(
    unread.value.map((n) => n.id),
    authStore.jwt
  ).catch(() => {
    console.error('Failed to mark notifications as read');
  });
}
</script>

<template>
  <div
    class="absolute inset-0 p-4 pt-0 flex flex-col gap-8 overflow-auto overflow-x-hidden pointer-events-auto"
    ref="containerRef"
    @wheel.passive="onScroll"
  >
    <div v-if="today.length" class="flex flex-col gap-2">
      <h2>{{ $t('today') }}</h2>
      <TransitionGroup tag="ul" name="fade" class="relative">
        <li class="inset-x-0" v-for="n in today" :key="n.id">
          <notification :notification="n" />
        </li>
      </TransitionGroup>
    </div>
    <div v-if="last7Days.length" class="flex flex-col gap-2">
      <h2>{{ $t('last-7-days') }}</h2>
      <TransitionGroup tag="ul" name="fade" class="relative">
        <li class="inset-x-0" v-for="n in last7Days" :key="n.id">
          <notification :notification="n" />
        </li>
      </TransitionGroup>
    </div>
    <div v-if="last30Days.length" class="flex flex-col gap-2">
      <h2>{{ $t('last-30-days') }}</h2>
      <TransitionGroup tag="ul" name="fade" class="relative">
        <li class="inset-x-0" v-for="n in last30Days" :key="n.id">
          <notification :notification="n" />
        </li>
      </TransitionGroup>
    </div>
    <div v-if="!today.length && !last7Days.length && !last30Days.length">
      <n-empty>
        {{ $t('theres-nothing-here') }}
      </n-empty>
    </div>
  </div>
</template>

<style scoped lang="scss">
.fade-move,
.fade-enter-active,
.fade-leave-active {
  @apply transition-all;
  transition-duration: var(--t-dur);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-3rem);
}

.fade-leave-active {
  position: absolute;
}
</style>
