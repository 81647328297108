import { reactive } from 'vue';

export default function(permanentUpdate = false) {
  const viewbox = reactive({ width: 0, height: 0 });
  const update = () => {
    if(viewbox.width !== window.innerWidth) {
      viewbox.width = window.innerWidth;
    }
    if(viewbox.height !== window.innerHeight) {
      viewbox.height = window.innerHeight;
    }
    if (permanentUpdate) {
      window.requestAnimationFrame(update);
    }
  };
  if(!permanentUpdate) {
    window.addEventListener('resize', update);
  }
  update();
  return { viewbox };
}