<script setup lang="ts">
import { logout } from '../../util/logout';
import { ExitOutline, ArrowRedoOutline } from '@vicons/ionicons5';
import ColorPaletteIcon from '../../assets/icons/color-palette-outline.svg';
import SettingsIcon from '../../assets/icons/settings-outline.svg';
import HomeIcon from '../../assets/icons/home-outline.svg';
import { Ref, VNode } from 'vue';
import { NAvatar, NTooltip } from 'naive-ui';
import { assertSingleVal } from '../../util/arrays';
import { BannerData } from '../banner/provider.vue';
interface Props {
  show?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  show: true,
});
const { show } = toRefs(props);
const router = useRouter();
const currentRoute = useRoute();
const root = computed(() => {
  return '/' + currentRoute.path.split('/')[1];
});
const activeTab = ref(root.value);
watch(
  currentRoute,
  (val) => {
    activeTab.value = root.value;
  },
  { immediate: true }
);
function select(tab: string) {
  router.push({
    path: tab,
    force: true,
  });
}
const device = useDevice();
const showTeamSwitcher = ref(false);
provide('showTeamSwitcher', showTeamSwitcher);
const authStore = useAuthStore();
interface NavItem {
  key: string;
  label: string;
  icon: () => VNode;
  callback?: () => void;
}
const teamStore = useTeamStore();
const currentTeam = computed(() => {
  return teamStore.currentTeamId;
});
const banner = inject<Ref<BannerData | null>>('banner-data');
if (!banner) throw new Error('no banner provider');
watchEffect(() => {
  if (!authStore.user?.shared || !currentTeam.value || !authStore.jwt) return;
  getMetadata({ key: 'shared_banner', teamId: currentTeam.value }, authStore.jwt).then((res) => {
    if (!res) return;
    const bannerData = JSON.parse(assertSingleVal(res).value);
    banner.value = bannerData;
  });
});
const sessionStore = useSessionStore();
const confirm = useConfirmDialog();
function doLogout() {
  if (authStore.user?.shared) {
    confirm({
      title: t('log-out'),
      content: t('confirm-logout'),
      yesText: t('im-sure'),
      callback: () => {
        if (sessionStore.currentSessionId && authStore.jwt) {
          console.log('killing session');
          killSession(sessionStore.currentSessionId, authStore.jwt)
            .then(() => {
              console.log('session killed');
            })
            .catch(() => {
              console.log('failed to kill session');
            });
        }
        const path = `/shared/${authStore.user?.id}?logout`;
        logout(true);
        router.push(path);
      },
      noText: t('cancel'),
    });
  }
}
const navItems = computed<NavItem[]>(() => {
  return [
    {
      key: '/gallery',
      label: t('gallery'),
      icon: () => h(ColorPaletteIcon),
      callback: () => select('/gallery'),
    },
    !authStore.user?.dummy && {
      key: '/hub',
      label: t('hub'),
      icon: () => h(HomeIcon),
      callback: () => select('/hub'),
    },
    {
      key: '/settings',
      label: t('settings'),
      icon: () => h(SettingsIcon),
      callback: () => select('/settings'),
    },
    authStore.user?.dummy && {
      key: '/logout',
      label: t('logout'),
      icon: () => h(ExitOutline),
      callback: () => doLogout(),
    },
  ].filter(Boolean) as NavItem[];
});
const itemRefs = ref<HTMLElement[]>([]);
const activeItem = computed(() => {
  return navItems.value.findIndex((i) => i.key === activeTab.value);
});
const activeRef = computed(() => {
  return itemRefs.value[activeItem.value];
});
const containerRef = ref<HTMLElement>();
function openIdPortal() {
  window.open(import.meta.env.V_ID_BASE_URL, '_blank');
}
const showNotifications = ref(false);
</script>

<template>
  <div
    class="absolute inset-0 flex justify-center overflow-hidden z-[1]"
    :class="{
      'flex-col-reverse': device === 'mobile',
    }"
  >
    <t-collapse
      class="z-[3]"
      style="background-color: var(--background1)"
      :reverse="device === 'mobile'"
      :direction="device === 'mobile' ? 'vertical' : 'horizontal'"
    >
      <div
        v-if="show"
        :style="{
          borderStyle: 'solid',
          borderColor: 'var(--borders)',
          borderRightWidth: device === 'mobile' ? '0px' : '1px',
          borderTopWidth: device === 'mobile' ? '1px' : '0px',
        }"
        class="flex"
        :class="{
          'flex-row-reverse': device === 'mobile',
          'flex-col': device !== 'mobile',
        }"
      >
        <team-switcher
          v-model:show="showTeamSwitcher"
          class="p-1 my-2"
          :class="{
            'mx-2': device !== 'tablet',
          }"
          :style="{
            backgroundColor: device === 'desktop' || device === 'ultrawide' ? 'var(--primary-doublehover)' : undefined,
            borderRadius: 'var(--roundness)',
          }"
        />
        <div
          class="flex flex-grow"
          :class="{
            'flex-col-reverse': device === 'mobile',
          }"
        >
          <div
            class="flex flex-grow"
            ref="containerRef"
            :class="{
              'flex-col': device !== 'mobile',
            }"
          >
            <!-- items -->
            <div
              ref="itemRefs"
              v-for="(item, i) in navItems"
              :class="{
                'flex-grow': device === 'mobile',
                'flex-1': device === 'mobile',
              }"
            >
              <div
                class="p-1 m-1 flex items-center gap-1 cursor-pointer box-border"
                :class="{
                  'flex-col': device === 'mobile',
                  'justify-center': device === 'mobile',
                  'px-3': device === 'tablet',
                }"
                :style="{
                  color: activeItem === i ? 'var(--primary)' : 'var(--text-hover)',
                  backgroundColor: activeItem === i ? 'var(--primary-doublehover)' : undefined,
                  borderRadius: 'var(--roundness)',
                }"
                @click="() => item.callback?.()"
              >
                <n-icon size="1.5rem">
                  <component :is="item.icon()" />
                </n-icon>
                <span v-if="device !== 'tablet'">
                  {{ item.label }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="relative overflow-hidden"
            :style="{
              width: device !== 'mobile' ? '2px' : 'inherit',
              height: device !== 'mobile' ? 'inherit' : '2px',
            }"
          >
            <!-- pointer -->
            <div
              class="absolute transition-all"
              :style="{
                willChange: 'width, height, left, top',
                transitionDuration: 'var(--t-dur)',
                width: device === 'mobile' ? activeRef?.clientWidth + 'px' : 'inherit',
                height: device === 'mobile' ? 'inherit' : activeRef?.clientHeight + 'px',
                left: device === 'mobile' ? activeRef?.offsetLeft - (containerRef?.offsetLeft ?? 0) + 'px' : 'inherit',
                top: device === 'mobile' ? 'inherit' : activeRef?.offsetTop - (containerRef?.offsetTop ?? 0) + 'px',
                backgroundColor: 'var(--primary)',
              }"
            />
          </div>
        </div>
        <div
          v-if="device !== 'mobile' && !authStore.user?.shared"
          class="p-1 m-1 flex items-center gap-1 cursor-pointer box-border"
          :class="{
            'px-3': device === 'tablet',
          }"
          :style="{
            color: 'var(--text-hover)',
            borderRadius: 'var(--roundness)',
          }"
          @click="showNotifications = true"
        >
          <n-tooltip :disabled="device !== 'tablet'">
            <template #trigger>
              <notification-button data-onboarding="notifications-button" icon v-model:show="showNotifications" />
            </template>
            <span>
              {{ $t('notifications') }}
            </span>
          </n-tooltip>
          <span v-if="device !== 'tablet'">
            {{ $t('notifications') }}
          </span>
        </div>
        <div
          v-if="device !== 'mobile' && !authStore.user?.shared"
          class="mb-4 p-1 m-1 flex items-center gap-1 cursor-pointer box-border"
          :class="{
            'px-3': device === 'tablet',
          }"
          :style="{
            color: 'var(--text-hover)',
            borderRadius: 'var(--roundness)',
          }"
          @click="openIdPortal"
        >
          <n-tooltip :disabled="device !== 'tablet'">
            <template #trigger>
              <n-icon size="1.5rem">
                <arrow-redo-outline />
              </n-icon>
            </template>
            <span>
              {{ $t('id') }}
            </span>
          </n-tooltip>
          <span v-if="device !== 'tablet'">
            {{ $t('id') }}
          </span>
        </div>
      </div>
    </t-collapse>
    <div class="relative flex-grow">
      <slot></slot>
    </div>
  </div>
</template>
