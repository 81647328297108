import { AxiosError, AxiosResponse } from "axios";

export class BaseController {
  protected baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }
  getUrl(...path: (string | undefined | null)[]) {
    return this.baseUrl + "/" + path.join("/");
  }
  authHeader(jwt?: string, apikey?: string) {
    const prefix = jwt ? "bearer" : apikey ? "apikey" : null;
    if (!prefix) return;
    return {
      Authorization: `${prefix} ${jwt ? jwt : apikey}`,
    };
  }
  query(params: any) {
    if (!params) return "";
    const result: string[] = [];
    for (const k in params) {
      const key = encodeURIComponent(k);
      const rawValue = params[k];
      if (Array.isArray(rawValue)) {
        for (const i of rawValue) {
          if (i === undefined) continue;
          result.push(`${key}=${encodeURIComponent(i)}`);
        }
      } else if (rawValue !== undefined) {
        result.push(`${key}=${encodeURIComponent(rawValue)}`);
      }
    }
    return result.join("&");
  }
  async handleAxiosError<T extends AxiosResponse>(func: () => Promise<T>, throwOnError = true): Promise<any> {
    try {
      const response = await func();
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (e: any) {
      if (!throwOnError) return;
      e = e as AxiosError;
      if (e.response) {
        throw new Error(e.response.data.message);
      }
    }
  }
}
