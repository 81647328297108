import { onLogout } from '../util/logout';
import { FeedEventOutput } from '@bluepic/types/src/Feed/FeedEvent.output';
import { UseBroadcastChannelReturn } from '@vueuse/core';
const baseUrl = import.meta.env.V_FEED_BASE_URL;
const _services: string[] = [];
const _resources: string[] = [];
const subscribers = new Map<number, (event: FeedEventOutput) => void>();
let _jwt: string;

let publisher = true;
let es: EventSource | null = null;
let bc: UseBroadcastChannelReturn<unknown, unknown>;

let BroadcastInitialised = false;
function initBroadcast() {
  if(BroadcastInitialised) return;
  bc = useBroadcastChannel({ name: 'bluepic-feed' });
  if(bc.isSupported.value && _jwt) {
    watch(bc.data, (v) => {
      if(publisher) {
        if(typeof v === 'string') {
          if(v === 'hello' && !bc.isClosed) {
            bc.post("it's me");
          } else if(v === "it's me") {
            console.log('other publisher found. Closing SSE connection');
            publisher = false;
            es?.close();
          } else if((v as string).startsWith('services: ')) {
            const services = (v as string).replace('services: ', '').split(',');
            const newServices = services.filter((s) => !_services.includes(s));
            _services.push(...newServices);
            initSSE();
          } else if((v as string).startsWith('resources: ')) {
            const resources = (v as string).replace('resources: ', '').split(',');
            const newResources = resources.filter((r) => !_resources.includes(r));
            _resources.push(...newResources);
            initSSE();
          }
        }
      } else if(v === "it's not me anymore" && !bc.isClosed) {
        console.log('current publisher closed. Reopening SSE connection');
        bc.post("it's me");
        publisher = true;
        initSSE();
      } else {
        for(const [, handler] of subscribers) {
          handler(bc.data.value as FeedEventOutput);
        }
      }
    });
    if(!bc.isClosed) {
      bc.post('hello');
    }
    watch(bc.error, (error) => {
      // console.error('broadcast-error', error);
    });
    BroadcastInitialised = true;
  }
}

export function refreshFeed() {
  if(publisher) {
    initSSE();
  }
}

function initSSE() {
  es?.close();
  const url = new URL(baseUrl);
  url.searchParams.append('jwt', _jwt);
  for(const service of _services) {
    url.searchParams.append('service', service);
  }
  for(const resource of _resources) {
    url.searchParams.append('resource', resource);
  }
  es = new EventSource(url.toString());
  es.onmessage = (e) => {
    // console.log(e); 
    for(const [, handler] of subscribers) {
      handler(JSON.parse(e.data));
    }
    if(!bc.isClosed) {
      bc.post(JSON.parse(e.data));
    }
  };
  es.onerror = (e) => {
    // console.error('sse-error', e);
  }
}

window.addEventListener("beforeunload", function (e) {
  if(publisher) {
    if(!bc.isClosed) {
      bc.post("it's not me anymore");
    }
  }
});

onLogout(() => {
  close();
});

function close() {
  es?.close();
  bc.close();
  BroadcastInitialised = false;
}

export default function(
    jwt: string, 
    services: string[], 
    resources: string[], 
    handler: (event: FeedEventOutput) => void
  ) {
  _jwt = jwt;
  initBroadcast();
  const newServices = services.filter((s) => !_services.includes(s));
  const newResources = resources.filter((r) => !_resources.includes(r));
  _services.push(...newServices);
  _resources.push(...newResources);
  if(publisher) {
    initSSE();
  } else if (!bc.isClosed) {
    bc.post(`services: ${_services.join(', ')}`);
    bc.post(`resources: ${_resources.join(', ')}`);
  }
  const unsubscribe = subscribe((event: FeedEventOutput) => {
    try {
      handler(event);
    } catch(e) {
      console.error(e);
    }
  });
  return unsubscribe;
}

export function registerOperationCallback(
  operationId: string, 
  handler: (event?: FeedEventOutput) => void,
  timeout?: number,
  executeOnTimeout = false
) {
  let timer: NodeJS.Timeout;
  const unsubscribe = subscribe((event: FeedEventOutput) => {
    if(event.operationId === operationId) {
      clearTimeout(timer);
      try {
        handler(event);
      } catch(e) {
        console.error(e);
      }
    }
  });
  if(timeout) {
    timer = setTimeout(() => {
      if(executeOnTimeout) {
        handler();
      }
      unsubscribe();
    }, timeout);
  }
  return unsubscribe;
}

let i = 0;
function subscribe(handler: (event: FeedEventOutput) => void) {
  subscribers.set(i, handler);
  i++;
  return () => subscribers.delete(i);
}

onLogout(() => {
  close();
})