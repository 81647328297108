import { TeamOutput } from '@bluepic/types/src/Auth/team.output';
import { pinia } from '../stores/pinia';

const authStore = useAuthStore(pinia);
const teamStore = useTeamStore(pinia);

export function getRoot(team?: TeamOutput): TeamOutput | undefined {
  if (!team) return;
  if (!team.parentId) return team;
  return getRoot(teamStore.teams.find((t) => t.id === team.parentId));
}

export function isTeamAdmin(team: TeamOutput, userId?: string): boolean {
  if (!authStore.user) return false;
  if (team?.adminIds?.includes(userId ?? authStore.user.id)) {
    return true;
  }
  return false;
}

export function isTeamOwner(team: TeamOutput, userId?: string): boolean {
  if (!authStore.user) return false;
  if (team?.ownerId === userId ?? authStore.user.id) {
    return true;
  }
  return false;
}
