import { isEqual } from 'lodash';
import { onLogout } from '../util/logout';
import { pinia } from './pinia';
import { Template } from '../types/bluepic';

type TemplateStoreState = {
  fetching: boolean;
  teamId: string;
  templates: Map<string, Template>;
};

type TemplateStoreActions = {
  tryFetch: (force?: boolean, excludeDefault?: boolean) => Promise<void>;
  getTemplate: (id: string, refetch?: boolean) => Promise<Template | undefined>;
  watchCurrentTeam: () => void;
  initFeed: () => void;
};

let stopWatchingTeam: () => void;
let abortFetch: (reason?: string) => void;
let unsubscribeFromFeed: () => void;
export const useTemplateStore = defineStore<string, TemplateStoreState, {}, TemplateStoreActions>('template', {
  state: () => ({
    fetching: false,
    teamId: '',
    templates: new Map<string, Template>(),
  }),
  actions: {
    async tryFetch(force = false) {
      this.initFeed();
      // console.log('fetching templates');
      if (this.fetching) {
        if (!force) {
          // console.log('already fetching templates')
          return;
        }
        abortFetch?.('force fetching templates');
      }
      this.fetching = true;
      try {
        const authStore = useAuthStore();
        if (!authStore.jwt) {
          console.log('no jwt, not fetching templates');
          this.fetching = false;
          return;
        }
        const abortController = new AbortController();
        abortFetch = (reason?: string) => {
          abortController.abort(reason);
        };
        getTemplates(
          {
            teamIds: [this.teamId],
          },
          authStore.jwt
        )
          .then((res) => {
            this.templates.clear();
            if (!res?.templates) return;
            for (const template of res.templates) {
              this.templates.set(template.id, template);
            }
          })
          .finally(() => {
            this.fetching = false;
          });
      } catch (e) {
        console.error(e);
      }
    },
    async getTemplate(id: string, refetch = false) {
      // console.log("getting template", id);
      if (this.templates.has(id) && !refetch) {
        // console.log("template already in store");
        return this.templates.get(id);
      }
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      const template = await getTemplate(id, authStore.jwt);
      // console.log("got template", template?.name);
      if (template) {
        this.templates.set(template.id, template);
        // console.log("set template", template.name);
      } else {
        this.templates.delete(id);
        // console.log("deleting template");
      }
      return template ?? undefined;
    },
    watchCurrentTeam() {
      stopWatchingTeam?.();
      const authStore = useAuthStore();
      stopWatchingTeam = watch(
        authStore.$state,
        (nv) => {
          const templateStore = useTemplateStore();
          if (isEqual(nv.currentTeam, templateStore.teamId)) {
            return;
          }
          templateStore.teamId = nv.currentTeam;
          templateStore.tryFetch(true);
        },
        { immediate: true, deep: true }
      );
    },
    initFeed() {
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      unsubscribeFromFeed?.();
      unsubscribeFromFeed = useFeed(authStore.jwt, ['studioResources'], ['FILE'], (m) => {
        switch (m.service) {
          case 'studioResources':
            switch (m.operation) {
              case 'UPDATE_FILE_PERMISSIONS':
                console.log('UPDATE_FILE_PERMISSIONS', m);
                if (m.resourceId) {
                  this.getTemplate(m.resourceId, true);
                }
                break;
            }
            break;
        }
      });
    },
  },
});

onLogout(() => {
  useTemplateStore(pinia).$reset();
});
