import { createDiscreteApi, darkTheme } from 'naive-ui';
import { pinia } from '../stores/pinia';

const themeStore = useThemeStore(pinia);

export const { dialog, message } = createDiscreteApi(['dialog', 'message'], {
  configProviderProps: {
    theme: darkTheme,
    themeOverrides: themeStore.themeOverrides().value,
  },
});
