<script setup lang="ts">
import { oneWaySync } from '../../../util/reactivity';
import { NInput, NForm, NFormItem, NButton, FormRules, NCheckbox } from 'naive-ui';

interface Props {
  show?: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(['created', 'update:show']);
const { show } = toRefs(props);
const _show = ref(show.value ?? false);
oneWaySync(_show, show);
watch(
  _show,
  (val) => {
    emit('update:show', val);
  },
  { immediate: true }
);

const data = reactive({
  name: '',
  noSubscription: false,
});
const rules: FormRules = {
  name: {
    required: true,
    message: t('please-enter-a-space-name'),
  },
};

const creating = ref(false);
const authStore = useAuthStore();
const request = useSafeHTTP();
const message = useMessage();
function doCreateTeam() {
  if (!authStore.jwt) return;
  const pending = request(
    () =>
      createTeam(
        {
          name: data.name,
          parentId: undefined,
          noSubscription: data.noSubscription,
        },
        authStore.jwt!
      ),
    (res) => {
      // console.log(res);
      message.success(t('space-created'));
      emit('created', res.resourceId);
      _show.value = false;
    }
  );
  oneWaySync(creating, pending);
}
</script>

<template>
  <b-adaptive-modal :title="$t('create-space')" v-model:show="_show">
    <div class="popup">
      <n-form :model="data" :rules="rules">
        <n-form-item label="Space Name" path="name">
          <n-input v-model:value="data.name" placeholder="Space Name" />
        </n-form-item>
        <admin-only>
          <n-form-item :label="$t('subscription')" path="noSubscription">
            <n-checkbox v-model:checked="data.noSubscription">
              {{ $t('no-sub-needed') }}
            </n-checkbox>
          </n-form-item>
        </admin-only>
      </n-form>
      <n-button type="primary" ghost :loading="creating" :disabled="creating" @click="doCreateTeam">
        {{ $t('create-space') }}
      </n-button>
    </div>
  </b-adaptive-modal>
</template>

<style scoped lang="scss">
.popup {
  @apply flex flex-col p-4 justify-center gap-2 mb-10 w-[90vw] max-w-2xl m-auto;
}
</style>
