export function upsert<T>(arr: Array<T>, predicate: (element: T) => boolean, newval: T | Array<T>) {
  if (!Array.isArray(newval)) {
    newval = [newval];
  }
  for (const nv of newval) {
    let index = -1;
    let i = 0;
    for (const el of arr) {
      if (predicate(el)) {
        index = i;
        break;
      }
      i++;
    }
    if (index != -1) {
      arr.splice(index, 1, nv);
    } else {
      arr.push(nv);
    }
  }
}

export function remove<T>(arr: Array<T>, predicate: (element: T) => boolean) {
  let index = -1;
  let i = 0;
  for (const el of arr) {
    if (predicate(el)) {
      index = i;
      break;
    }
    i++;
  }
  if (index != -1) {
    arr.splice(index, 1);
  }
}

export function assertSingleVal<T>(value: T | Array<T>): T {
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
}

export function assertArray<T>(value: T | Array<T>): Array<T> {
  if (value === undefined || value === null) return [];
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
}

export async function allSettledWithProgress(promises: Array<Promise<any>>, onProgress: (progress: number) => void) {
  const wrapped: Promise<any>[] = [];
  let settled = 0;
  function settle() {
    settled++;
    onProgress(settled / promises.length);
  }
  for (const p of promises) {
    wrapped.push(
      new Promise((resolve, reject) => {
        p.then(resolve).catch(reject).finally(settle);
      })
    );
  }
  return await Promise.allSettled(wrapped);
}
