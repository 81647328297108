<script setup lang="ts">
import { TeamOutput } from '@bluepic/types/src/Auth/team.output';
import { oneWaySync } from '../../util/reactivity';
import { NIcon, NEllipsis, NEmpty, NButton } from 'naive-ui';
import { CloseCircleOutline, AddOutline } from '@vicons/ionicons5';
interface Props {
  hideAvatar?: boolean;
  show?: boolean;
}
const props = defineProps<Props>();
const { hideAvatar, show } = toRefs(props);
const emit = defineEmits(['update:show']);
const showSelectDrawer = ref(false);
if (show) oneWaySync(showSelectDrawer, show);
watch(showSelectDrawer, (val) => {
  emit('update:show', val);
});
const teamStore = useTeamStore();
teamStore.tryFetch();
const fetchedTeams = ref(false);
const fetching = computed(() => teamStore.fetching);
watch(
  fetching,
  (nv, ov) => {
    if (nv === false && ov === true) {
      fetchedTeams.value = true;
    }
  },
  { immediate: true }
);
const authStore = useAuthStore();
const currentTeam = computed(() => {
  if (!authStore.currentTeam) return null;
  return teamStore.teams.find((t) => t.id === authStore.currentTeam);
});
const closable = computed(() => {
  return authStore.hydrated && authStore.currentTeam;
});
const device = useDevice();
function switchTeam(id: string) {
  authStore.currentTeam = id;
  showSelectDrawer.value = false;
}
const router = useRouter();
function edit(team: TeamOutput) {
  showSelectDrawer.value = false;
  router.push('/settings/teams/' + team.id);
}
const allTeams = computed(() => {
  if (!authStore.user) return [];
  return [...teamStore.teams.values()].filter(
    (team) => team.adminIds?.includes(authStore.user!.id) || team.memberIds?.includes(authStore.user!.id)
  );
});
watchEffect(() => {
  if (authStore.hydrated && fetchedTeams.value && !currentTeam.value) {
    if (authStore.user?.dummy || allTeams.value.length === 1) {
      switchTeam(allTeams.value[0].id);
      showSelectDrawer.value = false;
      return;
    }
    showSelect();
  }
});
function showSelect() {
  const stopWatchingUser = watch(
    () => authStore.user,
    (nv) => {
      if (!nv) return;
      nextTick(() => {
        stopWatchingUser();
      });
      if (authStore.user?.dummy) return;
      showSelectDrawer.value = true;
    },
    { immediate: true }
  );
}
const showCreateModal = ref(false);
</script>

<template>
  <div>
    <div v-if="!hideAvatar" class="team-switcher" @click="showSelect">
      <div class="flex flex-col items-center">
        <div class="avatar-container">
          <profile-picture v-if="currentTeam" :teamid="currentTeam.id" thumbnail />
          <n-icon size="3rem" v-else>
            <close-circle-outline />
          </n-icon>
        </div>
        <div v-if="device !== 'mobile' && device !== 'tablet'">
          <div class="flex flex-col">
            <n-ellipsis class="team-name">
              {{ currentTeam?.name ?? authStore.user?.name }}
            </n-ellipsis>
          </div>
        </div>
      </div>
    </div>
    <b-adaptive-modal :notClosable="!closable" v-model:show="showSelectDrawer" :title="$t('select-profile')">
      <div class="popup">
        <div v-if="allTeams.length > 0" class="flex-grow p-4 flex flex-col gap-2 min-w-0">
          <team-list
            :teams="allTeams"
            :active="authStore.currentTeam"
            @select="(team: TeamOutput) => switchTeam(team.id)"
            @edit="edit"
            editable
            hoverable
          />
        </div>
        <n-empty v-else>
          <span>
            {{ $t('theres-nothing-here') }}
          </span>
        </n-empty>
        <div class="w-full flex justify-center">
          <n-button ghost type="primary" @click="showCreateModal = true">
            <template #icon>
              <n-icon>
                <add-outline />
              </n-icon>
            </template>
            {{ $t('create-space') }}
          </n-button>
        </div>
      </div>
    </b-adaptive-modal>
    <team-create-modal v-model:show="showCreateModal" @created="(id: string) => switchTeam(id)" />
  </div>
</template>

<style lang="scss" scoped>
.team-switcher {
  @apply flex flex-col justify-center items-center gap-2 cursor-pointer;
  .avatar-container {
    @apply relative h-12 w-12 rounded-full overflow-hidden;
    .forbidden-overlay {
      @apply absolute inset-0 bg-black bg-opacity-50 z-[1] grid place-content-center cursor-not-allowed;
    }
  }
  :deep() .team-name {
    @apply max-w-[7rem];
    > span {
      @apply text-sm;
      color: var(--text-hover);
    }
  }
}
.popup {
  @apply w-[90vw] max-w-2xl m-auto /*pb-32*/;
}
.none {
  &:hover {
    background-color: var(--foreground2-hover);
  }
  &.active {
    border-left: 4px solid var(--primary);
  }
  .no-icon {
    @apply w-16 h-16 grid place-items-center rounded-full;
    box-shadow: 0 0 10px -3px black;
  }
}
</style>
