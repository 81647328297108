import { NButton, NSpace } from "naive-ui";
import { dialog } from "../util/naiveUI";

export interface ConfirmOptions {
  title: string;
  content: any;
  callback: () => void;
  noCallback?: () => void;
  noText: string;
  yesText: string;
  type?: "info" | "success" | "warning" | "error";
}

export function useConfirmDialog() {
  return (options: ConfirmOptions) => {
    const d = dialog.create({
      type: options.type ?? "warning",
      title: options.title,
      content: options.content,
      onClose: () => {
        options.noCallback?.();
      },
      onMaskClick: () => {
        options.noCallback?.();
      },
      action: () =>
        h(NSpace, {}, () => [
          h(
            NButton,
            {
              ghost: true,
              size: "small",
              onClick: () => {
                options.noCallback?.();
                d.destroy();
              },
            },
            () => options.noText
          ),
          h(
            NButton,
            {
              ghost: true,
              size: "small",
              type: options.type ?? "warning",
              onClick: () => {
                d.destroy();
                options?.callback?.();
              },
            },
            () => options.yesText
          ),
        ]),
    });
  };
}
