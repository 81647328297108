<script setup lang="ts">
const show = ref(true);
</script>

<template>
  <div class="email-collector">
    <slot />
    <!-- <b-adaptive-modal v-model:show="show" title="Email Collector">
      <p>Subscribe to our newsletter</p>
    </b-adaptive-modal> -->
  </div>
</template>

<style scoped lang="scss">
.email-collector {
  @apply contents;
}
</style>
