import { randomString } from '.';
import { HTTPRequest } from './types';

declare global {
  interface XMLHttpRequest {
    _BX: HTTPRequest;
  }
}

export function initXHRListener(excludeUrl?: RegExp) {
  const listeners = new Map<string, (req: HTTPRequest) => void>();
  const proto = XMLHttpRequest.prototype;
  const origOpen = proto.open;
  const origSend = proto.send;
  proto.open = function (this: XMLHttpRequest, ...args: any[]) {
    const [method, url] = args;
    if (excludeUrl?.test(url)) {
      // @ts-ignore
      return origOpen.apply(this, args);
    }
    this._BX = {
      location: window.location.href,
      initiator: 'xhr',
      method,
      url,
    };
    this.addEventListener('readystatechange', function (this: XMLHttpRequest) {
      if (this.readyState === 4) {
        try {
          // throws on some platforms
          this._BX.status = this.status;
        } catch {}
        this._BX.endTime = Date.now();
        this._BX.resHeaders = {};
        this.getAllResponseHeaders()
          .split('\r\n')
          .forEach((header) => {
            const [key, value] = header.split(': ');
            if (key && value) {
              this._BX.resHeaders![key] = value;
            }
          });
        this._BX.resBody = this.response;
        listeners.forEach((listener) => {
          listener(this._BX);
        });
      }
    });
    this.addEventListener('error', function (this: XMLHttpRequest, ev: Event) {
      this._BX.error = JSON.stringify(ev);
      listeners.forEach((listener) => {
        listener(this._BX);
      });
    });
    const origSetReqHeader = this.setRequestHeader;
    this.setRequestHeader = function (this: XMLHttpRequest, ...args: any[]) {
      if (!this._BX.reqHeaders) {
        this._BX.reqHeaders = {};
      }
      this._BX.reqHeaders[args[0]] = args[1];
      // @ts-ignore
      return origSetReqHeader.apply(this, args);
    };
    // @ts-ignore
    return origOpen.apply(this, args);
  };
  proto.send = function (this: XMLHttpRequest, ...args: any[]) {
    if (!this._BX) {
      // @ts-ignore
      return origSend.apply(this, args);
    }
    this._BX.startTime = Date.now();
    this._BX.reqBody = args[0];
    // @ts-ignore
    return origSend.apply(this, args);
  };
  return {
    listen(callback: (req: HTTPRequest) => void) {
      const id = randomString(8);
      listeners.set(id, callback);
      return () => {
        listeners.delete(id);
      };
    },
  };
}
