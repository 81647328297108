export function relativeTime(time: Date | string, locale: string) {
  if (typeof time === 'string') {
    time = new Date(time);
  }
  const formatter = new Intl.RelativeTimeFormat(locale, {
    numeric: 'auto',
  });

  const DIVISIONS = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' },
  ];
  let duration = (time.getTime() - new Date().getTime()) / 1000;
  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      // @ts-ignore
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
}

export function absoluteDate(time: Date | string, locale: string) {
  if (typeof time === 'string') {
    time = new Date(time);
  }
  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  return formatter.format(time);
}

export function absoluteTime(time: Date | string, locale: string) {
  if (typeof time === 'string') {
    time = new Date(time);
  }
  const formatter = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
  });
  return formatter.format(time);
}

export function timeOfDay(time: Date | string, i18n: any) {
  if (typeof time === 'string') {
    time = new Date(time);
  }
  const hour = time.getHours();
  switch (true) {
    case hour >= 22 || hour < 6:
      return i18n.t('night');
    case hour >= 6 && hour < 12:
      return i18n.t('morning');
    case hour >= 12 && hour < 18:
      return i18n.t('afternoon');
    case hour >= 18 && hour < 22:
      return i18n.t('evening');
  }
}
