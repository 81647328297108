<script setup lang="ts">
import { NModal, NCard, NButton } from 'naive-ui';
//@ts-ignore
import Markdown from 'vue3-markdown-it';
interface Props {
  show: boolean;
  content: string;
  confirmText: string;
  cancelText?: string;
}
const props = defineProps<Props>();
const emit = defineEmits(['confirm', 'cancel']);
</script>

<template>
  <n-modal :show="show" :closable="false" :footer="null" @mask-click="emit('cancel')">
    <n-card class="card" :bordered="false">
      <div class="flex gap-2">
        <slot name="icon" />
        <Markdown html breaks :source="content" />
      </div>
      <template #action>
        <div class="flex items-center justify-end gap-2">
          <n-button v-if="cancelText" @click="emit('cancel')">
            {{ cancelText }}
          </n-button>
          <n-button type="primary" @click="emit('confirm')">
            {{ confirmText }}
          </n-button>
        </div>
      </template>
    </n-card>
  </n-modal>
</template>

<style scoped lang="scss">
.card {
  @apply w-[30rem] max-w-[90%] z-[9999];
}
</style>
