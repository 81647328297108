import Color from 'color';
import { hslModify } from './color';

export class Theme {
  name!: string;
  colors!: ColorScheme;
  roundness!: RoundnessOptions;
  font!: FontOptions;
  backgroundGradient!: boolean;
  constructor(partial: Partial<Theme>) {
    Object.assign(this, partial);
    if (partial.colors) {
      this.colors = new ColorScheme(partial.colors);
    }
  }
}

interface BasicColorScheme {
  primary: string;
  background: string;
  greyScaleBackground?: boolean;
}

export class ColorScheme {
  basic!: BasicColorScheme;
  _secondary?: string;
  get secondary(): string {
    return this._secondary ?? this.basic.primary;
  }
  _background1?: string;
  get background1(): string {
    const color = this._background1 ?? this.basic.background;
    if (!this.basic.greyScaleBackground) {
      return color;
    }
    return Color(color).grayscale().hexa();
  }
  _background2?: string;
  get background2(): string {
    const color =
      this._background2 ??
      hslModify(this.basic.background, {
        l: (vl) => vl - (vl - 50) * -0.08,
      });
    if (!this.basic.greyScaleBackground) {
      return color;
    }
    return Color(color).grayscale().hexa();
  }
  _foreground1?: string;
  get foreground1(): string {
    const color =
      this._foreground1 ??
      hslModify(this.basic.background, {
        l: (vl) => vl - (vl - 50) * 0.1,
      });
    if (!this.basic.greyScaleBackground) {
      return color;
    }
    return Color(color).grayscale().hexa();
  }
  _foreground2?: string;
  get foreground2(): string {
    const color =
      this._foreground2 ??
      hslModify(this.basic.background, {
        l: (vl) => vl - (vl - 50) * 0.6,
      });
    if (!this.basic.greyScaleBackground) {
      return color;
    }
    return Color(color).grayscale().hexa();
  }
  _text!: string;
  get text(): string {
    return this._text ?? Color(this.basic.background).isDark() ? '#ffffffe6' : '#000000e6';
  }
  _borders?: string;
  get borders(): string {
    return this._borders ?? Color(this.text).alpha(0.1).hexa();
  }
  _success?: string;
  get success(): string {
    return (
      this._success ??
      hslModify(this.basic.primary, {
        h: () => 120,
      })
    );
  }
  _warning?: string;
  get warning(): string {
    return (
      this._warning ??
      hslModify(this.basic.primary, {
        h: () => 60,
      })
    );
  }
  _error?: string;
  get error(): string {
    return (
      this._error ??
      hslModify(this.basic.primary, {
        h: () => 0,
      })
    );
  }

  constructor(scheme: Partial<ColorScheme>) {
    Object.assign(this, scheme);
  }
}

export enum RoundnessOptions {
  none,
  minimal,
  medium,
  extra,
}

export enum FontOptions {
  Inter,
  Poppins,
  Roboto,
  RobotoMono,
  SourceSansPro,
  SourceCodePro,
  FiraCode,
  FiraSans,
}
