<script setup lang="ts">
import { TeamOutput } from '@bluepic/types/src/Auth/team.output';
import { NCard, NTag, NEllipsis, NButton } from 'naive-ui';
import { TrashBin } from '@vicons/ionicons5';

import SettingsIcon from '../../../assets/icons/settings.svg';
//@ts-ignore no types
import { NPopup } from 'naive-tools';
import { oneWaySync } from '../../../util/reactivity';
import { UserOutput } from '@bluepic/types/src/Auth/user.output';
interface Props {
  team: TeamOutput;
  active?: boolean;
  selected?: boolean;
  editable?: boolean;
  drawer?: boolean;
  compact?: boolean;
  mini?: boolean;
  hoverable?: boolean;
}
const props = defineProps<Props>();
const { team, active, selected, editable, drawer, compact, hoverable } = toRefs(props);
const emit = defineEmits(['edit', 'select']);
const authStore = useAuthStore();
const directMembers = computed(() => {
  if (!team.value) return [];
  return [...new Set([...(team.value?.memberIds ?? []), ...(team.value?.adminIds ?? [])])];
});
watch(
  directMembers,
  (m) => {
    if (!team.value) return;
    authStore.getUsers(m);
  },
  { immediate: true }
);
const directMemberUsers = computed(() => {
  if (!team.value) return [];
  return directMembers.value.map((m) => authStore.users.get(m)).filter((u) => u !== undefined && !u.dummy) as UserOutput[];
});
const showSubteamDrawer = ref(false);
function select() {
  emit('select', team.value);
  if (drawer?.value) {
    showSubteamDrawer.value = true;
  }
}
const teamStore = useTeamStore();
const parent = computed(() => {
  if (!team.value?.parentId) {
    return null;
  }
  return teamStore.teams?.find((t) => t.id === team.value?.parentId);
});
const teamPath = computed(() => {
  return _teamPath(team.value)
    .map((t) => t?.name)
    .reverse();
});
function _teamPath(team: TeamOutput): TeamOutput[] {
  if (!team?.parentId) {
    return [team];
  }
  const parent = teamStore.teams?.find((t) => t.id === team.parentId);
  if (!parent) {
    return [team];
  }
  return [..._teamPath(parent), team];
}
const confirm = useConfirmDialog();
const message = useMessage();
const request = useSafeHTTP();
const deleting = ref(false);
function _delete(hard?: boolean) {
  if (!team.value || !authStore.jwt) {
    return;
  }
  confirm({
    title: t('delete-space'),
    content: t('confirm-delete-space'),
    callback: () => {
      const pending = request(
        () => deleteTeam(team.value.id, hard, authStore.jwt!),
        (res) => {
          message.success(t('space-deleted'));
          showSubteamDrawer.value = false;
        }
      );
      oneWaySync(deleting, pending);
    },
    yesText: t('delete'),
    noText: t('cancel'),
  });
}
</script>

<template>
  <div>
    <n-card class="team" :class="{ selected, active, hoverable, compact }" @click="select" :hoverable="hoverable">
      <div class="max-w-full flex justify-between items-center overflow-hidden">
        <div class="flex items-center gap-2">
          <div class="flex items-center">
            <div v-if="parent" class="parent">
              <div class="avatar-container" :class="{ mini }">
                <profile-picture :team="parent" thumbnail />
              </div>
            </div>
            <div class="avatar-container" :class="{ mini }">
              <profile-picture :team="team" thumbnail />
            </div>
          </div>
          <div>
            <div class="max-w-full flex items-center gap-2">
              <div class="max-w-full team-path">
                <div class="team max-w-full" v-for="(name, index) in teamPath" :key="index">
                  <h2 class="name max-w-full">
                    <n-ellipsis class="max-w-full">
                      {{ name }}
                    </n-ellipsis>
                  </h2>
                  <h2 class="slash" v-if="index !== 0">/</h2>
                </div>
              </div>
            </div>
            <div v-if="!mini" class="flex items-center gap-2">
              <n-popover :keep-alive-on-hover="true">
                <template #trigger>
                  <p class="member-count cursor-help">
                    <n-ellipsis class="max-w-full">
                      {{ directMemberUsers.length }}
                      {{ directMemberUsers.length != 1 ? $t('member-plural') : $t('member-singular') }}
                    </n-ellipsis>
                  </p>
                </template>
                <b-paginated-list class="w-[80vw] max-w-lg" :items-per-page="5">
                  <user-list-item v-for="user of directMemberUsers" :user="user" :team="team ?? undefined" />
                </b-paginated-list>
              </n-popover>
              <n-tag v-if="active" round size="small" type="primary"> {{ $t('active') }} </n-tag>
              <n-tag v-if="team.locked" round size="small" type="error"> {{ $t('locked') }} </n-tag>
              <n-tag v-if="team.agency" type="warning" round size="small">{{ $t('agency') }}</n-tag>
            </div>
          </div>
        </div>
        <div class="flex-grow flex justify-end">
          <div
            v-if="editable"
            @click="
              (e: MouseEvent) => {
                e.stopPropagation();
                emit('edit', team);
              }
            "
            class="icon-container"
          >
            <SettingsIcon class="icon" />
          </div>
          <slot name="extra" />
        </div>
      </div>
    </n-card>
    <b-adaptive-modal v-if="drawer" v-model:show="showSubteamDrawer">
      <div class="popup">
        <team-profile :team="team">
          <template #header-extra>
            <div class="flex gap-2 items-center">
              <n-button type="error" circle ghost @click="_delete()" :loading="deleting">
                <template #icon>
                  <n-icon>
                    <TrashBin />
                  </n-icon>
                </template>
              </n-button>
              <admin-only>
                <n-button type="error" round ghost @click="_delete(true)" :loading="deleting">
                  <template #icon>
                    <n-icon>
                      <TrashBin />
                    </n-icon>
                  </template>
                  HARD
                </n-button>
              </admin-only>
            </div>
          </template>
        </team-profile>
      </div>
    </b-adaptive-modal>
  </div>
</template>

<style lang="scss" scoped>
.team {
  @apply max-w-full;
  &.compact {
    :deep().n-card-header {
      @apply py-2;
    }
    :deep().n-card__content {
      @apply py-2;
    }
  }
  &.hoverable {
    @apply cursor-pointer;
    &:hover {
      background-color: var(--foreground2-hover);
    }
  }
  &.selected {
    border: 2px solid var(--primary);
  }
  &.active {
    border-left: 4px solid var(--primary);
  }
  @media (hover: hover) {
    &:hover {
      .icon-container {
        @apply opacity-100;
      }
    }
  }
  .parent {
    @apply w-4;
  }

  .avatar-container {
    @apply w-16 h-16 rounded-full;
    border: 1px solid var(--borders);
    box-shadow: 0 0 10px -3px black;
    &.mini {
      @apply w-8 h-8;
    }
  }
  .member-count {
    color: var(--text-hover);
  }
  .icon-container {
    @apply h-8 w-8 rounded-full p-2 transition-opacity;
    @media (hover: hover) {
      @apply opacity-0;
    }
    &:hover {
      .icon {
        fill: var(--text-hover);
      }
    }
    .icon {
      @apply h-full w-full transition-colors cursor-pointer;
      fill: var(--text);
    }
  }

  .team-path {
    @apply flex flex-row-reverse justify-end items-center flex-wrap gap-2 overflow-hidden h-[2em];
    max-width: 100vw;
    .team {
      @apply flex gap-2 h-[2em];
      .slash {
        color: var(--text-hover);
      }
    }
  }
}
.popup {
  @apply flex flex-col p-4 justify-center gap-2 mb-10 w-[90vw] max-w-2xl m-auto;
}
</style>
