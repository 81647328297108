<script setup lang="ts">
import { NCard, NButton, NProgress } from 'naive-ui';
//@ts-ignore
import Markdown from 'vue3-markdown-it';
import { OnboardingFlow, OnboardingStep } from '../../onboarding/types';

interface Props {
  activeStep: {
    flow: OnboardingFlow;
    step: OnboardingStep;
  };
}
const props = defineProps<Props>();
const emit = defineEmits(['next']);
watchEffect(() => {
  console.log(props.activeStep?.step.message);
});
</script>

<template>
  <div>
    <n-card :bordered="false">
      <template #header>
        {{ activeStep?.flow.name }} ({{ (activeStep?.flow.steps.indexOf(activeStep.step) ?? 0) + 1 }}/{{
          activeStep?.flow.steps.length
        }})
      </template>
      <Markdown html breaks :source="activeStep?.step.message" />
      <template #footer>
        <n-button v-if="activeStep?.step.nextCondition?.type === 'button'" @click="() => emit('next')">
          {{ activeStep?.step.nextCondition?.target }}
        </n-button>
      </template>
    </n-card>
    <n-progress
      :percentage="(activeStep?.flow.steps.indexOf(activeStep.step) / activeStep?.flow.steps.length) * 100"
      :show-indicator="false"
    />
  </div>
</template>
