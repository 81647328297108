<script setup lang="ts">
const authStore = useAuthStore();
const isAdmin = computed(() => {
  return authStore.hasPermission('bluepic.admin');
});
</script>

<template>
  <div v-if="isAdmin" class="admin-only">
    <label>Admin</label>
    <slot />
  </div>
  <div v-else>
    <slot name="fallback" />
  </div>
</template>

<style scoped lang="scss">
.admin-only {
  @apply relative flex flex-col p-3 rounded-md;
  border: 1px solid var(--error-hover);
  label {
    @apply text-sm absolute top-0 left-2;
    color: var(--error);
  }
}
</style>
