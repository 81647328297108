<script setup lang="ts">
import { NPopup } from 'naive-tools';
import { NModal, NCard, NButton, NIcon } from 'naive-ui';
import { CloseOutline } from '@vicons/ionicons5';
interface Props {
  show?: boolean;
  title?: string;
  notClosable?: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(['update:show']);
const device = useDevice();
</script>

<template>
  <n-popup
    v-if="device === 'mobile'"
    type="layer"
    fixed-height
    :show="show"
    @update:show="(v: boolean) => {
      if(!notClosable) {
        emit('update:show', v)
      }
    }"
    :title="title"
  >
    <template #header>
      <div v-if="!$slots.header" class="flex items-center flex-row-reverse justify-between">
        <n-button v-if="!notClosable" circle tertiary @click="() => emit('update:show', false)">
          <template #icon>
            <n-icon>
              <CloseOutline />
            </n-icon>
          </template>
        </n-button>
        <div v-else />
        <h1 v-if="title && !$slots.title" class="text-xl font-bold">
          {{ title }}
        </h1>
        <slot name="title" />
      </div>
      <slot name="header" />
    </template>
    <slot />
  </n-popup>
  <n-modal
    v-else
    :show="show"
    @update:show="(v: boolean) => {
    if(!notClosable) {
      emit('update:show', v)
    }
  }"
  >
    <n-card :bordered="false">
      <template #header>
        <div v-if="!$slots.header" class="flex items-center flex-row-reverse justify-between">
          <div class="actions">
            <slot v-if="$slots.actions" name="actions" />
            <n-button v-if="!notClosable" circle tertiary @click="() => emit('update:show', false)">
              <template #icon>
                <n-icon>
                  <CloseOutline />
                </n-icon>
              </template>
            </n-button>
          </div>
          <h1 v-if="title && !$slots.title" class="text-xl font-bold">
            {{ title }}
          </h1>
          <slot name="title" />
        </div>
        <slot name="header" />
      </template>
      <slot />
    </n-card>
  </n-modal>
</template>

<style scoped lang="scss">
.n-popup__root {
  @apply h-0 w-0;
}
.n-card {
  @apply w-max;
  border-radius: calc(var(--roundness) * 2.5);
  max-height: calc(100vh - 2rem);
  max-width: calc(100vw - 2rem);
  :deep().n-card__content {
    @apply overflow-auto;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
</style>
