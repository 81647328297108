import { OnboardingFlow } from "../types";

export const editorFlow: OnboardingFlow = {
  key: "editor",
  name: "Use the editor",
  startRoute: "/gallery",
  startMessage: `
  # Welcome to Bluepic!
  <br/>
  let's learn how to use the editor!
  `,
  endMessage: `
  # Good Job!
  <br/>
  You're ready to create great social media content!
  `,
  steps: [
    {
      elements: ["demo-template-insta", "demo-template-twitter", "demo-template-facebook"],
      message: `
      # This is the template gallery
      <br/>
      Here are all your templates.
      `,
      preventClicks: true,
      nextCondition: {
        type: "button",
        target: "continue",
      },
    },
    {
      elements: ["gallery-filters"],
      message: `
      # Try out the filters
      <br/>
      You can filter your templates by platform and format.
      If you can't find what you're looking for, you also search by name.
      `,
      nextCondition: {
        type: "button",
        target: "continue",
      },
    },
    {
      elements: ["demo-template-insta", "demo-template-twitter", "demo-template-facebook"],
      message: `
      # Select a ~Pokémon~ template
      <br/>
      Click on a template to get to the editor.
      `,
      nextCondition: {
        type: "route",
        target: "/editor",
      },
    },
  ],
};
