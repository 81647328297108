import { SessionOutput } from '@bluepic/types/src/Auth/session.output';
import { logout, onLogout } from '../util/logout';
import { parseJwt } from '../util/jwt';
import { pinia } from './pinia';
import { router } from '../routes';
import { upsert } from '../util/arrays';

type SessionStoreState = {
  currentSessionId: string | null;
  sessions: SessionOutput[];
};

type SessionStoreActions = {
  tryFetch: () => Promise<void>;
  initFeed: () => void;
  getSessionId: () => string | null;
  fetchSessions: (userId: string) => Promise<void>;
  killSession: (id: string) => Promise<void>;
  killAllSessions: () => Promise<void>;
};

let unsubscribe = () => {};
export const useSessionStore = defineStore<string, SessionStoreState, {}, SessionStoreActions>('session', {
  state: () => ({
    currentSessionId: null as string | null,
    sessions: [] as SessionOutput[],
  }),
  actions: {
    async tryFetch() {
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      try {
        this.sessions = await getSessions(undefined, authStore.jwt);
        this.currentSessionId = this.getSessionId();
        this.initFeed();
      } catch (e) {
        console.error(e);
      }
    },
    initFeed() {
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      unsubscribe?.();
      unsubscribe = useFeed(authStore.jwt, ['auth2'], ['SESSION'], (m) => {
        if (m.service !== 'auth2') return;
        switch (m.operation) {
          case 'CREATE_SESSION':
            if (!m.payload) return;
            const newSession = JSON.parse(m.payload) as SessionOutput;
            upsert(this.sessions, (s) => s.id === newSession.id, newSession);
            break;
          case 'UPDATE_SESSION':
            if (!m.payload) return;
            const session = JSON.parse(m.payload) as SessionOutput;
            this.sessions = this.sessions?.filter((s) => s.id !== session.id);
            this.sessions?.push(session);
            break;
          case 'DELETE_SESSION':
            if (this.currentSessionId === m.resourceId) {
              if (authStore.user?.shared) {
                const path = `/shared/${authStore.user?.id}?logout`;
                logout(true);
                return router.push(path);
              }
              return logout();
            }
            this.sessions = this.sessions?.filter((s) => s.id !== m.resourceId);
            break;
        }
      });
    },
    async fetchSessions(userId: string) {
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      this.sessions = await getSessions(userId, authStore.jwt);
    },
    getSessionId() {
      const authStore = useAuthStore();
      if (!authStore.jwt) return null;
      const payload = parseJwt(authStore.jwt);
      return payload?.session;
    },
    async killSession(id: string) {
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      await killSession(id, authStore.jwt);
    },
    async killAllSessions() {
      for (const session of this.sessions) {
        if (session.id === this.currentSessionId) continue;
        this.killSession(session.id);
      }
    },
  },
});

onLogout(() => {
  useSessionStore(pinia).$reset();
});
