import { Ref } from "vue";

export function useConfetti() {
  const showParticles = inject<Ref<boolean>>('showParticles');
  if(!showParticles) {
    throw new Error('showParticles not provided');
  }
  return {
    show() {
      showParticles.value = true;
    },
    hide() {
      showParticles.value = false;
    },
    showFor(ms: number) {
      showParticles.value = true;
      setTimeout(() => {
        showParticles.value = false;
      }, ms);
    }
  }
}