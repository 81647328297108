export const transitions = {
  'enter-bluepicintro': `
    .page-enter-active {
      transition: opacity var(--t-dur) ease, transform .5s 1s ease;
    }
    .page-enter-from {
      opacity: 0;
      transform: translateY(20vh);
    }`,
  'enter-fade': `
    .page-enter-active {
      transition: opacity var(--t-dur) ease;
    }
    .page-enter-from {
      opacity: 0;
    }`,
  'enter-fromBottom': `
    .page-enter-active {
      transition: transform var(--t-dur) ease;
    }
    .page-enter-from {
      transform: translateY(100%);
    }`,
  'enter-fromLeft': `
    .page-enter-active {
      transition: transform var(--t-dur) ease;
    }
    .page-enter-from {
      transform: translateX(-100%);
    }`,
  'enter-fromRight': `
    .page-enter-active {
      transition: transform var(--t-dur) ease;
    }
    .page-enter-from {
      transform: translateX(100%);
    }`,
  'enter-fromTop': `
    .page-enter-active {
      transition: transform var(--t-dur) ease;
    }
    .page-enter-from {
      transform: translateY(-100%);
    }`,
  'exit-fade': `
    .page-leave-active {
      transition: opacity var(--t-dur) ease;
    }
    .page-leave-to {
      opacity: 0;
    }`,
  'exit-toBottom': `
    .page-leave-active {
      transition: transform var(--t-dur) ease;
    }
    .page-leave-to {
      transform: translateY(100%);
    }`,
  'exit-toLeft': `
    .page-leave-active {
      transition: transform var(--t-dur) ease;
    }
    .page-leave-to {
      transform: translateX(-100%);
    }`,
  'exit-toRight': `
    .page-leave-active {
      transition: transform var(--t-dur) ease;
    }
    .page-leave-to {
      transform: translateX(100%);
    }`,
  'exit-toTop': `
    .page-leave-active {
      transition: transform var(--t-dur) ease;
    }
    .page-leave-to {
      transform: translateY(-100%);
    }`,
};