<script setup lang="ts">
import { NDrawer, NButton, NIcon } from 'naive-ui';
import { CloseOutline } from '@vicons/ionicons5';
interface Props {
  show: boolean;
}
const { show } = defineProps<Props>();
const emit = defineEmits(['update:show']);
const device = useDevice();
// on mobile: fullscreen, on desktop: left side drawer
const width = computed(() => (device.value === 'mobile' ? '100vw' : '400px'));
</script>

<template>
  <n-drawer :show="show" @update-show="(v) => emit('update:show', v)" :default-width="width" class="no-bottom-inset">
    <div class="flex flex-col gap-4 h-full overflow-hidden" @click.prevent="(e) => e.stopPropagation()">
      <div class="p-4 pb-0 flex justify-end">
        <h2 class="flex-grow">
          {{ $t('notifications') }}
        </h2>
        <n-button circle tertiary @click.prevent="emit('update:show', false)">
          <template #icon>
            <n-icon>
              <CloseOutline />
            </n-icon>
          </template>
        </n-button>
      </div>
      <div class="relative flex-grow">
        <notification-list />
      </div>
    </div>
  </n-drawer>
</template>

<style scoped lang="scss"></style>
