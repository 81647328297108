const { viewbox } = useWindowViewbox(true);
const breakpoints = {
  mobile: 600,
  tablet: 1300,
  desktop: 1920,
}

export const useDevice = () => computed<'mobile' | 'tablet' | 'desktop' | 'ultrawide'>(() => {
  if (viewbox.width <= breakpoints.mobile) return 'mobile';
  if (viewbox.width <= breakpoints.tablet) return 'tablet';
  if (viewbox.width <= breakpoints.desktop) return 'desktop';
  return 'ultrawide';
});