import { OnboardingFlow } from "../types";

export const settingsFlow: OnboardingFlow = {
  key: "settings",
  name: "View your settings",
  startRoute: "/gallery",
  startMessage: `
  # Welcome to Bluepic!
  <br/>
  let's learn how to get to your settings
  `,
  endMessage: "You're all set, have fun!",
  steps: [
    {
      elements: ["nav-settings"],
      message: "Click here to access your settings",
      nextCondition: {
        type: "route",
        target: "/settings",
      },
    },
    {
      elements: ["settings-appearance"],
      message: "Click here for general settings",
      route: "/settings",
      nextCondition: {
        type: "route",
        target: "/settings/appearance",
      },
    },
  ],
};
