<script setup lang="ts">
import { NForm, NFormItem, NInput, NButton, FormRules, FormItemRule, FormValidationError } from 'naive-ui';

interface Props {
  show?: boolean;
  teamId: string;
}
const props = defineProps<Props>();
const { show, teamId } = toRefs(props);
const emit = defineEmits(['update:show']);

const data = reactive({
  email: '',
});
const rules: FormRules = {
  name: {
    trigger: 'blur',
    validator: (rule: FormItemRule, value: string) => {
      if (!value) {
        resetValidation();
        return Error(t('email-is-required'));
      }
      return true;
    },
  },
};
const formRef = ref<HTMLFormElement | undefined>();
let timer: NodeJS.Timeout;
function resetValidation() {
  clearTimeout(timer);
  timer = setTimeout(() => {
    formRef.value?.restoreValidation();
  }, 3000);
}
const inputRef = ref<HTMLInputElement | undefined>();

const authStore = useAuthStore();
const message = useMessage();
const request = useSafeHTTP();
function doInvite() {
  if (!teamId.value) return;
  if (!authStore.jwt) return;
  formRef.value?.validate((errors: Array<FormValidationError> | undefined) => {
    if (errors) {
      return;
    }
    inputRef.value?.blur();
    request(
      () =>
        inviteUser(
          {
            email: data.email,
            teamId: teamId.value,
          },
          authStore.jwt!
        ),
      (res) => {
        message.success(t('invite-sent'));
        emit('update:show', false);
      }
    );
  });
}
</script>

<template>
  <b-adaptive-modal :title="$t('invite-user')" :show="show" @update:show="(v: boolean) => emit('update:show', v)">
    <div class="popup">
      <n-form ref="formRef" :rules="rules" :model="data" :show-label="false">
        <n-form-item path="email">
          <n-input
            ref="inputRef"
            :input-props="{ type: 'email', autocomplete: 'email' }"
            v-model:value="data.email"
            :placeholder="$t('email')"
            @keydown="(e: KeyboardEvent) => {
                if(e.key === 'Enter') {
                  doInvite();
                }
              }"
          />
        </n-form-item>
      </n-form>
      <n-button @click="doInvite" type="primary" ghost>
        {{ $t('send-invite') }}
      </n-button>
    </div>
  </b-adaptive-modal>
</template>

<style scoped lang="scss">
.popup {
  @apply flex flex-col p-4 justify-center gap-2 mb-10 w-[90vw] max-w-2xl m-auto;
}
</style>
