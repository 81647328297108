<script setup lang="ts">
import { OnboardingFlow } from '../../onboarding/types';
import { NButton } from 'naive-ui';
import { InformationOutline } from '@vicons/ionicons5';

interface Props {
  flow: OnboardingFlow;
}
const props = defineProps<Props>();
const { flow } = toRefs(props);
const emit = defineEmits(['click']);

const targets = computed(() => {
  return flow.value.steps[0].elements
    .map((el) => document.querySelector(`[data-onboarding="${el}"]`))
    .filter((el) => el !== null) as Element[];
});

const bbox = reactive({
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

const innerMostAnchor = computed(() => {
  //calculate anchor of bbox that is closest to the center of the screen
  const center = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };
  const anchors = [
    // top left
    { x: bbox.left, y: bbox.top },
    // top center
    { x: bbox.left + (bbox.right - bbox.left) / 2, y: bbox.top },
    // top right
    { x: bbox.right, y: bbox.top },
    // right center
    { x: bbox.right, y: bbox.top + (bbox.bottom - bbox.top) / 2 },
    // bottom left
    { x: bbox.left, y: bbox.bottom },
    // bottom center
    { x: bbox.left + (bbox.right - bbox.left) / 2, y: bbox.bottom },
    // bottom right
    { x: bbox.right, y: bbox.bottom },
    // left center
    { x: bbox.left, y: bbox.top + (bbox.bottom - bbox.top) / 2 },
  ];
  const insetAmount = 0.5;
  const insets = [
    //top left
    { x: insetAmount, y: insetAmount },
    //top center
    { x: 0, y: insetAmount },
    //top right
    { x: -insetAmount, y: insetAmount },
    //right center
    { x: -insetAmount, y: 0 },
    //bottom left
    { x: insetAmount, y: -insetAmount },
    //bottom center
    { x: 0, y: -insetAmount },
    //bottom right
    { x: -insetAmount, y: -insetAmount },
    //left center
    { x: insetAmount, y: 0 },
  ];
  const distances = anchors.map((corner) => {
    return Math.sqrt(Math.pow(corner.x - center.x, 2) + Math.pow(corner.y - center.y, 2));
  });
  const min = Math.min(...distances);
  return {
    x: `calc(${anchors[distances.indexOf(min)].x}px + ${
      insets[distances.indexOf(min)].x ? insets[distances.indexOf(min)].x + 'rem' : ''
    })`,
    y: `calc(${anchors[distances.indexOf(min)].y}px + ${
      insets[distances.indexOf(min)].y ? insets[distances.indexOf(min)].y + 'rem' : ''
    })`,
  };
});

const stopWatching = watch(
  targets,
  (nv) => {
    if (nv) {
      function observe() {
        requestAnimationFrame(() => {
          //calculate bounding box of all targets
          const box = nv.reduce(
            (acc, el) => {
              const rect = el.getBoundingClientRect();
              return {
                top: Math.min(acc.top, rect.top),
                left: Math.min(acc.left, rect.left),
                bottom: Math.max(acc.bottom, rect.bottom),
                right: Math.max(acc.right, rect.right),
              };
            },
            {
              top: Infinity,
              left: Infinity,
              bottom: -Infinity,
              right: -Infinity,
            }
          );
          bbox.top = box.top;
          bbox.left = box.left;
          bbox.bottom = box.bottom;
          bbox.right = box.right;
          observe();
        });
      }
      observe();
      nextTick(() => {
        stopWatching();
      });
    }
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="onboarding-starter"
    :style="{
      top: innerMostAnchor.y,
      left: innerMostAnchor.x,
    }"
  >
    <n-button class="absolute -translate-x-1/2 -translate-y-1/2" circle type="primary" @click="emit('click')">
      <n-icon size="1.8rem">
        <information-outline />
      </n-icon>
    </n-button>
  </div>
</template>

<style scoped lang="scss">
.onboarding-starter {
  @apply fixed pointer-events-auto;
}
//pulsating animation
</style>
