<script setup lang="ts">
import { NotificationOutput } from '@bluepic/types/src/Auth/notification.output';
import { NCard, NCollapseTransition, NEmpty, NButton, NIcon, NTag } from 'naive-ui';
import {
  TrashBinOutline,
  NotificationsOutline,
  MailOutline,
  MegaphoneOutline,
  PersonAddOutline,
  PersonRemoveOutline,
  PersonOutline,
  PeopleOutline,
  LockClosedOutline,
  LockOpenOutline,
  EaselOutline,
  CheckmarkDoneOutline,
  CloseOutline,
} from '@vicons/ionicons5';
import { absoluteDate, absoluteTime, relativeTime } from '../../util/time';
import { InviteOutput } from '@bluepic/types/src/Auth/invite.output';
import { VNode } from 'vue';
import { useSwipe } from '@vueuse/core';
import { t } from '../../composables/useLocalisation';
import { router } from '../../routes';
import { BxAvatar } from '@bluepic/embedded';
interface Props {
  notification: NotificationOutput;
  hideNew?: boolean;
  noDelete?: boolean;
  close?: () => void;
}
const props = defineProps<Props>();
// watchEffect(() => {
//   console.log(props.notification);
// });
const data = computed<
  | {
      text: string;
      user?: {
        id?: string;
        name: string;
        img: string;
      };
      space?: {
        id?: string;
        name: string;
        img: string;
      };
      template?: {
        name: string;
        preview: string;
      };
      action?: () => void;
      icon?: () => VNode;
    }
  | undefined
>(() => {
  try {
    const {
      user,
      userId,
      userImg,
      inviter,
      inviterImg,
      space,
      spaceImg,
      addedUser,
      addedUserImg,
      removedUser,
      removedUserImg,
      reason,
      preview,
      name,
      email,
      inviteId,
      spaceId,
      message,
    } = JSON.parse(props.notification.payload);
    // console.log(JSON.parse(props.notification.payload));
    switch (props.notification?.type) {
      case 'CUSTOM':
        return {
          text: message,
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          space: {
            name: space,
            img: spaceImg,
          },
          icon: () => h(MegaphoneOutline),
        };
      case 'CHANGE_PASSWORD':
        return {
          text: t('CHANGE_PASSWORD'),
        };
      case 'SPACE_INVITE':
        if (inviteId) {
          getInvite(inviteId)
            .then((i) => {
              invite.value = i;
            })
            .catch((e) => {
              console.error(e);
            });
        }
        return {
          text: t('SPACE_INVITE', [inviter, space]),
          user: {
            id: userId,
            name: inviter,
            img: inviterImg,
          },
          space: {
            name: space,
            img: spaceImg,
          },
          action: () => {
            showInvite.value = !showInvite.value;
          },
          icon: () => h(MailOutline),
        };
      case 'SPACE_MEMBER_JOINED':
        return {
          text: t('SPACE_MEMBER_JOINED', [addedUser, space]),
          user: {
            id: userId,
            name: addedUser,
            img: addedUserImg,
          },
          space: {
            name: space,
            img: spaceImg,
          },
          action: () => {
            router.push(`/settings/teams/${spaceId}`);
          },
          icon: () => h(PersonAddOutline),
        };
      case 'SPACE_MEMBER_REMOVED':
        return {
          text: t('SPACE_MEMBER_REMOVED', [removedUser, space]),
          user: {
            id: userId,
            name: removedUser,
            img: removedUserImg,
          },
          space: {
            name: space,
            img: spaceImg,
          },
          action: () => {
            router.push(`/settings/teams/${spaceId}`);
          },
          icon: () => h(PersonRemoveOutline),
        };
      case 'SPACE_OWNER_CHANGED':
        return {
          text: t('SPACE_OWNER_CHANGED', [space, user]),
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          space: {
            name: space,
            img: spaceImg,
          },
          action: () => {
            router.push(`/settings/teams/${spaceId}`);
          },
          icon: () => h(PeopleOutline),
        };
      case 'SPACE_OWNER_TRANSFER_REQUESTED':
        return {
          text: t('SPACE_OWNER_TRANSFER_REQUESTED', [space, user]),
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          space: {
            name: space,
            img: spaceImg,
          },
          action: () => {
            router.push(`/settings/teams/${spaceId}`);
          },
          icon: () => h(PeopleOutline),
        };
      case 'SPACE_PROMOTED':
        return {
          text: t('SPACE_PROMOTED', [user, space]),
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          space: {
            name: space,
            img: spaceImg,
          },
          action: () => {
            router.push(`/settings/teams/${spaceId}`);
          },
          icon: () => h(PeopleOutline),
        };
      case 'SPACE_DEMOTED':
        return {
          text: t('SPACE_DEMOTED', [space, user]),
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          space: {
            name: space,
            img: spaceImg,
          },
          action: () => {
            router.push(`/settings/teams/${spaceId}`);
          },
          icon: () => h(PeopleOutline),
        };
      case 'SPACE_LOCKED':
        return {
          text: t('SPACE_LOCKED', [space]),
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          space: {
            id: userId,
            name: space,
            img: spaceImg,
          },
          action: () => {
            router.push(`/settings/teams/${spaceId}`);
          },
          icon: () => h(LockClosedOutline),
        };
      case 'SPACE_UNLOCKED':
        return {
          text: t('SPACE_UNLOCKED', [space]),
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          space: {
            id: userId,
            name: space,
            img: spaceImg,
          },
          action: () => {
            router.push(`/settings/teams/${spaceId}`);
          },
          icon: () => h(LockOpenOutline),
        };
      case 'SPACE_TEMPLATE_ADDED':
        return {
          text: t('SPACE_TEMPLATE_ADDED', [name, space]),
          space: {
            name: space,
            img: spaceImg,
          },
          template: {
            name: name,
            preview: preview,
          },
          action: () => {
            router.push(`/settings/teams/${spaceId}`);
          },
          icon: () => h(EaselOutline),
        };
      case 'USER_EMAIL_CONFIRMED':
        return {
          text: t('USER_EMAIL_CONFIRMED', [email]),
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          action: () => {
            router.push(import.meta.env.V_ID_BASE_URL);
          },
          icon: () => h(CheckmarkDoneOutline),
        };
      case 'USER_LOCKED':
        return {
          text: t('USER_LOCKED', [user, reason]),
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          action: () => {
            router.push(import.meta.env.V_ID_BASE_URL);
          },
          icon: () => h(LockClosedOutline),
        };
      case 'USER_UNLOCKED':
        return {
          text: t('USER_UNLOCKED', [user]),
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          action: () => {
            router.push(import.meta.env.V_ID_BASE_URL);
          },
          icon: () => h(LockOpenOutline),
        };
      case 'UPDATE_EMAIL':
        return {
          text: t('UPDATE_EMAIL', [email]),
          user: {
            id: userId,
            name: user,
            img: userImg,
          },
          action: () => {
            router.push(import.meta.env.V_ID_BASE_URL);
          },
          icon: () => h(PersonOutline),
        };
    }
  } catch (e) {
    console.error(e);
  }
});
const timestamp = computed(() => {
  const date = absoluteDate(props.notification.timestamp, locale.value);
  const time = absoluteTime(props.notification.timestamp, locale.value);
  const relative = relativeTime(props.notification.timestamp, locale.value);
  return `${date} ${time} (${relative})`;
});
const unread = computed(
  () => !props.hideNew && (!props.notification.read || new Date().getTime() - props.notification.read.getTime() < 1000 * 60)
);
const showInvite = ref(false);
const invite = ref<InviteOutput | undefined>(undefined);

const notificationStore = useNotificationStore();
const authStore = useAuthStore();
const request = useSafeHTTP();
const deleting = ref(false);
function _delete() {
  if (!authStore.jwt) throw new Error('No JWT');
  deleting.value = true;
  request(
    () => deleteNotifications([props.notification.id], authStore.jwt!),
    (res) => {
      deleting.value = false;
      props.close?.();
      notificationStore.deleted.push(props.notification.id);
    },
    () => {
      deleting.value = false;
      props.close?.();
    }
  );
}
const swipe = reactive({
  x: 0,
  active: false,
});
const limit = 80;
const cardRef = ref<HTMLElement | undefined>(undefined);
const stopWatchingCardRef = watch(
  cardRef,
  (nv) => {
    if (!nv || (props.noDelete && !props.close)) return;
    const { lengthX, isSwiping } = useSwipe(nv, { threshold: 20, passive: true });
    watch(
      [lengthX, isSwiping],
      ([x, swiping]) => {
        if (swiping) {
          swipe.active = true;
          swipe.x = props.close ? -x : Math.min(limit, Math.max(-limit, -x));
        } else {
          if (Math.abs(swipe.x) >= limit) {
            if (props.close) {
              props.close?.();
            } else {
              _delete();
            }
            return;
          }
          if (props.close) {
          }
          swipe.active = false;
          swipe.x = 0;
        }
      },
      { immediate: true }
    );
    nextTick(() => {
      stopWatchingCardRef();
    });
  },
  { immediate: true }
);
</script>

<template>
  <div class="m-2 relative">
    <div
      v-if="!props.noDelete"
      class="absolute inset-0 flex justify-between items-center px-7"
      :style="{
        backgroundColor: 'var(--error)',
        borderRadius: 'calc(var(--roundness) * 1.2)',
      }"
    >
      <n-spin :show="deleting" stroke="var(--text)">
        <n-icon :size="32">
          <trash-bin-outline />
        </n-icon>
      </n-spin>
      <n-spin :show="deleting" stroke="var(--text)">
        <n-icon :size="32">
          <trash-bin-outline />
        </n-icon>
      </n-spin>
    </div>
    <n-card
      ref="cardRef"
      @click="
        (e) => {
          e.stopPropagation();
          data?.action?.();
        }
      "
      :class="{
        'cursor-pointer': data?.action !== undefined,
        'transition-transform': !swipe.active,
        'shadow-black': true,
        'shadow-lg': props.close !== undefined,
      }"
      :hoverable="data?.action !== undefined"
      :style="{
        borderColor: unread ? 'var(--primary)' : undefined,
        transform: `translateX(${swipe.x}px)`,
        transitionDuration: 'var(--t-dur)',
      }"
    >
      <template #header>
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-4">
            <n-icon size="large">
              <component v-if="data?.icon" :is="data?.icon" />
              <notifications-outline v-else />
            </n-icon>
            <n-tag v-if="unread" type="primary" round> {{ t('new') }} </n-tag>
          </div>
          <n-button
            v-if="!props.noDelete"
            class="delete-btn"
            circle
            type="error"
            ghost
            @click="
              (e) => {
                e.stopPropagation();
                _delete();
              }
            "
            :loading="deleting"
            :disabled="deleting"
            size="small"
          >
            <template #icon>
              <n-icon size="medium">
                <trash-bin-outline />
              </n-icon>
            </template>
          </n-button>
          <n-button v-else-if="props.close" class="delete-btn" circle quaternary @click="props.close" size="small">
            <template #icon>
              <n-icon size="medium">
                <close-outline />
              </n-icon>
            </template>
          </n-button>
        </div>
      </template>
      <div class="flex items-stretch gap-4">
        <div class="flex-grow">
          <p v-if="data?.text" class="flex-grow" v-html="data.text" />
          <p class="text-end text-sm" style="color: var(--text-hover)">{{ timestamp }}</p>
        </div>
        <div class="flex flex-col items-center justify-end">
          <div class="w-10 h-10">
            <bx-avatar
              v-if="data?.user?.img || (data?.user?.name && data?.user?.id)"
              :src="data?.user?.img"
              :name="data?.user?.name"
              :colorSeed="data?.user?.id"
            />
            <b-img
              v-else
              src="https://imagedelivery.net/mudX-CmAqIANL8bxoNCToA/8af9d1db-cfc0-474d-4b80-33772d385100/ppNormal"
              circle
            />
          </div>
        </div>
      </div>
      <n-collapse-transition :show="showInvite">
        <div class="mt-4">
          <invite-incoming-list-item
            v-if="invite"
            :invite="invite"
            :onDecided="
              () => {
                _delete();
              }
            "
          />
          <n-empty v-else>{{ t('invalid-invite') }}</n-empty>
        </div>
      </n-collapse-transition>
    </n-card>
  </div>
</template>

<style>
@media (pointer: coarse) {
  html,
  body {
    overscroll-behavior-x: none;
  }
}
</style>

<style scoped lang="scss">
@media (pointer: coarse) {
  .delete-btn {
    display: none;
  }
}
</style>
