import { dialog } from "../util/naiveUI";

export function useSafeHTTP() {
  return function request<T>(
    func: () => Promise<T>,
    successCallback?: (data: T) => void,
    errorCloseCallback?: () => void
  ) {
    const loading = ref(true);
    func().then(
      (data) => {
        loading.value = false;
        successCallback?.(data);
      },
      (error) => {
        loading.value = false;
        let msg;
        if (error?.message?.startsWith("ERR")) {
          const [code] = error.message.split(":");
          msg = t("errors." + code);
        } else {
          msg = t("this-shouldnt-happen");
        }
        dialog.error({
          title: t("error"),
          content: msg,
          onClose: () => errorCloseCallback?.(),
        });
      }
    );
    return loading;
  };
}
