<script setup lang="ts">
import { NPopover } from 'naive-ui';
import { OnboardingFlow, OnboardingStep } from '../../onboarding/types';
interface Props {
  activeStep: {
    flow: OnboardingFlow;
    step: OnboardingStep;
  };
  targets: HTMLElement[];
}
const props = defineProps<Props>();
const { targets } = toRefs(props);
const emit = defineEmits(['mask-click']);

const pos = reactive({
  top: 0,
  left: 0,
  width: 0,
  height: 0,
});

const stopWatching = watch(
  targets,
  (nv) => {
    if (nv) {
      function observe() {
        requestAnimationFrame(() => {
          //calculate bounding box of all targets
          const box = nv.reduce(
            (acc, el) => {
              const rect = el.getBoundingClientRect();
              return {
                top: Math.min(acc.top, rect.top),
                left: Math.min(acc.left, rect.left),
                bottom: Math.max(acc.bottom, rect.bottom),
                right: Math.max(acc.right, rect.right),
              };
            },
            {
              top: Infinity,
              left: Infinity,
              bottom: -Infinity,
              right: -Infinity,
            }
          );
          pos.top = box.top;
          pos.left = box.left;
          pos.width = box.right - box.left;
          pos.height = box.bottom - box.top;
          observe();
        });
      }
      observe();
      nextTick(() => {
        stopWatching();
      });
    }
  },
  { immediate: true }
);
const showGuide = ref(false);
setTimeout(() => {
  showGuide.value = true;
}, 100);
const popoverRef = ref<InstanceType<typeof NPopover>>();
const stopWatchingPopoverRef = watch(popoverRef, (nv) => {
  if (!nv) return;
  function sync() {
    requestAnimationFrame(() => {
      nv?.syncPosition();
      sync();
    });
  }
  sync();
  nextTick(() => {
    stopWatchingPopoverRef();
  });
});
function maskClick(e: MouseEvent) {
  e.stopPropagation();
  emit('mask-click');
}
</script>

<template>
  <div>
    <n-popover ref="popoverRef" trigger="manual" :show="showGuide" :z-index="9999">
      <template #trigger>
        <div
          :style="{
            pointerEvents: activeStep.step.preventClicks ? 'auto' : 'none',
            width: `${pos.width}px`,
            height: `${pos.height}px`,
            top: `${pos.top}px`,
            left: `${pos.left}px`,
          }"
          class="onboarding-highlighter"
        />
      </template>
      <onboarding-guide :active-step="activeStep" />
    </n-popover>
    <div class="mask">
      <div
        @click="maskClick"
        class="left"
        :style="{
          width: `${pos.left}px`,
        }"
      />
      <div
        class="right"
        @click="maskClick"
        :style="{
          left: `${pos.left + pos.width}px`,
          width: `calc(100% - ${pos.left + pos.width}px)`,
        }"
      />
      <div
        class="top"
        @click="maskClick"
        :style="{
          height: `${pos.top}px`,
        }"
      />
      <div
        class="bottom"
        @click="maskClick"
        :style="{
          top: `${pos.top + pos.height}px`,
          height: `calc(100% - ${pos.top + pos.height}px)`,
        }"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.onboarding-highlighter {
  @apply absolute pointer-events-none;
  outline: 2px solid var(--primary);
  border-radius: 0.25rem;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7);
}
.mask {
  @apply absolute inset-0 pointer-events-none;
  > * {
    @apply absolute inset-0 pointer-events-auto;
  }
}
</style>
