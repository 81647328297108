<script setup lang="ts">
import { VNode } from '@vue/runtime-dom';
import { NPagination } from 'naive-ui';
import { oneWaySync } from '../../../util/reactivity';
interface Props {
  itemsPerPage?: number;
  page?: number;
  hidePagination?: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(['update:page']);
const slots = useSlots();
const nodes = computed(() => {
  const result: VNode[] = [];
  for (const s of slots.default?.() ?? []) {
    if (s.type.toString() === Symbol('v-fgt').toString()) {
      if (!Array.isArray(s.children)) continue;
      result.push(...(s.children as VNode[]));
    } else {
      result.push(s);
    }
  }
  return result;
});
const pages = computed(() => chunk(nodes.value, props.itemsPerPage ?? 10));
const currentPage = ref(0);
watch(
  () => props.page,
  (nv) => {
    if (nv !== undefined) currentPage.value = nv;
  }
);
watch(currentPage, (nv) => {
  emit('update:page', nv);
});
const rootRef = ref<HTMLElement | null>(null);
const availableWidth = ref(0);
let resizeObserver: ResizeObserver | null = null;
watch(
  rootRef,
  (nv) => {
    if (!nv) return;
    resizeObserver?.disconnect();
    resizeObserver = new ResizeObserver((entries) => {
      if (availableWidth.value !== entries[0].contentRect.width) {
        availableWidth.value = entries[0].contentRect.width;
      }
    });
    resizeObserver.observe(nv);
  },
  { immediate: true }
);
let mutationObserver: MutationObserver | null = null;
const pageElements = ref<HTMLElement[]>([]);
const pagesRef = ref<HTMLElement | null>(null);
watch(
  [pagesRef, pages],
  ([nv, _]) => {
    if (!nv) return;
    mutationObserver?.disconnect();
    mutationObserver = new MutationObserver((mutations) => {
      pageElements.value = Array.from(nv.children) as HTMLElement[];
    });
    mutationObserver.observe(nv, {
      childList: true,
    });
    pageElements.value = Array.from(nv.children) as HTMLElement[];
  },
  { immediate: true }
);
const wrapperRef = ref<HTMLElement | null>(null);
const pageResizeObservers = ref<ResizeObserver[]>([]);
watch(
  pageElements,
  (nv) => {
    if (nv.length) {
      pageResizeObservers.value.forEach((o) => o.disconnect());
      pageResizeObservers.value = nv.map((el) => {
        const ro = new ResizeObserver(() => {
          recomputePageHeight();
        });
        ro.observe(el);
        return ro;
      });
    }
    nextTick(() => {
      recomputePageHeight();
    });
  },
  { immediate: true }
);
const recomputePageHeight = throttle(
  () => {
    const pageHeight = pageElements.value?.length ? Math.max(...pageElements.value.map((el) => el.offsetHeight)) + 1 : 0;
    if (wrapperRef.value) {
      wrapperRef.value.style.height = `${pageHeight}px`;
    }
  },
  16,
  { leading: false, trailing: true }
);
const rem = computed(() => {
  if (!rootRef.value) return 0;
  return parseFloat(getComputedStyle(rootRef.value).fontSize);
});
</script>

<template>
  <div ref="rootRef" class="b-paginated-list">
    <div class="pages-wrapper" ref="wrapperRef">
      <div
        ref="pagesRef"
        class="pages"
        :style="{
          transform: `translateX(-${currentPage * (availableWidth + 1 * rem)}px)`,
        }"
      >
        <div
          class="page"
          :style="{
            width: `${availableWidth}px`,
          }"
          v-for="(page, i) in pages"
          :key="`page_${i}`"
        >
          <div class="item" v-for="(item, j) in page" :key="`item_${j}`">
            <component :is="item" />
          </div>
        </div>
      </div>
    </div>
    <n-pagination
      v-if="pages.length > 1 && !props.hidePagination"
      class="mx-auto max-w-full"
      :page="currentPage + 1"
      @update:page="(p: number) => currentPage = p - 1"
      :page-count="pages.length"
      :page-slot="7"
    />
  </div>
</template>

<style scoped lang="scss">
.b-paginated-list {
  @apply flex flex-col gap-2;
  .pages-wrapper {
    @apply relative overflow-hidden w-full;
    .pages {
      @apply absolute flex transition-transform gap-[1rem];
      transition-duration: var(--t-dur);
      .page {
        @apply flex flex-col gap-2;
        .item {
          @apply w-full;
        }
      }
    }
  }
}
</style>
