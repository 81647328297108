<script setup lang="ts">
import { UserOutput } from "@bluepic/types/src/Auth/user.output";
import { NSpin, NCard, NIcon, NTag, NDropdown, NButton, DropdownOption } from "naive-ui";
import { EllipsisVertical, LockClosedOutline } from "@vicons/ionicons5";
import CrownIcon from "../../../assets/icons/krone-filled.svg";
import { demoteTeamMember, promoteTeamMember, removeTeamMember } from "../../../controllers/authController";
import { TeamOutput } from "@bluepic/types/src/Auth/team.output";
import { oneWaySync } from "../../../util/reactivity";
import { Ref } from "vue";
import { isTeamAdmin, isTeamOwner } from "../../../util/team";
import { uniq } from "lodash";
const request = useSafeHTTP();
interface Props {
  user: UserOutput;
  team?: TeamOutput;
  showConfirmedTag?: boolean;
  hideActions?: boolean;
  hoverable?: boolean;
  compact?: boolean;
}
const props = defineProps<Props>();
const { user, team, showConfirmedTag, hideActions, hoverable, compact } = toRefs(props);
const emit = defineEmits(["select"]);
const authStore = useAuthStore();
const viewerAdmin = computed(() => {
  if (!team?.value?.adminIds || !authStore.user) return false;
  return team.value.adminIds.includes(authStore.user.id);
});
const viewerOwner = computed(() => {
  if (!team?.value?.ownerId || !authStore.user) return false;
  return team.value.ownerId === authStore.user.id;
});
const teamAdmin = computed(() => {
  if (!team?.value) return false;
  return isTeamAdmin(team.value, user.value.id);
});
const teamOwner = computed(() => {
  if (!team?.value) return false;
  return isTeamOwner(team.value, user.value.id);
});
const self = computed(() => {
  if (!authStore.user) return false;
  return authStore.user.id === user.value.id;
});
const ownershipTransferPending = computed(() => {
  if (!team?.value || !viewerAdmin.value) return false;
  return team.value.newOwnerId === user.value.id;
});
const options = computed<DropdownOption[]>(() => {
  const result: DropdownOption[] = [];
  if (
    (self.value && !(viewerAdmin.value && (team?.value?.adminIds?.length ?? 0) === 1)) ||
    (viewerAdmin.value && !teamAdmin.value)
  ) {
    result.push({
      label: self.value ? t("leave") : t("remove"),
      key: "remove",
    });
  }
  if (viewerOwner.value) {
    if (!result.map((r) => r.key).includes("remove")) {
      result.push({
        label: t("remove"),
        key: "remove",
      });
    }
    if (teamAdmin.value) {
      if (!teamOwner.value) {
        if (ownershipTransferPending.value) {
          result.push({
            label: t("cancel-ownership-transfer"),
            key: "cancel-transfer",
          });
        } else {
          result.push({
            label: t("request-ownership-transfer"),
            key: "owner-transfer",
          });
        }
      }
      result.push({
        label: t("demote"),
        key: "demote",
      });
    }
  }
  if (viewerAdmin.value) {
    result.push({
      label: t("promote"),
      key: "promote",
    });
  }
  return result;
});
const msg = useMessage();
const confirm = useConfirmDialog();
const loading = ref(false);
function handleSelect(option: string) {
  if (!team?.value || !authStore.jwt) return;
  let pending: Ref<boolean> | undefined;
  switch (option) {
    case "remove":
      confirm({
        title: self.value ? t("leave-space") : t("remove-member"),
        content: self.value
          ? t("are-you-sure-you-want-to-leave-this-space")
          : t("are-you-sure-you-want-to-remove-this-user-from-the-space"),
        yesText: self.value ? t("leave") : t("remove"),
        noText: t("cancel"),
        callback: () => {
          pending = request(() => removeTeamMember(team.value!.id, user.value.id, authStore.jwt!));
        },
      });
      break;
    case "promote":
      pending = request(
        () => promoteTeamMember(team.value!.id, user.value.id, authStore.jwt!),
        (res) => {
          msg.success(t("user-promoted-to-admin"));
        }
      );
      break;
    case "demote":
      pending = request(
        () => demoteTeamMember(team.value!.id, user.value.id, authStore.jwt!),
        (res) => {
          msg.success(t("user-demoted-to-member"));
        }
      );
      break;
    case "owner-transfer":
      pending = request(
        () => initTeamOwnershipTransfer(team.value!.id, user.value.id, authStore.jwt!),
        (res) => {
          msg.success(t("ownership-transfer-requested"));
        }
      );
      break;
    case "cancel-transfer":
      pending = request(
        () => cancelTeamOwnershipTransfer(team.value!.id, authStore.jwt!),
        (res) => {
          msg.success(t("ownership-transfer-cancelled"));
        }
      );
      break;
  }
  if (pending) {
    oneWaySync(loading, pending);
  }
}
</script>

<template>
  <n-spin :show="loading">
    <n-card
      class="user-list-item"
      :class="{
        hoverable,
        compact,
      }"
      @click="emit('select', user)"
      :hoverable="hoverable"
    >
      <div class="flex justify-between items-center">
        <div class="item-content">
          <div class="avatar-container">
            <profile-picture class="avatar" :user="user" thumbnail />
            <n-icon v-if="teamAdmin" class="crown" size="large">
              <crown-icon />
            </n-icon>
          </div>
          <div class="info">
            <div class="flex gap-2 items-center">
              <h2>{{ user?.name }}</h2>
              <n-tag v-if="user?.locked" round type="error" size="small">
                <n-icon>
                  <lock-closed-outline />
                </n-icon>
              </n-tag>
              <n-tag v-if="self" round type="primary" size="small">
                {{ $t("you") }}
              </n-tag>
              <n-tag v-if="teamOwner" round type="primary" size="small">
                {{ $t("owner") }}
              </n-tag>
              <n-tag v-if="ownershipTransferPending" round type="primary" size="small">
                {{ $t("ownership-transfer-pending") }}
              </n-tag>
              <n-tag v-if="showConfirmedTag && user?.email" round type="primary" size="small">
                {{ $t("email-confirmed") }}
              </n-tag>
            </div>
            <p>{{ user?.email ?? user?.newEmail }}</p>
          </div>
        </div>
        <div v-if="!hideActions" class="extra">
          <slot name="extra" />
          <n-dropdown v-if="options.length" trigger="hover" :options="options" @select="handleSelect">
            <n-button circle size="small" ghost>
              <n-icon>
                <ellipsis-vertical />
              </n-icon>
            </n-button>
          </n-dropdown>
        </div>
      </div>
    </n-card>
  </n-spin>
</template>

<style lang="scss" scoped>
.user-list-item {
  @apply w-full;
  &.compact {
    :deep().n-card-header {
      @apply p-2;
    }
    :deep().n-card__content {
      @apply p-2;
    }
  }
  &.hoverable {
    @apply cursor-pointer;
    &:hover {
      background-color: var(--foreground2-hover);
    }
  }
  .item-content {
    @apply w-full flex items-center gap-2;
    .avatar-container {
      @apply relative w-14 h-14;
      .avatar {
        @apply w-full h-full;
      }
      .crown {
        @apply absolute top-[-.9rem] left-[50%] translate-x-[-50%];
        color: var(--warning);
      }
    }
    .info {
      @apply flex flex-col gap-1;
      p {
        color: var(--text-hover);
      }
    }
  }
}
</style>
